import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ResponsibleText from '../../../components/ResponsibleText';
import { IDocumentAttached } from '../../../interfaces/document';
import AttachedDocumentsActions from './AttachedDocumentsActions';

interface INavAttachedDocumentsProps extends React.AllHTMLAttributes<HTMLDivElement> {
	attached: IDocumentAttached[];
	selectedHash?: string;
	editable: boolean;
	documentId: number;
	realm: string;
	extension: string;
}
const NavAttachedDocuments = ({
	attached,
	selectedHash,
	editable,
	documentId,
	realm,
	extension,
}: INavAttachedDocumentsProps) => {
	const [selectedIndex, setSelectedIndex] = useState<number>(-1);
	useEffect(() => {
		setSelectedIndex(attached.findIndex(({ document_hash }) => document_hash === selectedHash));
	}, [attached, selectedHash]);

	return (
		<div className="hstack gap-3">
			{0 < attached.length &&
				attached.map(({ document_hash, document_no }, index) => (
					<React.Fragment key={document_hash}>
						{document_hash === selectedHash ? (
							<ResponsibleText hiddenPart="Частина " visibleOn="md">
								{document_no}
							</ResponsibleText>
						) : (
							<Link to={`../${document_hash}`} replace relative="path" className="link-secondary">
								<ResponsibleText hiddenPart="Частина " visibleOn="md">
									{document_no}
								</ResponsibleText>
							</Link>
						)}
					</React.Fragment>
				))}
			{editable && (
				<AttachedDocumentsActions
					documentId={documentId}
					attached={attached}
					selectedIndex={selectedIndex}
					realm={realm}
					selectedHash={selectedHash}
					extension={extension}
				/>
			)}
		</div>
	);
};

export default NavAttachedDocuments;
