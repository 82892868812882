import byteSize from 'byte-size';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { BsDownload } from 'react-icons/bs';
import { showSystemError } from '../alerts';
import { addToast } from '../features/app/appSlice';
import DocumentIcon from '../features/documents/DocumentIcon';
import { ISolrDocument } from '../interfaces/document';
import { selectAppStore, useAppDispatch, useAppSelector } from '../store';
import { FixedDotSpinner } from './DotSpinner';

interface IDownloadDocumentButtonProps extends React.AllHTMLAttributes<HTMLDivElement> {
	document: ISolrDocument;
	variant?: string;
	iconClassName?: string;
}
const DownloadDocumentButton = ({
	document: { size, id, title, ext },
	children,
	variant = 'link',
	className,
	iconClassName = 'd-inline-block',
}: IDownloadDocumentButtonProps) => {
	const dispatch = useAppDispatch();
	const { accessToken } = useAppSelector(selectAppStore);
	const [downloading, setDownloading] = useState(false);

	const saveDocx = (blob: Blob) => {
		const objectUrl = URL.createObjectURL(blob);
		const baseName = `${title}${ext}`;

		const link = document.createElement('a');
		link.setAttribute('href', objectUrl);
		link.setAttribute('download', baseName);
		link.textContent = 'download';
		link.className = 'd-none';
		const body = document.querySelector('body');
		if (!body) return;
		body.appendChild(link);
		link.click();
		dispatch(addToast({ text: `Файл "${baseName}" завантажено`, variant: 'success', delay: 3000 }));
		setTimeout(() => {
			URL.revokeObjectURL(objectUrl);
			body.removeChild(link);
		}, 5000);
	};

	const onDownloadClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		dispatch(addToast({ text: `Завантажується файл "${title}${ext}"...`, variant: 'success', delay: 3000 }));
		setDownloading(true);
		try {
			try {
				const response = await fetch(`api/download/${id}${ext}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
						// 'c-user-email': user?.email || 'n/a',
					},
				});
				if (!response.ok) {
					throw new Error(`${response.status} ${response.statusText}: ${await response.text()}`);
				}
				saveDocx(await response.blob());
			} catch (error) {
				showSystemError(error as Error);
			}
		} finally {
			setDownloading(false);
		}
	};

	return (
		<>
			<Button
				variant={variant}
				className={classNames(className, 'text-nowrap position-relative')}
				onClick={onDownloadClick}
				disabled={downloading}
			>
				{!downloading ? <BsDownload className="me-2" /> : <Spinner animation="grow" size="sm" className="me-2" />}
				{!downloading ? <>Завантажити</> : <>Завантаження</>} ({byteSize(size).toString()}){children}
				<DocumentIcon size={32} ext={ext} className={classNames('me-n2 mt-n2 mb-n1', iconClassName)} />
			</Button>
			{downloading && <FixedDotSpinner>Триває завантаження документу 😃 Дочекайтесь</FixedDotSpinner>}
		</>
	);
};

export default DownloadDocumentButton;
