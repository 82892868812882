import React from 'react';

interface IParamsContainerProps extends React.AllHTMLAttributes<HTMLDivElement> {
	caption: string | JSX.Element;
}
const ParamsContainer = ({ caption, children }: IParamsContainerProps) => {
	return (
		<fieldset className="inactive-transparent">
			{/* <legend className="border-bottom border-dark border-2">{title}</legend> */}
			<legend className="border-bottom">{caption}</legend>
			{children}
		</fieldset>
	);
};

export default ParamsContainer;
