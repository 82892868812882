import { DEFAULTS, IDefaultHttpResponse, ISearchParams, IUpdateResponse } from '../../common';
import { IEntityEditMethod, QUERY_TAGS } from '../../interfaces/common';
import { TDocumentStatusOptions } from '../../interfaces/dict';
import {
	IDocumentData,
	IDocumentSourceData,
	IMutatingDocumentData,
	ISolrDocument,
	ISolrResponse,
} from '../../interfaces/document';
import { moriaApi } from './moriaApi';

export interface IShortStatistic {
	count: number;
	lastDate: string | null;
}

export interface ISearchRequestParams {
	params: ISearchParams;
	pageNo: number;
	salt: number;
}

export interface IRecentDocument {
	id: number;
	first_document_hash: string | null;
	insertion_date: string;
	status: TDocumentStatusOptions;
	realm: string;
	user_code: string;
	title: string | null;
	editable: boolean;
}
export interface IRecentDocumentsResponse {
	pageNo: number;
	documents: IRecentDocument[];
}

export interface IDocumentIdParams {
	id: number;
	hash?: string;
}

export interface IDocumentStatusParams extends IDocumentIdParams {
	status: TDocumentStatusOptions;
}

export interface IDeleteDocumentParams extends IDocumentIdParams {
	ext?: string;
}

export interface IDocumentPageChangeParams {
	id: number; // document's serial id
	pages: {
		id: number;
		document_no: number;
	}[];
}

export interface IUploadResult {
	error: number | string | undefined;
	documentHash?: string;
	documentId?: number;
}

const documentsApi = moriaApi.injectEndpoints({
	endpoints: (builder) => ({
		documentById: builder.query<ISolrDocument, string>({
			query: (documentHash) => `doc/${documentHash}`,
			providesTags: (result, error, documentHash) => {
				return [{ type: QUERY_TAGS.document_by_hash, id: `${documentHash}_body` }];
			},
		}),
		deleteDocument: builder.mutation<IDocumentStatusParams, IDeleteDocumentParams>({
			query: (body) => ({
				url: 'edit/doc',
				method: DEFAULTS.httpMethod.delete,
				body,
			}),
			invalidatesTags: (result, error, { hash, id }) => {
				return [
					{ type: QUERY_TAGS.document_by_serial, id },
					{ type: QUERY_TAGS.document_by_serial, id: 'list' },
					// { type: QUERY_TAGS.document_by_hash, id: hash },
				];
			},
		}),
		documentLinks: builder.mutation<IDocumentSourceData, number>({
			// Тут використовуємо мутацію, тому що потрібно виконувати пошук тільки за запитом
			query: (docId) => `doc/${docId}/links`,
		}),
		documentStatus: builder.mutation<IDocumentStatusParams, IDocumentStatusParams>({
			query: (body) => ({
				url: 'edit/doc/status',
				method: DEFAULTS.httpMethod.put,
				body,
			}),
			invalidatesTags: (result, error, { id }) => {
				return [{ type: QUERY_TAGS.document_by_serial, id }];
			},
		}),
		documentsRecent: builder.query<IRecentDocumentsResponse, { scope: string; pageNo: number }>({
			query: ({ scope, pageNo }) => `doc/list/recent/${scope}?page=${pageNo}&dpp=${DEFAULTS.recentDocumentsPerPage}`,
			providesTags: (result, error, scope) => {
				return result?.documents
					? [
							{ type: QUERY_TAGS.document_by_hash, id: 'list' },
							{ type: QUERY_TAGS.document_by_serial, id: 'list' },
							...result.documents.map(({ first_document_hash }) => ({
								type: QUERY_TAGS.document_by_hash,
								id: first_document_hash || '',
							})),
							...result.documents.map(({ id }) => ({
								type: QUERY_TAGS.document_by_serial,
								id,
							})),
					  ]
					: [];
			},
		}),
		documentData: builder.query<IDocumentData | undefined, string>({
			query: (documentHash) => `doc/${documentHash}/data`,
			providesTags: (result, error, documentHash) => {
				return [
					{ type: QUERY_TAGS.document_by_serial, id: result?.main.id },
					{ type: QUERY_TAGS.document_by_hash, id: documentHash },
					...(result?.attached.map(({ document_hash }) => ({ type: QUERY_TAGS.document_by_hash, id: document_hash })) ||
						[]),
				];
			},
		}),
		shortStatistic: builder.query<IShortStatistic, void>({
			query: () => 'statistic',
		}),
		searchDocuments: builder.query<ISolrResponse, ISearchRequestParams>({
			query: ({ params: { term, severity, realm }, pageNo }) =>
				`search?${new URLSearchParams({
					term,
					startRow: (pageNo * DEFAULTS.documentsPerPage).toString(),
					rowsCount: DEFAULTS.documentsPerPage.toString(),
					mm: severity,
					realm,
				}).toString()}`,
		}),
		documentMetadata: builder.mutation<IUpdateResponse, IMutatingDocumentData & IEntityEditMethod>({
			query: ({ method, ...rest }) => ({
				url: 'edit/doc',
				method,
				body: rest,
			}),
			invalidatesTags: (result, error, { method }) => {
				return [{ type: QUERY_TAGS.document_by_serial, id: method === DEFAULTS.httpMethod.put ? result?.ID : 'list' }];
			},
		}),
		moveDocuments: builder.mutation<IDefaultHttpResponse, IDocumentPageChangeParams>({
			query: (body) => ({
				url: 'edit/doc/pages',
				method: DEFAULTS.httpMethod.put,
				body,
			}),
			invalidatesTags: (result, error, { id }) => [{ type: QUERY_TAGS.document_by_serial, id }],
		}),
		uploadFile: builder.mutation<IUploadResult, { data: FormData; realm: string; draft: boolean }>({
			query: ({ data, realm }) => ({
				url: `../upload/${realm}`,
				method: DEFAULTS.httpMethod.post,
				body: data,
			}),
			invalidatesTags: (result, error, { draft, data }) => {
				if (draft) return [];
				const strDocumentId = data.get('documentId')?.toString();
				return strDocumentId
					? [
							{
								type: QUERY_TAGS.document_by_serial,
								id: parseInt(strDocumentId, 10),
							},
							{ type: QUERY_TAGS.document_by_hash, id: result?.documentHash || '' },
					  ]
					: [
							{ type: QUERY_TAGS.document_by_hash, id: 'list' },
							{ type: QUERY_TAGS.document_by_serial, id: 'list' },
							{ type: QUERY_TAGS.document_by_hash, id: result?.documentHash || '' },
					  ];
			},
		}),
		addEmptyDocument: builder.mutation<IUploadResult, { realm: string; title: string }>({
			query: ({ realm, ...rest }) => ({
				url: `edit/doc/${realm}`,
				method: DEFAULTS.httpMethod.post,
				body: rest,
			}),
			invalidatesTags: () => {
				return [
					{ type: QUERY_TAGS.document_by_hash, id: 'list' },
					{ type: QUERY_TAGS.document_by_serial, id: 'list' },
				];
			},
		}),
	}),
});

export const {
	useDocumentByIdQuery,
	useShortStatisticQuery,
	useSearchDocumentsQuery,
	useDocumentDataQuery,
	useDocumentMetadataMutation,
	useDocumentLinksMutation,
	useDocumentsRecentQuery,
	useDocumentStatusMutation,
	useDeleteDocumentMutation,
	useMoveDocumentsMutation,
	useUploadFileMutation,
	useAddEmptyDocumentMutation,
} = documentsApi;
