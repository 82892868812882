import React from 'react';

interface ITextByParagraphsProps extends React.AllHTMLAttributes<HTMLDivElement> {
	text: string;
}
const TextByParagraphs = ({ text, ...rest }: ITextByParagraphsProps) => (
	<div {...rest}>
		{text.split('\n').map((paragraph, index) => (
			<p key={index} className="mb-1">
				{paragraph}
			</p>
		))}
	</div>
);

export default TextByParagraphs;
