import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

interface IScorePanelProps extends React.AllHTMLAttributes<HTMLDivElement> {
	boxesCount?: number;
	current: number;
	max: number;
}
const ScorePanel = ({ boxesCount = 5, current, max }: IScorePanelProps) => {
	const [items, setItems] = useState<boolean[]>([]);
	const matching = current / (max || 1000);

	useEffect(() => {
		const greensCount = Math.round(matching / (1 / boxesCount));
		setItems(
			Array(boxesCount)
				.fill(null)
				.map((dummy, index) => index <= greensCount)
		);
	}, [boxesCount, matching]);

	const percent = (matching * 100).toFixed(1) + ' %';
	return (
		<div className="doc-score__container d-flex flex-nowrap" title={`${percent} від максимуму відповідності`}>
			{items.map((isGreen, index) => (
				<span
					key={index}
					className={classNames('doc-score__item', isGreen ? 'doc-score__item_green' : 'doc-score__item_gray')}
				/>
			))}
		</div>
	);
};

export default ScorePanel;
