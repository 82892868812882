import { skipToken } from '@reduxjs/toolkit/query';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { BsArrowLeft, BsCalendar, BsExclamationTriangle, BsInfoCircle } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DEFAULTS, formatDocumentDate } from '../../common';
import { FixedDotSpinner } from '../../components/DotSpinner';
import DownloadDocumentButton from '../../components/DownloadButton';
import FetchError from '../../components/FetchError';
import HeaderWithImage from '../../components/HeaderWithImage';
import WithRealms, { IWithRealms } from '../../components/hoc/withRealms';
import ImageZoom from '../../components/ImageZoom';
import PageTitle from '../../components/PageTitle';
import VerifiedMark from '../../components/VerifiedMark';
import { useScreenWidth, useShowSkeleton } from '../../hooks';
import VoidImage from '../../img/undraw_void_-3-ggu.svg';
import { IDocumentData } from '../../interfaces/document';
import { moveControlToScreenCenter, scrollWindowToBottom } from '../../scrolls';
import { TopStickyDiv } from '../../styled';
import { getRandomArray, idIsSerial } from '../../utils';
import FloatFace from '../facer/FloatFace';
import { useDocumentByIdQuery } from '../services/documents';
import DocumentData from './data/DocumentData';
import NavAttachedDocuments from './data/NavAttachedDocuments';
import DocumentImageTHumbnails from './DocumentImageThumbnails';

interface IHtmlDocumentProps extends IWithRealms {}
const HtmlDocument = ({ realmsById }: IHtmlDocumentProps) => {
	const { id: documentHash } = useParams<'id'>();
	const isSerial = idIsSerial(documentHash);
	const [loadedDocumentHash, setLoadedDocumentHash] = useState('');
	const {
		data: solrDocument,
		error,
		isFetching,
	} = useDocumentByIdQuery(documentHash || skipToken, {
		pollingInterval: 1 * 60 * 1000,
		skip: loadedDocumentHash === documentHash,
	});
	const navigate = useNavigate();
	const location = useLocation();
	const [zoomImgSrc, setZoomImgSrc] = useState<string>();
	const [documentData, setDocumentData] = useState<IDocumentData | null>();
	const [searchParams] = useSearchParams();
	const [stickyImages] = useScreenWidth(1500);
	// const [withFloatFace, setWithFloatFace] = useState(false);
	const [facedImageNo, setFacesImageNo] = useState('');
	const showSkeleton = useShowSkeleton(isFetching, 200);

	useEffect(() => {
		setLoadedDocumentHash(solrDocument?.id || '');
		if (!solrDocument || !facedImageNo) return;
		const selectedImage = document.querySelector(`.img-in-doc[data-image-no="${facedImageNo}"]`);
		setTimeout(() => {
			/**
			 * Якщо відсутнє зображення з бажаним номером, значить кількість зображень
			 * у завантаженому документі менша за те, яке намагається побачити користувач.
			 * В такому випадку скролимо сторінку до низу, бо бажане зображення має буте дес там.
			 */
			if (selectedImage) moveControlToScreenCenter(selectedImage);
			else scrollWindowToBottom();
		}, 1000);
	}, [solrDocument, facedImageNo]);

	useEffect(() => {
		const imageNo = searchParams.get('image_no');
		// Тут image_no - це string, тому при image_no === 0 це також буде працювати
		if (!imageNo) return;
		setFacesImageNo(imageNo);
	}, [searchParams, setFacesImageNo]);

	/**
	 * Нас в даному випадку цікавить натискання на зображенні, у відповідь на яке
	 * виводиться діалогове вікно з даним зображенням.
	 */
	const onDocumentClick = useCallback(async (event: React.MouseEvent<HTMLDivElement>) => {
		const imageElement = (event.target as HTMLElement).closest('.img-zoomable') as HTMLImageElement;
		if (!imageElement) return;

		event.preventDefault();
		event.stopPropagation();

		const src = imageElement.getAttribute('src');
		if (!src) return;
		setZoomImgSrc(src);
	}, []);

	const onZoomImageClose = useCallback(() => {
		setZoomImgSrc(undefined);
	}, []);

	const onBackClick = useCallback(() => {
		location.state?.fromList ? navigate(-1) : navigate('/' + location.search, { replace: true });
	}, [location, navigate]);

	const realm = realmsById[documentData?.main.realm || solrDocument?.realm || ''];
	return (
		<>
			<PageTitle title={solrDocument?.title || 'Завантаження документу'} />
			<Container fluid={DEFAULTS.fluid}>
				{error && <FetchError error={error} className="w-50 mt-3 mx-auto" />}
				{(documentData || solrDocument) && (
					<TopStickyDiv className="row mb-2 pt-1 mt-n2">
						<Col>
							<div className="d-flex justify-content-between">
								<h3 className="mb-0">
									{documentData?.main.title || solrDocument?.title}
									{solrDocument && 1 < (documentData?.attached.length || 0) && (
										<span className="ms-2 text-secondary">({solrDocument?.title})</span>
									)}
									{realm && <VerifiedMark draft={realm.draft} title={realm.title} className="ms-2 fs-4 mt-n2" />}
								</h3>
								<h6 title="Приблизна дата створення документа" className="mt-1 text-secondary text-nowrap">
									<BsCalendar className="me-1 mt-n1" />
									{formatDocumentDate(solrDocument?.create_date || documentData?.main.creation_date)}
								</h6>
							</div>
							<div className="d-flex justify-content-between border-bottom">
								<Button variant="link" className="text-nowrap p-0" onClick={onBackClick}>
									<BsArrowLeft className="me-1" />
									{!facedImageNo ? 'До списку' : 'До знайдених облич'}
								</Button>
								{documentData && (
									<NavAttachedDocuments
										attached={documentData.attached}
										selectedHash={
											isSerial
												? documentData.attached.length !== 0
													? documentData.attached[0].document_hash
													: ''
												: documentHash
										}
										editable={documentData.editable}
										documentId={documentData.main.id}
										realm={documentData.main.realm}
										extension={solrDocument?.ext || ''}
									/>
								)}
								{solrDocument ? (
									<DownloadDocumentButton document={solrDocument} className="link-secondary p-0" />
								) : (
									<span>&nbsp;</span>
								)}
							</div>
						</Col>
					</TopStickyDiv>
				)}
				{solrDocument && realmsById[solrDocument.realm]?.draft === true && (
					<Row>
						<Col className="d-print-none">
							<Alert variant="warning mb-2">
								<BsExclamationTriangle className="me-1 mt-n1" />
								<strong>Увага!</strong> Документ належить до&nbsp;сегменту «{realmsById[solrDocument.realm]?.title}».
								Він&nbsp;містить
								<span className="ms-1">{realmsById[solrDocument.realm]?.description.toLowerCase()}</span>.
							</Alert>
						</Col>
					</Row>
				)}
				{documentHash && (
					<DocumentData
						documentHash={documentHash}
						onDataFetched={setDocumentData}
						textExists={!!solrDocument}
						draft={realm?.draft}
					/>
				)}
				{!solrDocument && !isFetching && documentData !== undefined && (
					<>
						{documentData?.attached.length === 0 ? (
							<HeaderWithImage
								imgSrc={VoidImage}
								alt="Зображення відсутності результатів"
								headerClassName="text-info"
								title="Наразі відсутні документи для даного опису"
								className="mb-3"
							/>
						) : (
							<HeaderWithImage
								imgSrc={VoidImage}
								alt="Зображення відсутності результатів"
								headerClassName="text-info"
								title="Відсутній документ з таким ідентифікатором"
							>
								<Alert variant="info" className="my-3 w-50">
									<BsInfoCircle className="me-1 mt-n1" />
									Якщо документ було завантажено на&nbsp;сервер нещодавно, то, можливо, ще&nbsp;не&nbsp;виконано його
									конвертування, або&nbsp;занесення до&nbsp;пошукової бази даних. В&nbsp;такому випадку документ стане
									доступним найближчим часом.
								</Alert>
							</HeaderWithImage>
						)}
					</>
				)}
				{solrDocument && (
					<Row>
						<Col
							xs={10}
							lg={stickyImages ? 11 : 12}
							dangerouslySetInnerHTML={{
								__html: solrDocument.html,
							}}
							className="html-document-container text-break"
							onClick={onDocumentClick}
						/>
						<DocumentImageTHumbnails solrDocument={solrDocument} isSticky={stickyImages} />
					</Row>
				)}
				{facedImageNo && solrDocument && <FloatFace />}
			</Container>
			{showSkeleton && (
				<Container fluid={DEFAULTS.fluid}>
					<h1>
						<Skeleton count={Math.random() * 0.3 + 0.4} />
					</h1>
					{getRandomArray(3, 7).map((_, index) => (
						<p key={`sd_${index}`}>
							<Skeleton count={Math.random() * 4 + 1} />
						</p>
					))}
				</Container>
			)}
			{isFetching && <FixedDotSpinner>Завантаження тексту документу</FixedDotSpinner>}
			{zoomImgSrc && <ImageZoom src={zoomImgSrc} onClose={onZoomImageClose} />}
		</>
	);
};

export default WithRealms(HtmlDocument);
