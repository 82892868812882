import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import WithCountries, { IWithCountries } from './hoc/withCountries';

interface ICountryFlagProps extends React.AllHTMLAttributes<HTMLDivElement> {
	digram: string;
	isSquare?: boolean;
}
const CountryFlag = React.memo(({ digram, title, isSquare = false, className }: ICountryFlagProps) => (
	<span
		className={classNames(className, 'flex-shrink-0 country-flag', isSquare && 'fis', `fi fi-${digram}`)}
		title={title}
	/>
));

interface INatoCountryFlagProps extends IWithCountries, React.AllHTMLAttributes<HTMLDivElement> {
	trigram?: string | null;
	isSquare?: boolean;
	withTitle?: boolean;
	inline?: boolean;
}
const NatoCountryFlag = React.memo(
	({
		countriesById,
		trigram,
		isSquare = false,
		className,
		withTitle = false,
		inline = false,
	}: INatoCountryFlagProps) => {
		const country = trigram ? countriesById[trigram] : undefined;
		let digram = country ? country.digram.toLowerCase() : 'un';
		return withTitle ? (
			<NatoContainer
				className={classNames('d-flex gap-1 align-items-center', inline ? 'd-inline-flex' : 'd-flex', className)}
				title={country?.title}
			>
				<NatoFlag digram={digram} isSquare={isSquare} />
				{withTitle && <span>{country?.title}</span>}
			</NatoContainer>
		) : (
			<CountryFlag digram={digram} isSquare={isSquare} className={className} />
		);
	}
);

export default WithCountries(NatoCountryFlag);

const NatoContainer = styled.div`
	--bs-body-line-height: 1;
`;

const NatoFlag = styled(CountryFlag)`
	margin-top: -0.1em;
`;

// const NatoTitle = styled.span``;
