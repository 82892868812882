import React from 'react';
import { Helmet } from 'react-helmet';
import { DEFAULTS } from '../common';

interface IPageTitleProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const PageTitle = ({ title = '' }: IPageTitleProps) => {
	const titleText = `${title}${title !== '' ? '::' : ''}${DEFAULTS.pageTitle}`;
	return (
		<Helmet>
			<title>{titleText}</title>
		</Helmet>
	);
};

export default PageTitle;
