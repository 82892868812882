import { useCallback, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { BsCardChecklist } from 'react-icons/bs';
import styled from 'styled-components';
import { showAlert, showPromiseConfirm } from '../../../alerts';
import { FixedDotSpinner } from '../../../components/DotSpinner';
import { useDocumentChildData } from '../../../hooks';
import { PUB_RELIABILITY_DEFAULT } from '../../../interfaces/dict';
import {
	IArrayDataOptions,
	IDocumentSourceData,
	IDocumentSourceView,
	TDocumentSource,
} from '../../../interfaces/document';
import { getControlData, getDomainFromUrl, objectsAreEquals } from '../../../utils';
import { useDocumentLinksMutation } from '../../services/documents';
import DocumentSourcesActions from './DocumentSourcesActions';
import LinkEdit from './LinkEdit';
import LinkView from './LinkView';

const DEFAULT_SOURCE: IDocumentSourceView = {
	id: 0,
	source_id: null,
	source_reliability: 'F',
	pub_title: null,
	pub_author: null,
	pub_date: null,
	pub_url: '',
	pub_reliability: PUB_RELIABILITY_DEFAULT,
	comment: null,
};

interface IDocumentSourcesProps {
	documentId: number;
	initialData?: IDocumentSourceData;
	onChange: (data: IArrayDataOptions<IDocumentSourceView>) => void;
	setInEditing: (newInEditing: boolean) => void;
	autoLinksEnabled: boolean;
}
const DocumentSources = ({
	initialData,
	onChange,
	setInEditing,
	documentId,
	autoLinksEnabled,
}: IDocumentSourcesProps) => {
	const {
		newItemIndex,
		editingIndex,
		setEditingIndex,
		// refSelectedInput,
		data: links,
		setData: setLinks,
		modified,
		onItemReset,
	} = useDocumentChildData(initialData?.links, 'id');
	// const [inserting, setInserting] = useState(false);
	const [sourcesById, setSourcesById] = useState(() => ({ ...initialData?.byId }));
	const [newLink, setNewItem] = useState<IDocumentSourceView | null>(null);
	const [autoLinksRefetch, autoLinks] = useDocumentLinksMutation();
	const [selectedLinks, setSelectedLinks] = useState<Set<number>>(new Set());
	// const selectedLinks = useRef<Set<number>>(new Set());
	// const [dummy, updateComponent] = useReducer((x) => x + 1, 0);

	useEffect(() => {
		setInEditing(newItemIndex !== editingIndex || newLink !== null);
	}, [newItemIndex, editingIndex, setInEditing, newLink]);

	useEffect(() => {
		if (autoLinks.status !== 'fulfilled' || !autoLinks.data) return;
		const existingUrls = new Set(links.map(({ pub_url: PUB_URL }) => PUB_URL));
		const newLinks = autoLinks.data.links.filter(({ pub_url: PUB_URL }) => !existingUrls.has(PUB_URL));
		if (newLinks.length !== 0) {
			setLinks([...links, ...newLinks]);
			if (autoLinks.data.byId) addSources(Object.values(autoLinks.data.byId));
		}
	}, [autoLinks]); // eslint-disable-line

	useEffect(() => {
		onChange({ modified, selected: links });
	}, [modified, links]); // eslint-disable-line

	const addSources = useCallback(
		(sources: (TDocumentSource | undefined)[]) => {
			const newSources = { ...sourcesById };
			let someSourcesAdded = false;
			for (const source of sources) {
				if (!source || sourcesById[source.id]) continue;
				newSources[source.id] = source;
				someSourcesAdded = true;
			}
			if (someSourcesAdded) setSourcesById(newSources);
		},
		[sourcesById]
	);

	const getSimilarLinksIndexes = (index: number) => {
		const domain = getDomainFromUrl(links[index]?.pub_url);
		const result: Set<number> = new Set();
		links.forEach(({ pub_url }, i) => {
			if (getDomainFromUrl(pub_url) === domain) result.add(i);
		});
		return result;
	};

	const onControlClick = async (event: React.MouseEvent<HTMLDivElement>) => {
		const action = getControlData(event.target, '.btn-action', 'action');
		const strIndex = getControlData(event.target, '.source-row', 'index') as string;
		if (!action || !strIndex) return;
		const index = parseInt(strIndex, 10);
		// console.log(action, index);

		switch (action) {
			case 'edit':
				setEditingIndex(index);
				break;
			// case 'delete':
			// 	const oldLink = links[index].pub_title || links[index].pub_url;
			// 	if (!(await showPromiseConfirm(`Ви впевнені, що бажаєте видалити посилання на джерело "${oldLink}?"`))) return;
			// 	setLinks(links.filter((item, itemIndex) => index !== itemIndex));
			// 	break;
			case 'select':
				if (selectedLinks.has(index)) setSelectedLinks((s) => new Set([...s].filter((i) => i !== index)));
				else setSelectedLinks((s) => new Set([...s, index]));
				break;
			case 'select-similar':
				const indexesForAdding = getSimilarLinksIndexes(index);
				setSelectedLinks((s) => new Set([...s, ...indexesForAdding]));
				break;
			case 'unselect-similar':
				const indexesForRemoving = getSimilarLinksIndexes(index);
				setSelectedLinks((s) => new Set([...s].filter((i) => !indexesForRemoving.has(i))));
				break;
		}
	};

	const onDeleteLinksClick = useCallback(async () => {
		if (!(await showPromiseConfirm('Ви впевнені, що бажаєте видалити вибрані посилання?'))) return;
		setLinks(links.filter((item, index) => !selectedLinks.has(index)));
		setSelectedLinks(new Set());
	}, [selectedLinks, links, setLinks]);

	const onEditDone = useCallback(
		(newValue?: IDocumentSourceView) => {
			if (newValue) {
				const oldValue = links[editingIndex];
				if (oldValue && objectsAreEquals(oldValue, newValue)) return setEditingIndex(newItemIndex);

				let newLinks = [...links];
				if (editingIndex !== newItemIndex) {
					newLinks[editingIndex] = newValue;
					setEditingIndex(newItemIndex);
				} else {
					newLinks.push(newValue);
				}

				// Якщо було додане нове джерело, то розповсюджуємо його на всі записи
				// з аналогічним доменом
				if (!oldValue?.source_id && newValue.source_id) {
					const newDomain = getDomainFromUrl(newValue.pub_url);
					newLinks = newLinks.map((link) =>
						link.source_id || getDomainFromUrl(link.pub_url) !== newDomain
							? link
							: {
									...link,
									source_id: newValue.source_id,
									source_reliability: newValue.source_reliability,
							  }
					);
				}

				setLinks(newLinks);
			} else {
				onItemReset();
			}
			setNewItem(null);
		},
		[links, setEditingIndex, setLinks, editingIndex, newItemIndex, onItemReset]
	);

	const onNewLinkClick = useCallback(() => setNewItem({ ...DEFAULT_SOURCE }), []);

	const onGetDocumentLinksClick = useCallback(() => {
		if (documentId) autoLinksRefetch(documentId);
		if (!autoLinksEnabled)
			showAlert(
				'Схоже на те, що текст документу ще не занесено до бази даних. Якщо спроба буде невдалою, повторіть її пізніше.'
			);
	}, [documentId, autoLinksRefetch, autoLinksEnabled]);

	const onSourceChange = useCallback((source: TDocumentSource) => addSources([source]), [addSources]);

	return (
		<>
			{(links.length !== 0 || newLink) && (
				<Table striped>
					<SourcesTableHeader className="position-sticky z-1">
						<tr>
							<th>#</th>
							<th>
								<BsCardChecklist className="icon-lg mt-n1" />
							</th>
							<th>Джерело</th>
							<th>Атрибути посилання</th>
							<th>Рейтинг</th>
							<th>Дії</th>
						</tr>
					</SourcesTableHeader>
					<tbody onClick={onControlClick}>
						{links.map((link, index) =>
							index !== editingIndex ? (
								<LinkView
									key={link.id !== 0 ? link.id : `${link.pub_url}_${link.pub_title}`}
									link={link}
									index={index}
									sourceTitle={link.source_id ? sourcesById[link.source_id]?.title : undefined}
									editButtonsDisabled={editingIndex !== newItemIndex || !!newLink}
									selected={selectedLinks.has(index)}
								/>
							) : (
								<LinkEditRow
									key={link.id !== 0 ? link.id : `${link.pub_url}_${link.pub_title}`}
									className="position-sticky z-1"
								>
									<td colSpan={6}>
										<LinkEdit
											link={link}
											baseSource={link.source_id ? sourcesById[link.source_id] : undefined}
											onClose={onEditDone}
											onSourceChange={onSourceChange}
										/>
									</td>
								</LinkEditRow>
							)
						)}
						{newLink && (
							<LinkEditRow className="position-sticky z-1">
								<td colSpan={6}>
									<LinkEdit link={newLink} onClose={onEditDone} onSourceChange={onSourceChange} />
								</td>
							</LinkEditRow>
						)}
					</tbody>
				</Table>
			)}
			{editingIndex === newItemIndex && !newLink && (
				<DocumentSourcesActions
					autoLinksLoading={autoLinks.isLoading}
					// autoLinksEnabled={autoLinksEnabled}
					onNewLinkClick={onNewLinkClick}
					onGetDocumentLinksClick={onGetDocumentLinksClick}
					onDeleteClick={onDeleteLinksClick}
					selectedLinksCount={selectedLinks.size}
				/>
			)}
			{autoLinks.isLoading && <FixedDotSpinner>Пошук посилань в документі</FixedDotSpinner>}
		</>
	);
};

export default DocumentSources;

const SourcesTableHeader = styled.thead`
	top: 58px;
`;

const LinkEditRow = styled.tr`
	top: 98px;
	bottom: -1.1rem;
	--bs-table-striped-bg: var(--bs-tertiary-bg);
	--bs-table-bg: var(--bs-tertiary-bg);
`;
