import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { BsPencilSquare, BsSave, BsTrash, BsXLg } from 'react-icons/bs';
import styled from 'styled-components';
import { showError, showPromiseConfirm } from '../../../alerts';
import { DEFAULTS, DELETION_WARNING, EXIT_CONFIRMATION } from '../../../common';
import Asterisk, { AsteriskText } from '../../../components/Asterisk';
import TextIcon from '../../../components/textIcon';
import { useFormFields } from '../../../hooks';
import { IDictionary, TEntityId } from '../../../interfaces/common';
import { IRealmDic } from '../../../interfaces/dict';
import { ITextArrayDataOptions } from '../../../interfaces/document';
import { IUser, IUserBase } from '../../../interfaces/users';
import { getDataForSave } from '../../../utils';
import CheckboxesGroup from '../../controls/CheckboxesGroup';
import DangerousListItem from '../../dangerous/DangerousListItem';
import DangerousZone from '../../dangerous/DangerousZone';
import { useUserMutation } from '../../services/users';
import UserSelector from './UserSelector';

const DEFAULT_USER: IUserBase = {
	id: 0,
	email: '',
	code: '',
	manager: null,
};
interface IUserDialogProps extends React.AllHTMLAttributes<HTMLDivElement> {
	user?: IUser;
	realmsById: IDictionary<string, IRealmDic>;
	realmsAllIds: TEntityId<string>[];
	onClose: (userId?: number) => void;
	users: IDictionary<number, IUser>;
}
const UserDialog = ({ user, realmsById, realmsAllIds, onClose, users }: IUserDialogProps) => {
	const inserting = user === undefined;
	const [initialData, setInitialData] = useState<IUserBase>(DEFAULT_USER);
	const { formFields, createChangeHandler, modified, setFormFields } = useFormFields(initialData);
	const refFirstControl = useRef<HTMLInputElement>(null);
	const [updateUser, updateUserResult] = useUserMutation();
	const [editableRealmsState, setEditableRealmsState] = useState<ITextArrayDataOptions>(() => ({
		modified: false,
		selected: user?.editableRealms || [],
	}));

	useEffect(() => {
		refFirstControl.current?.focus();
	}, []);

	useEffect(() => {
		if (!user) return;
		const { id: ID, email: EMAIL, code: CODE, manager: MANAGER } = user;
		setInitialData({ id: ID, email: EMAIL, code: CODE, manager: MANAGER });
	}, [user]); // eslint-disable-line

	useEffect(() => {
		if (!updateUserResult) return;
		const { isError, error, isSuccess, data } = updateUserResult;
		if (isSuccess) return closeDialog(data.ID);
		if (isError) showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
		refFirstControl.current?.focus();
	}, [updateUserResult]); // eslint-disable-line

	const closeDialog = (userId?: number) => {
		onClose(userId);
	};

	const handleClose = async () => {
		if ((modified || editableRealmsState?.modified) && !(await showPromiseConfirm(EXIT_CONFIRMATION))) return;
		closeDialog();
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		const dataToSave = getDataForSave<IUserBase, 'id'>(inserting ? undefined : initialData, formFields, ['id']);
		updateUser({
			...dataToSave,
			editableRealms: editableRealmsState?.selected,
			method: inserting ? DEFAULTS.httpMethod.post : DEFAULTS.httpMethod.put,
		});
	};

	const onDeleteClick = async () => {
		if (!(await showPromiseConfirm(`Ви впевнені, що хочете видалити запис користувача «${formFields.email}»?`))) return;
		await updateUser({ id: formFields.id, method: 'DELETE' });
	};

	const onManagerChange = (managerId: number) => {
		if (managerId !== formFields.manager) setFormFields({ ...formFields, manager: managerId });
	};

	return (
		<Modal
			show
			centered
			size="xl"
			fullscreen="lg-down"
			onHide={handleClose}
			// contentClassName={`bg-${DIALOG_BG[formFields.type]}-subtle`}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<TextIcon Icon={BsPencilSquare} className="mt-n1 me-1" />
					Редагування користувача
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={onSubmit} id="fmUser">
					<Row className="g-3">
						<Form.Group as={Col} controlId="userEmail">
							<Form.Label>
								Email
								<Asterisk />
							</Form.Label>
							<Form.Control
								type="email"
								required
								placeholder="Введіть електронну адресу"
								maxLength={50}
								value={formFields.email}
								onChange={createChangeHandler('email')}
								autoComplete="off"
								ref={refFirstControl}
							/>
						</Form.Group>
						<Form.Group as={Col} xs="auto" controlId="userCode">
							<Form.Label>Код</Form.Label>
							<UserCode
								type="text"
								placeholder="GXX"
								maxLength={10}
								value={formFields.code || ''}
								onChange={createChangeHandler('code')}
								autoComplete="off"
								pattern="^[A-Z][A-Z]?\d+$"
							/>
						</Form.Group>
						<UserSelector
							onUserChange={onManagerChange}
							userId={formFields.manager}
							containerClassName="col-12 col-md"
							allUsers={users}
							label="Керівник"
							required
							name="MANAGER"
						/>
						{/* <Form.Group as={Col} xs={12} md controlId="userManager">
							<Form.Label>
								Контролер
								<Asterisk />
							</Form.Label>
							<Form.Control
								type="number"
								required
								placeholder="Контролер"
								maxLength={50}
								value={formFields.MANAGER || 0}
								onChange={createChangeHandler('MANAGER')}
								autoComplete="off"
							/>
						</Form.Group> */}
					</Row>
					<CheckboxesGroup
						entities={{ ids: realmsAllIds, entities: realmsById }}
						initialOptions={user?.editableRealms}
						idTemplate="userRealms"
						inline
						title="Сегменти, до яких користувач може додавати документи"
						className="mt-3"
						onChange={setEditableRealmsState}
					/>
					{!inserting && (
						<DangerousZone className="mt-3">
							<DangerousListItem
								onClick={onDeleteClick}
								title="Видалити запис даного користувача."
								description={
									!!user?.docsCount
										? 'Видалення запису неможливе, позаяк існують створені даним користувачем документи.'
										: DELETION_WARNING
								}
								disabled={!!user?.docsCount}
							>
								<TextIcon Icon={BsTrash}>Видалити</TextIcon>
							</DangerousListItem>
						</DangerousZone>
					)}
				</Form>
			</Modal.Body>
			<Modal.Footer className="justify-content-between">
				<AsteriskText />
				{!inserting && <Badge bg="secondary">ID: {initialData.id}</Badge>}
				<div className="hstack gap-1">
					<Button
						disabled={!(modified || editableRealmsState.modified) || updateUserResult.isLoading}
						type="submit"
						form="fmUser"
					>
						<TextIcon Icon={BsSave} className={updateUserResult.isLoading ? 'animation-bounce' : undefined}>
							Зберегти
						</TextIcon>
					</Button>
					<Button variant="secondary" onClick={handleClose} type="button">
						<TextIcon Icon={BsXLg}>Закрити</TextIcon>
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default UserDialog;

const UserCode = styled(Form.Control)`
	width: 100px;
`;
