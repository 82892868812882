import classNames from 'classnames';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { BsEyeFill, BsEyeSlashFill, BsFiles, BsPencilSquare } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { DEFAULTS } from '../../../common';
import NatoCountryFlag from '../../../components/NatoCountryFlag';
import WithStaticOptions, { IWithStaticOptions } from '../../../components/hoc/withStaticOptions';
import TextIcon from '../../../components/textIcon';
import { ISourceBase } from '../../../interfaces/sources';

interface ISourceItemProps extends IWithStaticOptions, React.AllHTMLAttributes<HTMLDivElement> {
	source: ISourceBase;
}
const SourceItem = ({ source, staticOptions: { SOURCE_RELIABILITY } }: ISourceItemProps) => {
	const sourceNotRated = source.findable && source.reliability === 'F';
	return (
		<tr className={sourceNotRated ? 'table-color-danger' : undefined}>
			<td>
				<Badge bg="secondary">{source.id}</Badge>
			</td>
			<td>
				<TextIcon
					Icon={source.findable ? BsEyeFill : BsEyeSlashFill}
					className={source.findable ? (sourceNotRated ? undefined : 'text-success') : 'text-secondary'}
					title={(source.findable ? 'Може' : 'Не може') + ' бути знайдене'}
				/>
			</td>
			<td id={`source_${source.id}`}>{source.title}</td>
			<td>
				{source.findable ? (
					<a
						href={`https://${source.base_domain}`}
						target="_blank"
						rel="noreferrer"
						className={sourceNotRated ? 'link-danger' : undefined}
					>
						{source.base_domain}
					</a>
				) : (
					source.base_domain
				)}
			</td>
			<td>
				<NatoCountryFlag trigram={source.country} />
			</td>
			<td>
				<strong>{source.reliability}</strong> — {SOURCE_RELIABILITY && SOURCE_RELIABILITY[source.reliability]}
			</td>
			<td>
				{source.update_date ? (
					new Date(source.update_date).toLocaleDateString(DEFAULTS.locale, {
						month: '2-digit',
						day: '2-digit',
						year: 'numeric',
					})
				) : (
					<>&empty;</>
				)}
			</td>
			<td>
				<div className="text-nowrap d-flex gap-2">
					<button
						data-source-id={source.id}
						className={classNames('btn-edit link-button', sourceNotRated ? 'link-danger' : 'link-primary')}
						title="Редагувати"
					>
						<TextIcon Icon={BsPencilSquare}>
							<span className="d-none d-lg-inline">Редагувати</span>
						</TextIcon>
					</button>
					{source.findable && (
						<Link
							to={`${source.id}/${DEFAULTS.routes.docs}`}
							className="link-secondary"
							title="Список останніх документів, в яких використане джерело"
							state={{ source }}
							preventScrollReset
						>
							<TextIcon Icon={BsFiles}>
								<span className="d-none d-lg-inline">Документи</span>
							</TextIcon>
						</Link>
					)}
				</div>
			</td>
		</tr>
	);
};

export default WithStaticOptions(SourceItem);
