import React from 'react';
import { ListGroup } from 'react-bootstrap';
import styled from 'styled-components';

interface IDangerousZoneProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const DangerousZone = ({ title = 'Небезпечна зона', className, children }: IDangerousZoneProps) => {
	return (
		<fieldset className={className}>
			<legend>{title}</legend>
			<DangerListGroup className="border-danger">{children}</DangerListGroup>
		</fieldset>
	);
};

export default DangerousZone;

const DangerListGroup = styled(ListGroup)`
	--bs-list-group-border-color: var(--bs-danger, red);
`;
