import React from 'react';
import { useDictionariesQuery } from '../../features/services/moriaApi';
import { IDictionary, TEntityId } from '../../interfaces/common';
import { ICountryDic } from '../../interfaces/dict';

export interface IWithCountries {
	countriesAllIds: TEntityId<string>[];
	countriesById: IDictionary<string, ICountryDic>;
}

const WithCountries =
	<T extends IWithCountries>(WrappedComponent: React.ComponentType<T>) =>
	(props: Omit<T, keyof IWithCountries>) => {
		// eslint-disable-next-line
		const { countries } = useDictionariesQuery(undefined, {
			refetchOnMountOrArgChange: false,
			selectFromResult: ({ data }) => ({ countries: data?.countries }),
		});

		return (
			<WrappedComponent
				{...(props as T)}
				countriesAllIds={countries?.ids || []}
				countriesById={countries?.entities || {}}
			/>
		);
	};

export default WithCountries;
