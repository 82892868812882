import React from 'react';
import { Badge, Button, ButtonGroup } from 'react-bootstrap';
import { BsMagic, BsPlusCircle, BsTrash } from 'react-icons/bs';
import TextIcon from '../../../components/textIcon';
import { BottomStickyDiv } from '../../../styled';

interface IDocumentSourcesActionsProps extends React.AllHTMLAttributes<HTMLDivElement> {
	// autoLinksEnabled: boolean;
	autoLinksLoading: boolean;
	onNewLinkClick: () => void;
	onGetDocumentLinksClick: () => void;
	onDeleteClick?: () => void;
	selectedLinksCount: number;
}
const DocumentSourcesActions = ({
	// autoLinksEnabled,
	autoLinksLoading,
	onNewLinkClick,
	onGetDocumentLinksClick,
	onDeleteClick,
	selectedLinksCount,
}: IDocumentSourcesActionsProps) => {
	return (
		<BottomStickyDiv bottom="-1rem" className="text-center">
			<ButtonGroup className="my-1">
				<Button onClick={onNewLinkClick} variant="outline-primary" disabled={autoLinksLoading}>
					<TextIcon Icon={BsPlusCircle}>Нове посилання</TextIcon>
				</Button>
				<Button
					variant="outline-secondary"
					className="text-nowrap"
					onClick={onDeleteClick}
					disabled={selectedLinksCount === 0}
				>
					<TextIcon Icon={BsTrash} inline>
						Видалити
					</TextIcon>
					{selectedLinksCount !== 0 && (
						<Badge bg="primary" className="ms-1">
							{selectedLinksCount}
						</Badge>
					)}
				</Button>
				<Button variant="outline-secondary" onClick={onGetDocumentLinksClick} disabled={autoLinksLoading}>
					<TextIcon Icon={BsMagic} className={autoLinksLoading ? 'animation-bounce' : undefined}>
						Отримати посилання з документів
					</TextIcon>
				</Button>
			</ButtonGroup>
		</BottomStickyDiv>
	);
};

export default DocumentSourcesActions;
