import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISourceBase } from '../../interfaces/sources';

interface IManageSlice {
	sourceTerm: string;
	sourceOrder: keyof ISourceBase;
	sourcePageNo: number;
}
const initialState: IManageSlice = {
	sourceTerm: '',
	sourceOrder: 'id',
	sourcePageNo: 0,
};
export const manageSlice = createSlice({
	name: 'manage',
	initialState,
	reducers: {
		changeSourceTerm: (state, action: PayloadAction<string>) => {
			if (state.sourceTerm === action.payload) return;
			state.sourceTerm = action.payload;
			state.sourcePageNo = 0;
		},
		changeSourceOrder: (state, action: PayloadAction<keyof ISourceBase>) => {
			if (state.sourceOrder === action.payload) return;
			state.sourceOrder = action.payload;
			state.sourcePageNo = 0;
		},
		changeSourcePageNo: (state, action: PayloadAction<number>) => {
			state.sourcePageNo = action.payload;
		},
	},
});

export const { changeSourceTerm, changeSourceOrder, changeSourcePageNo } = manageSlice.actions;

export default manageSlice.reducer;
