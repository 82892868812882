import Skeleton, { SkeletonProps } from 'react-loading-skeleton';
import styled from 'styled-components';

const GrayerSkeleton = (props: SkeletonProps) => <GSkeleton {...props} />;

export default GrayerSkeleton;

const GSkeleton = styled(Skeleton)`
	--base-color: var(--greyer-skeleton-base-color);
`;
