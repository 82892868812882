import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import styled from 'styled-components';
import TextIcon from '../../components/textIcon';
import { useScreenWidth } from '../../hooks';
import { selectPhotoSearchStore, useAppSelector } from '../../store';
import { getGlobalSelectedFaceSrc } from '../search/faceSearchSlice';

// interface IFloatFaceProps extends React.AllHTMLAttributes<HTMLImageElement> {}
const FloatFace = () => {
	const { selectedFaceSrcTs } = useAppSelector(selectPhotoSearchStore);
	const [selectedFaceSrc, setSelectedFaceSrc] = useState(getGlobalSelectedFaceSrc());
	const [showFace, setShowFace] = useState(true);
	const [stickyImage] = useScreenWidth(1650);

	const onShowFaceClick = useCallback(() => {
		setShowFace((show) => !show);
	}, []);

	useEffect(() => {
		setSelectedFaceSrc(selectedFaceSrcTs ? getGlobalSelectedFaceSrc() : null);
	}, [selectedFaceSrcTs]);

	if (!selectedFaceSrcTs || !selectedFaceSrc) return null;
	return (
		<div
			className={classNames(
				'w-auto',
				stickyImage
					? 'position-sticky bottom-0 d-inline-block mt-3'
					: 'position-fixed start-0 p-0 top-50 translate-middle-y'
			)}
		>
			<FaceContainer className="border border-2 vstack">
				<ShowButton variant="danger" className="py-0 rounded-0 border-0" onClick={onShowFaceClick}>
					Цільове фото
					<TextIcon Icon={showFace ? BsCaretUpFill : BsCaretDownFill} className="ms-1" />
				</ShowButton>
				<Face src={selectedFaceSrc} className={showFace ? undefined : 'd-none'} alt="Обране обличчя" />
			</FaceContainer>
		</div>
	);
};

export default FloatFace;

// const FaceCol = styled.div`
// 	width: auto;
// `;

const FaceContainer = styled.div`
	/* flex: 0 0; */
	--bs-border-color: color-mix(in lch, var(--bs-warning) 30%, var(--bs-danger));
`;

const ShowButton = styled(Button)`
	--bs-btn-bg: color-mix(in lch, var(--bs-warning) 30%, var(--bs-danger));
	--bs-btn-hover-bg: color-mix(in lch, var(--bs-warning) 15%, var(--bs-danger));
`;

const Face = styled.img`
	width: 150px;
	height: 200px;
	object-fit: cover;
`;
