import { useCallback, useEffect, useState } from 'react';
import { Badge, Button, Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { BsPencilSquare, BsSave, BsXLg } from 'react-icons/bs';
import { Navigate, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { showError, showPromiseConfirm, showPromiseError } from '../../../alerts';
import { DEFAULTS, EXIT_CONFIRMATION } from '../../../common';
import Asterisk, { AsteriskText } from '../../../components/Asterisk';
import WithStaticOptions, { IWithStaticOptions } from '../../../components/hoc/withStaticOptions';
import TextIcon from '../../../components/textIcon';
import { useArray, useFormFields } from '../../../hooks';
import { PUB_RELIABILITY_DEFAULT, TContainingOptions, TDisclaimerOptions } from '../../../interfaces/dict';
import {
	IArrayDataOptions,
	IDocumentBase,
	IDocumentSourceView,
	IDocumentTocView,
	ITextArrayDataOptions,
	TDocumentContextType,
} from '../../../interfaces/document';
import { datePeriodIsValid, getDataForSave, onBeforeUnloadStandard } from '../../../utils';
import CheckboxesGroup from '../../controls/CheckboxesGroup';
import DateWithNull from '../../controls/DateWithNull';
import MultiCountrySelector from '../../controls/MultiCountrySelector';
import TextInputsGroup from '../../controls/TextInputsGroup';
import { useDocumentMetadataMutation } from '../../services/documents';
import DocumentSources from './DocumentSources';
import DocumentToc from './DocumentToc';

// const DEFAULT_DOCUMENT: IDocumentBase = {
// 	ID: 0,
// 	DOCUMENT_HASH: '',
// 	REALM: '',
// 	CREATOR_ID: 0,
// 	RESTRICTION: '0',
// 	TASK: 'I',
// 	PRIORITY: '5',
// 	TASK_TYPE: 'I',
// 	VERSION: '0',
// 	SERIES: 'P',
// 	CREATION_DATE: '',
// 	COLLECTION_START_DATE: '',
// 	COLLECTION_STOP_DATE: '',
// 	EVENT_START_DATE: null,
// 	EVENT_STOP_DATE: null,
// 	VALIDITY_DATE: null,
// 	LAT: null,
// 	LON: null,
// 	SUMMARY: '',
// 	COMMENT: null,
// 	STATUS: 'L',
// };

interface IDocumentDataDialogProps extends IWithStaticOptions {}
const DocumentDataDialog = ({ staticOptions }: IDocumentDataDialogProps) => {
	const [tabKey, setTabKey] = useState('main');
	const { data, textExists } = useOutletContext<TDocumentContextType>();
	const [workPeriodIsValid, setWorkPeriodIsValid] = useState<boolean>();
	const [eventPeriodIsValid, setEventPeriodIsValid] = useState<boolean>();

	const navigate = useNavigate();
	const location = useLocation();
	// const inserting = data === undefined;
	// const [initialData, setInitialData] = useState<IDocumentView>(() => data.main);
	// const [initialData, setInitialData] = useState<IDocumentBase>(() => ({
	// 	...DEFAULT_DOCUMENT,
	// 	REALM: realm,
	// 	CREATION_DATE: new Date().toISOString().split('T')[0],
	// 	COLLECTION_START_DATE: new Date().toISOString().split('T')[0],
	// 	COLLECTION_STOP_DATE: new Date().toISOString().split('T')[0],
	// }));
	const [inEditing, setInEditing] = useState(false);
	const { formFields, createChangeHandler, modified } = useFormFields(data.main);
	const [updateDocument, updateDocumentResult] = useDocumentMetadataMutation();
	const [disclaimersState, setDisclaimersState] = useState<ITextArrayDataOptions>(() => ({
		modified: false,
		selected: data?.disclaimers || [],
	}));
	const [containingState, setContainingState] = useState<ITextArrayDataOptions>(() => ({
		modified: false,
		selected: data?.containing || [],
	}));
	const { data: countries, setData: setCountries, modified: countriesModified } = useArray(data?.countries);
	const [titlesState, setTitlesState] = useState<ITextArrayDataOptions>(() => ({
		modified: false,
		selected: data?.titles || [],
	}));
	const [tocState, setTocState] = useState<IArrayDataOptions<IDocumentTocView>>(() => ({
		modified: false,
		selected: data?.toc || [],
	}));
	const [linksState, setLinksState] = useState<IArrayDataOptions<IDocumentSourceView>>(() => ({
		modified: false,
		selected: data?.sources.links || [],
	}));
	const [someIsModified, setSomeIsModified] = useState(false);

	useEffect(() => {
		setSomeIsModified(
			modified ||
				disclaimersState.modified ||
				containingState.modified ||
				countriesModified ||
				titlesState.modified ||
				tocState.modified ||
				linksState.modified
		);
	}, [
		modified,
		disclaimersState.modified,
		containingState.modified,
		countriesModified,
		titlesState.modified,
		tocState.modified,
		linksState.modified,
	]);

	useEffect(() => {
		setWorkPeriodIsValid(datePeriodIsValid(formFields.collection_start_date, formFields.collection_stop_date, false));
		setEventPeriodIsValid(datePeriodIsValid(formFields.event_start_date, formFields.event_stop_date));
	}, [formFields]);

	// useEffect(() => {
	// 	if (!data) return;
	// 	const { main } = data;
	// 	setInitialData({ ...main });
	// 	// setEditableRealms(editableRealms);
	// }, [data]); // eslint-disable-line

	useEffect(() => {
		if (!updateDocumentResult) return;
		const { isError, error, isSuccess } = updateDocumentResult;
		if (isSuccess) return closeDialog();
		if (isError) showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
	}, [updateDocumentResult]); // eslint-disable-line

	const closeDialog = () => {
		navigate('..' + location.search, { replace: true });
	};

	const exitApproved = useCallback(
		async () => (someIsModified ? await showPromiseConfirm(EXIT_CONFIRMATION) : true),
		[someIsModified]
	);

	const handleClose = async () => {
		if (!(await exitApproved())) return;
		closeDialog();
	};

	useEffect(() => {
		window.addEventListener('beforeunload', onBeforeUnloadStandard);
		return () => window.removeEventListener('beforeunload', onBeforeUnloadStandard);
	}, []);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (inEditing) return showError('Завершіть, будь ласка, редагування запису');

		const dataIsValid = await documentDataAreValid({
			containingLength: containingState.selected.length,
			countriesLength: countries.length,
			titlesLength: titlesState.selected.length,
			tocLength: tocState.selected.length,
			linksLength: linksState.selected.length,
			dialogType: 'W',
			resumeMessage: 'Ви впевнені, що бажаєте продовжити зберігання?',
			documentLinks: linksState.selected,
			onLinksDataInvalid: () => setTabKey('sources'),
		});
		if (!dataIsValid) return;
		if (!workPeriodIsValid || !eventPeriodIsValid) {
			showError('Дата закінчення не може бути меншою за дату початку!');
			return setTabKey('main');
		}

		const mainDataToSave = getDataForSave<IDocumentBase, 'id' | 'realm'>(data.main, formFields, ['id', 'realm']);

		updateDocument({
			main: { ...mainDataToSave },
			disclaimers: disclaimersState.selected as TDisclaimerOptions[],
			containing: containingState.selected as TContainingOptions[],
			countries,
			titles: titlesState.selected,
			toc: tocState.selected,
			links: linksState.selected,
			// method: inserting ? DEFAULTS.httpMethod.post : DEFAULTS.httpMethod.put,
			method: DEFAULTS.httpMethod.put,
		});
	};

	const onTabSelect = (newKey: string | null) => setTabKey(newKey || 'main');

	const onInvalid = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.currentTarget.name === 'TITLE') return setTabKey('titles');
		if (event.currentTarget.name === 'SUMMARY') return setTabKey('resume');
		setTabKey('main');
	};

	if (!data.editable) return <Navigate to=".." replace={true} />;

	return (
		<Modal centered show size="xl" fullscreen="xl-down" onHide={handleClose} keyboard={false} backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>
					<TextIcon Icon={BsPencilSquare} className="mt-n1 me-1" />
					Редагування опису дослідження
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-0 h-75v overflow-auto">
				<Tabs activeKey={tabKey} onSelect={onTabSelect} className="mb-3 position-sticky bg-default pt-3 top-0 z-3" fill>
					<Tab eventKey="main" title="Загальні дані" disabled={inEditing}>
						<Form onSubmit={onSubmit} id="fmDocumentData">
							<Row className="mt-3">
								<Form.Group as={Col} controlId="docTaskType">
									<Form.Label>
										Тип завдання <Asterisk />
									</Form.Label>
									<Form.Select value={formFields.task_type} onChange={createChangeHandler('task_type')} autoFocus>
										{staticOptions.TASK_TYPE_OPTIONS &&
											Object.entries(staticOptions.TASK_TYPE_OPTIONS).map(([key, value]) => (
												<option key={key} value={key}>
													{value}
												</option>
											))}
									</Form.Select>
								</Form.Group>
								<Form.Group as={Col} controlId="docTask">
									<Form.Label>
										Завдання <Asterisk />
									</Form.Label>
									<Form.Select value={formFields.task} onChange={createChangeHandler('task')}>
										{staticOptions.TASK_OPTIONS &&
											Object.entries(staticOptions.TASK_OPTIONS).map(([key, value]) => (
												<option key={key} value={key}>
													{value}
												</option>
											))}
									</Form.Select>
								</Form.Group>
							</Row>
							<Row className="mt-3">
								<Form.Group as={Col} controlId="docPriority">
									<Form.Label>
										Пріоритет <Asterisk />
									</Form.Label>
									<Form.Select value={formFields.priority} onChange={createChangeHandler('priority')}>
										{staticOptions.PRIORITY_OPTIONS &&
											Object.entries(staticOptions.PRIORITY_OPTIONS).map(([key, value]) => (
												<option key={key} value={key}>
													{value}
												</option>
											))}
									</Form.Select>
								</Form.Group>
								<Form.Group as={Col} controlId="docVersion">
									<Form.Label>
										Версія <Asterisk />
									</Form.Label>
									<Form.Select value={formFields.version} onChange={createChangeHandler('version')}>
										{staticOptions.VERSION_OPTIONS &&
											Object.entries(staticOptions.VERSION_OPTIONS).map(([key, value]) => (
												<option key={key} value={key}>
													{value}
												</option>
											))}
									</Form.Select>
								</Form.Group>
								<Form.Group as={Col} controlId="docSeries">
									<Form.Label>
										Серія <Asterisk />
									</Form.Label>
									<Form.Select value={formFields.series} onChange={createChangeHandler('series')}>
										{staticOptions.SERIES_OPTIONS &&
											Object.entries(staticOptions.SERIES_OPTIONS).map(([key, value]) => (
												<option key={key} value={key}>
													{value}
												</option>
											))}
									</Form.Select>
								</Form.Group>
							</Row>
							<Row className="mt-3">
								<Form.Group as={Col} xs={4} lg controlId="docEventStart">
									<Form.Label>Дата початку події</Form.Label>
									<DateWithNull
										value={formFields.event_start_date || ''}
										onChange={createChangeHandler('event_start_date')}
										dateIsValid={eventPeriodIsValid === false ? false : null}
									/>
								</Form.Group>
								<Form.Group as={Col} xs={4} lg controlId="docEventStop">
									<Form.Label>Дата закінчення події</Form.Label>
									<DateWithNull
										value={formFields.event_stop_date || ''}
										onChange={createChangeHandler('event_stop_date')}
										dateIsValid={eventPeriodIsValid === false ? false : null}
									/>
								</Form.Group>
								<Form.Group as={Col} xs={4} lg controlId="docValidityDate">
									<Form.Label>Термін актуальності</Form.Label>
									<DateWithNull
										value={formFields.validity_date || ''}
										onChange={createChangeHandler('validity_date')}
									/>
								</Form.Group>
								<Form.Group as={Col} controlId="docLat">
									<Form.Label>Широта</Form.Label>
									<Form.Control
										type="number"
										placeholder="десяткова"
										value={formFields.lat || ''}
										onChange={createChangeHandler('lat')}
										autoComplete="off"
										min={-90}
										max={90}
										step={0.000001}
									/>
								</Form.Group>
								<Form.Group as={Col} controlId="docLon">
									<Form.Label>Довгота</Form.Label>
									<Form.Control
										type="number"
										placeholder="десяткова"
										value={formFields.lon || ''}
										onChange={createChangeHandler('lon')}
										autoComplete="off"
										min={-180}
										max={180}
										step={0.000001}
									/>
								</Form.Group>
							</Row>
							<Row className="mt-3">
								<Form.Group as={Col} controlId="docCollectionStart">
									<Form.Label>
										Дата початку збору інформації <Asterisk />
									</Form.Label>
									<Form.Control
										type="date"
										required
										// placeholder=""
										value={formFields.collection_start_date}
										onChange={createChangeHandler('collection_start_date')}
										autoComplete="off"
										onInvalid={onInvalid}
										className={workPeriodIsValid === false ? 'is-invalid bg-danger-subtle' : undefined}
									/>
								</Form.Group>
								<Form.Group as={Col} controlId="docCollectionStop">
									<Form.Label>
										Дата закінчення збору інформації <Asterisk />
									</Form.Label>
									<Form.Control
										type="date"
										required
										// placeholder=""
										value={formFields.collection_stop_date}
										onChange={createChangeHandler('collection_stop_date')}
										autoComplete="off"
										onInvalid={onInvalid}
										className={workPeriodIsValid === false ? 'is-invalid bg-danger-subtle' : undefined}
									/>
								</Form.Group>
								<Form.Group as={Col} controlId="docCreationDate">
									<Form.Label>
										Дата створення <Asterisk />
									</Form.Label>
									<Form.Control
										type="date"
										required
										// placeholder=""
										value={formFields.creation_date}
										onChange={createChangeHandler('creation_date')}
										autoComplete="off"
										onInvalid={onInvalid}
									/>
								</Form.Group>
							</Row>
							<MultiCountrySelector
								onCountriesChange={setCountries}
								trigrams={countries}
								label={
									<>
										Країни, пов'язані з дослідженням <Asterisk />
										<span className="text-secondary ms-2">(для пошуку введіть кілька букв з назви країни)</span>
									</>
								}
								containerClassName="mt-3"
								// required
							/>
							<CheckboxesGroup
								options={staticOptions.DISCLAIMER_OPTIONS || {}}
								initialOptions={data?.disclaimers}
								idTemplate="docDisclaimers"
								inline
								title="Застереження щодо вмісту дослідження"
								className="mt-3"
								onChange={setDisclaimersState}
							/>
							<CheckboxesGroup
								options={staticOptions.CONTAINING_OPTIONS || {}}
								initialOptions={data?.containing}
								idTemplate="docContaining"
								inline
								title="Що містить дослідження"
								className="mt-3"
								onChange={setContainingState}
								required
							/>
						</Form>
					</Tab>
					<Tab
						eventKey="titles"
						title={
							<>
								Теми <Asterisk />
							</>
						}
						disabled={inEditing}
					>
						<fieldset className="mt-n1 mb-3">
							<legend>
								Головна тема дослідження <Asterisk />
							</legend>
							<Form.Control
								type="text"
								required
								placeholder="Зазвичай це назва файлу, але може бути іншою"
								value={formFields.title}
								onChange={createChangeHandler('title')}
								autoComplete="off"
								maxLength={200}
								form="fmDocumentData"
								onInvalid={onInvalid}
								name="TITLE"
							/>
						</fieldset>
						<fieldset>
							<legend>Додаткові теми дослідження</legend>
							<TextInputsGroup
								// idTemplate="docTitles"
								// title="Набір тем документа"
								// className="mt-3"
								onChange={setTitlesState}
								initialData={data?.titles}
								// required
								setInEditing={setInEditing}
							/>
						</fieldset>
					</Tab>
					<Tab
						eventKey="toc"
						title={
							<>
								Зміст <Asterisk />
							</>
						}
						disabled={inEditing}
					>
						<DocumentToc initialData={data?.toc} onChange={setTocState} setInEditing={setInEditing} />
					</Tab>
					<Tab
						eventKey="sources"
						title={
							<>
								Джерела <Asterisk />
							</>
						}
						disabled={inEditing}
					>
						<DocumentSources
							initialData={data?.sources}
							setInEditing={setInEditing}
							onChange={setLinksState}
							// documentHash={data?.main.document_hash}
							documentId={data.main.id}
							autoLinksEnabled={textExists}
						/>
					</Tab>
					<Tab eventKey="resume" title="Висновки та доповнення" disabled={inEditing}>
						<Form.Group controlId="docSummary" className="mt-3">
							<Form.Label>
								Резюме <Asterisk />
							</Form.Label>
							<Form.Control
								as="textarea"
								required
								rows={10}
								placeholder="Коротко опишіть результат дослідження"
								value={formFields.summary}
								onChange={createChangeHandler('summary')}
								autoComplete="off"
								className="no-resize"
								maxLength={4000}
								form="fmDocumentData"
								onInvalid={onInvalid}
								name="SUMMARY"
							/>
						</Form.Group>
						<Form.Group controlId="docComment" className="mt-3">
							<Form.Label>Коментар</Form.Label>
							<Form.Control
								as="textarea"
								rows={10}
								placeholder="За необхідності"
								value={formFields.comment || ''}
								onChange={createChangeHandler('comment')}
								autoComplete="off"
								className="no-resize"
								maxLength={4000}
								form="fmDocumentData"
							/>
						</Form.Group>
					</Tab>
				</Tabs>
			</Modal.Body>
			<Modal.Footer className="justify-content-between position-sticky bottom-0 bg-default">
				<AsteriskText />
				{/* {!inserting && <Badge bg="secondary">ID: {data.main.ID}</Badge>} */}
				<Badge bg="secondary">ID: {data.main.id}</Badge>
				<div className="hstack gap-1">
					<Button
						disabled={!someIsModified || updateDocumentResult.isLoading || inEditing}
						type="submit"
						form="fmDocumentData"
						variant={inEditing ? 'outline-primary' : 'primary'}
					>
						<TextIcon Icon={BsSave} className={updateDocumentResult.isLoading ? 'animation-bounce' : undefined}>
							Зберегти
						</TextIcon>
					</Button>
					<Button
						variant={inEditing ? 'outline-secondary' : 'secondary'}
						onClick={handleClose}
						type="button"
						disabled={inEditing}
					>
						<TextIcon Icon={BsXLg}>Закрити</TextIcon>
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default WithStaticOptions(DocumentDataDialog);

export interface ICheckDataValidityParams {
	containingLength: number;
	countriesLength: number;
	titlesLength: number;
	tocLength: number;
	linksLength: number;
	dialogType: 'E' | 'W';
	resumeMessage: string;
	documentLinks: IDocumentSourceView[];
	onLinksDataInvalid?: () => void;
}
export const documentDataAreValid = async ({
	containingLength,
	countriesLength,
	titlesLength,
	tocLength,
	linksLength,
	dialogType,
	resumeMessage,
	documentLinks,
	onLinksDataInvalid,
}: ICheckDataValidityParams): Promise<boolean> => {
	if (documentLinks.some(({ source_id }) => source_id === null)) {
		if (onLinksDataInvalid) onLinksDataInvalid();
		showError('Для всіх посилань в документі мають бути вказані їх джерела');
		return false;
	}
	// if (documentLinks.some(({ pub_reliability }) => pub_reliability === PUB_RELIABILITY_DEFAULT)) {
	// 	if (onLinksDataInvalid) onLinksDataInvalid();
	// 	showError('Ви маєте оцінити достовірність інформації кожного доданого в описову частину посилання!');
	// 	return false;
	// }

	const warnings: string[] = [];
	if (containingLength === 0) warnings.push('Має бути вибрана хоча б одна опція вмісту документу');
	if (countriesLength === 0) warnings.push('Має бути вибрана хоча б одна країна');
	/**
	 * Тепер опис сам по собі вже має теме, тому додаткових тем може не бути
	 */
	// if (titlesLength === 0) warnings.push('Має бути вказана хоча б одна тема');
	if (tocLength === 0) warnings.push('Має бути вказаний зміст');
	if (linksLength === 0) warnings.push('Мають бути додані джерела документа');
	if (documentLinks.some(({ pub_reliability }) => pub_reliability === PUB_RELIABILITY_DEFAULT))
		warnings.push('Ви маєте оцінити достовірність інформації кожного доданого в описову частину посилання');
	if (warnings.length === 0) return true;
	const message = (
		<>
			Описова частина документа містить наступні помилки:
			<ul>
				{warnings.map((warning, index) => (
					<li key={index}>{warning}.</li>
				))}
			</ul>
			{resumeMessage}
		</>
	);
	return dialogType === 'E' ? !!(await showPromiseError(message)) : !!(await showPromiseConfirm(message));
};
