import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULTS, ESearchScope, ISearchParams, TNumberWithUndefined } from '../../common';
import { storage } from '../../interfaces/common';
import { ISolrResponse } from '../../interfaces/document';
import { IRecentDocument } from '../services/documents';

// const DEFAULT_PROCESS_HASH = '';
// export const getProcessedHash = (pageNo: number, salt: number) => `${pageNo}_${salt}`;

export interface ISetDocuments {
	searchResponse: ISolrResponse;
	pageCount?: number;
	pageNo: number;
}

export interface ISearchSlice {
	searchResponse: ISolrResponse | undefined;
	// searching: boolean;
	operativeSearchParams: ISearchParams;
	pageNo: number;
	pageCount: TNumberWithUndefined;
	searchingSalt: number;
	// processedHash: string;
	// Група параметрів, що стосуються списку останніх створених документів
	viewAllRecentDocs?: boolean;
	recentDocuments: IRecentDocument[][];
	pageForLoad: number;
}
const initialState: ISearchSlice = {
	searchResponse: undefined,
	// searching: false,
	operativeSearchParams: { ...DEFAULTS.searchParams, realm: storage.get('realm') || DEFAULTS.searchParams.realm },
	pageNo: 0,
	pageCount: undefined,
	searchingSalt: new Date().valueOf(),
	// processedHash: DEFAULT_PROCESS_HASH,
	recentDocuments: [],
	pageForLoad: 0,
};
export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		// setSearching: (state, action: PayloadAction<boolean>) => {
		// 	state.searching = action.payload;
		// },
		setPageNo: (state, action: PayloadAction<number>) => {
			state.pageNo = action.payload;
		},
		setDocuments: (state, action: PayloadAction<ISetDocuments>) => {
			const { searchResponse, pageCount, pageNo } = action.payload;
			state.searchResponse = searchResponse;
			// state.searching = false;
			state.pageNo = pageNo;
			if (pageCount !== undefined) state.pageCount = pageCount;
			// state.processedHash = getProcessedHash(pageNo, state.searchingSalt);
		},
		clearDocuments: (state) => {
			state.searchResponse = undefined;
			// state.searching = false;
			state.pageNo = 0;
			state.pageCount = undefined;
			// state.processedHash = DEFAULT_PROCESS_HASH;
		},
		changeOperativeSearchParams: (state, action: PayloadAction<ISearchParams>) => {
			// Змінюємо сегмент тільки якщо виконується реальний пошук
			const newRealm =
				action.payload.term.trim() !== '' ? action.payload.realm : storage.get('realm') || DEFAULTS.searchParams.realm;
			storage.setSmart('realm', newRealm === DEFAULTS.searchParams.realm ? undefined : newRealm);
			state.operativeSearchParams = { ...action.payload, realm: newRealm };
			state.searchingSalt = new Date().valueOf();
			state.pageNo = 0;
		},
		changeTerm: (state, action: PayloadAction<string>) => {
			state.operativeSearchParams.term = action.payload;
		},
		changeScope: (state, action: PayloadAction<ESearchScope>) => {
			state.operativeSearchParams.scope = action.payload;
		},
		changeSeverity: (state, action: PayloadAction<string>) => {
			state.operativeSearchParams.severity = action.payload;
		},
		changeRealm: (state, action: PayloadAction<string>) => {
			state.operativeSearchParams.realm = action.payload;
		},
		changeSearchingSalt: (state) => {
			state.searchingSalt = new Date().valueOf();
			state.pageNo = 0;
		},
		changeViewAllRecentDocs: (state, action: PayloadAction<boolean>) => {
			if (action.payload === state.viewAllRecentDocs) return;
			// Якщо змінюється діапазон пошуку, то очищаємо список документів
			state.viewAllRecentDocs = action.payload;
			state.recentDocuments = [];
			state.pageForLoad = 0;
		},
		addRecentDocumentsByPage: (state, action: PayloadAction<{ page: number; documents: IRecentDocument[] }>) => {
			const { page, documents } = action.payload;
			if (page === 0) state.recentDocuments = [];
			state.recentDocuments[page] = documents;
			if (documents.length < DEFAULTS.recentDocumentsPerPage) state.recentDocuments[page + 1] = [];
		},
		incPageForLoad: (state, action: PayloadAction<void>) => {
			state.pageForLoad = state.pageForLoad + 1;
		},
		clearRecentDocuments: (state, action: PayloadAction<void>) => {
			state.recentDocuments = [];
			state.pageForLoad = 0;
		},
	},
});

export const {
	setPageNo,
	setDocuments,
	clearDocuments,
	changeOperativeSearchParams,
	changeScope,
	changeTerm,
	changeSeverity,
	changeRealm,
	changeSearchingSalt,
	changeViewAllRecentDocs,
	addRecentDocumentsByPage,
	incPageForLoad,
	clearRecentDocuments,
} = searchSlice.actions;

export default searchSlice.reducer;
