import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { DEFAULTS } from '../../../common';
import Asterisk from '../../../components/Asterisk';
import { IDictionary, ISelectOption } from '../../../interfaces/common';
import { IUser } from '../../../interfaces/users';
import { getUserLabel } from '../../services/users';

interface IUserSelectorProps {
	onUserChange?: (userId: number) => void;
	userId: number | null;
	containerClassName?: string;
	className?: string;
	allUsers: IDictionary<number, IUser>;
	label?: string | JSX.Element;
	required?: boolean;
	name?: string;
}
const UserSelector = ({ onUserChange, userId, allUsers, containerClassName, ...rest }: IUserSelectorProps) => {
	const users: ISelectOption[] = useMemo(
		() =>
			Object.values(allUsers)
				.map((user) => ({
					label: getUserLabel(user),
					value: user?.id.toString() || '',
				}))
				.sort((item1, item2) => (item1.label < item2.label ? -1 : 1)),
		[allUsers]
	);

	const selectedUser: ISelectOption = useMemo(
		() => ({
			label: getUserLabel(allUsers[userId ?? -1]),
			value: allUsers[userId ?? -1]?.id.toString() ?? '',
		}),
		[allUsers, userId]
	);

	const onChange = (value: SingleValue<ISelectOption>, actionMeta: ActionMeta<ISelectOption>) => {
		if (onUserChange && value) onUserChange(parseInt(value.value));
	};

	return (
		<Form.Group className={containerClassName}>
			<label className="form-label">
				Контролер <Asterisk />
			</label>
			<Select
				classNamePrefix="themed-select"
				isSearchable
				options={users}
				value={selectedUser}
				onChange={onChange}
				placeholder="країна..."
				noOptionsMessage={() => DEFAULTS.noMatch}
				{...rest}
			/>
		</Form.Group>
	);
};

export default UserSelector;
