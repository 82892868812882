import React from 'react';

interface IResponsibleTextProps extends React.AllHTMLAttributes<HTMLDivElement> {
	visibleOn: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
	hiddenPart: string;
}
const ResponsibleText = ({ children, visibleOn, hiddenPart, ...rest }: IResponsibleTextProps) => {
	return (
		<span {...rest}>
			<span className={`d-none d-${visibleOn}-inline`}>{hiddenPart}</span>
			{children}
		</span>
	);
};

export default ResponsibleText;
