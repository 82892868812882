import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';

interface IDangerousListItemProps extends React.AllHTMLAttributes<HTMLButtonElement> {
	onClick: () => void;
	description?: string;
	variant?: string;
}
const DangerousListItem = ({
	onClick,
	title,
	description = '',
	variant = 'outline-danger',
	children,
	disabled,
}: IDangerousListItemProps) => {
	return (
		<ListGroup.Item className="hstack justify-content-between align-items-center gap-1">
			<div>
				<strong>{title}</strong>
				<span className="ms-1">{description}</span>
			</div>
			<Button variant={variant} tabIndex={-1} onClick={onClick} disabled={disabled}>
				{children}
			</Button>
		</ListGroup.Item>
	);
};

export default DangerousListItem;
