import { Button } from 'react-bootstrap';
import { BsPencilSquare, BsSave, BsTrash, BsXLg } from 'react-icons/bs';

interface IEditButtonsGroupProps {
	showEditingButtons: boolean;
	showEditButtons: boolean;
	editButtonsDisabled: boolean;
}
const EditButtonsGroup = ({ showEditingButtons, showEditButtons, editButtonsDisabled }: IEditButtonsGroupProps) => {
	return (
		<>
			{showEditingButtons && (
				<>
					<Button variant="primary" title="Зберегти" type="submit">
						<BsSave />
					</Button>
					<Button variant="secondary" title="Відмінити" type="reset">
						<BsXLg />
					</Button>
				</>
			)}
			{showEditButtons && (
				<>
					<Button
						variant="outline-secondary"
						title="Редагувати"
						className="btn-action"
						data-action="edit"
						type="button"
						disabled={editButtonsDisabled}
					>
						<BsPencilSquare />
					</Button>
					<Button
						variant="outline-secondary"
						title="Видалити"
						className="btn-action"
						data-action="delete"
						type="button"
						disabled={editButtonsDisabled}
					>
						<BsTrash />
					</Button>
				</>
			)}
		</>
	);
};

export default EditButtonsGroup;
