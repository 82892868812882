import React from 'react';
import { useDictionariesQuery } from '../../features/services/moriaApi';
import { ENTITY_STATE_STUB, IDictionaries } from '../../interfaces/dict';

export interface IWithDictionaries extends IDictionaries {}

const WithDictionaries =
	<T extends IWithDictionaries>(WrappedComponent: React.ComponentType<T>) =>
	(props: Omit<T, keyof IWithDictionaries>) => {
		// eslint-disable-next-line
		const { data } = useDictionariesQuery(undefined, {
			refetchOnMountOrArgChange: false,
		});

		return (
			<WrappedComponent
				{...(props as T)}
				realms={data?.realms || ENTITY_STATE_STUB}
				countries={data?.countries || ENTITY_STATE_STUB}
				staticOptions={data?.staticOptions || {}}
			/>
		);
	};

export default WithDictionaries;
