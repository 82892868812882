import { Button, Form, FormControlProps, InputGroup } from 'react-bootstrap';

interface IDateWithNullProps extends FormControlProps {
	onChange: (
		event?: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
		newValue?: string | number | null
	) => void;
	dateIsValid?: boolean | null;
}
const DateWithNull = ({ className, onChange, dateIsValid = null, ...rest }: IDateWithNullProps) => {
	const onClearClick = () => onChange(undefined, null);

	return (
		<InputGroup className={className}>
			<Form.Control
				type="date"
				onChange={onChange}
				autoComplete="off"
				className={dateIsValid === null ? undefined : dateIsValid ? 'is-valid' : 'is-invalid bg-danger-subtle'}
				{...rest}
			/>
			<Button variant="outline-secondary" title="Очистити" onClick={onClearClick}>
				&empty;
			</Button>
		</InputGroup>
	);
};

export default DateWithNull;
