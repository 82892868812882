import { Col, Container, Row } from 'react-bootstrap';
import { DEFAULTS } from '../common';
import Params from '../features/params/Params';
import SearchForm from '../features/search/SearchForm';
import SearchResults from '../features/search/SearchResults';
import { useScreenWidth } from '../hooks';

// interface IWorkPlaceProps extends React.AllHTMLAttributes<HTMLDivElement> {
// 	accessToken: TStringWithUndefined;
// }
const WorkPlace = () => {
	// const [userMetadata, setUserMetadata] = useState(null);

	// const onGetClick = () => {
	// 	const getAPI = async () => {
	// 		try {
	// 			// const response = await fetch(`api/test?aaa=bbb&ccc=1111`, {
	// 			// const response = await fetch(`api/private`, {
	// 			const response = await fetch(`api/private-scoped`, {
	// 				headers: {
	// 					Authorization: `Bearer ${accessToken}`,
	// 				},
	// 			});
	// 			if (!response.ok) throw new Error(`${response.status}: ${response.statusText}`);
	// 			const data = await response.json();
	// 			console.log(data);
	// 		} catch (e) {
	// 			showSystemError(e as Error);
	// 			// console.log((e as Error).message);
	// 		}
	// 	};

	// 	getAPI();

	// const getUserMetadata = async () => {
	// 	const domain = 'dev-mtz-3264.us.auth0.com';

	// 	try {
	// 		const accessToken = await getAccessTokenSilently({
	// 			audience: `https://${domain}/api/v2/`,
	// 			// audience: `http://localhost:3000/`,
	// 			scope: 'read:current_user',
	// 		});

	// 		console.log(accessToken);

	// 		const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user?.sub}`;

	// 		const metadataResponse = await fetch(userDetailsByIdUrl, {
	// 			headers: {
	// 				Authorization: `Bearer ${accessToken}`,
	// 			},
	// 		});
	// 		console.log(metadataResponse);

	// 		const { user_metadata } = await metadataResponse.json();

	// 		setUserMetadata(user_metadata);
	// 	} catch (e: any) {
	// 		console.log(e.message);
	// 	}
	// };

	// getUserMetadata();
	// };

	const [screenIsMdOrSmall] = useScreenWidth(DEFAULTS.screenMdBorder);

	return (
		<Container fluid={DEFAULTS.fluid} className="flex-grow-1">
			<Row>
				<Col xs={12} lg={8}>
					{screenIsMdOrSmall && <SearchForm className="mb-1" />}
					<SearchResults />
				</Col>
				<Col xs={12} lg={4}>
					<Params />
				</Col>
			</Row>
		</Container>
	);
};

export default WorkPlace;
