import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IAuth0AccessToken, TStringWithUndefined } from '../../common';
import { selectAppStore, useAppSelector } from '../../store';
import { changeAccessToken } from './appSlice';

const AccessTokenUpdater = () => {
	const { accessToken } = useAppSelector(selectAppStore);
	const dispatch = useDispatch();
	const { isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup, loginWithRedirect } = useAuth0();

	const getNewAccessToken = useCallback(async () => {
		let newAccessToken: TStringWithUndefined;
		try {
			// accessToken = await getAccessTokenSilently(getTokenSilentlyAuthOptions(scope));
			newAccessToken = await getAccessTokenSilently();
		} catch (error) {
			try {
				newAccessToken = await getAccessTokenWithPopup();
			} catch (error) {
				loginWithRedirect({
					authorizationParams: {
						redirect_uri: window.location.href,
					},
				});
				return '';
			}
		}
		// console.log(newAccessToken);

		dispatch(changeAccessToken(newAccessToken || undefined));
	}, [dispatch, getAccessTokenSilently, getAccessTokenWithPopup, loginWithRedirect]);

	useEffect(() => {
		if (!accessToken) return;
		const { iat, exp } = jwtDecode(accessToken) as IAuth0AccessToken;
		// console.log(`exp: ${new Date(decoded.exp * 1000).toLocaleString()}`);

		const correctedUpdateAfterMs = (exp - iat - 30) * 1000;
		// const updateAfterMs = exp * 1000 - new Date().valueOf() - 30000;
		// console.log(updateAfterMs, correctedUpdateAfterMs);
		// console.log(new Date(new Date().valueOf() + correctedUpdateAfterMs));

		const timer = setTimeout(getNewAccessToken, correctedUpdateAfterMs);
		return () => clearTimeout(timer);
	}, [accessToken, getNewAccessToken]);

	useEffect(() => {
		if (!isAuthenticated) {
			dispatch(changeAccessToken(undefined));
			return;
		}
		if (!accessToken) getNewAccessToken();
	}, [isAuthenticated, dispatch, getNewAccessToken, accessToken]);

	return null;
};

export default AccessTokenUpdater;
