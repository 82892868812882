import { useCallback, useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { showError, showPromiseConfirm } from '../../alerts';
import { DEFAULTS } from '../../common';
import TextIcon from '../../components/textIcon';
import { useAppDispatch } from '../../store';
import { clearRecentDocuments } from '../search/searchSlice';
import { useDeleteDocumentMutation } from '../services/documents';

interface IDeleteDocumentButtonProps {
	documentId: number;
	disabled: boolean;
}
const DeleteDocumentButton = ({ documentId, disabled }: IDeleteDocumentButtonProps) => {
	const dispatch = useAppDispatch();
	const [deleteDocument, deleteDocumentResult] = useDeleteDocumentMutation();
	const navigate = useNavigate();
	const location = useLocation();

	const onDeleteClick = useCallback(async () => {
		if (!(await showPromiseConfirm('Ви дійсно бажаєте видалити дане дослідження?'))) return;
		await deleteDocument({ id: documentId });
	}, [deleteDocument, documentId]);

	useEffect(() => {
		const { isUninitialized, isError, error, isSuccess } = deleteDocumentResult;
		if (isUninitialized) return;
		if (isSuccess) {
			dispatch(clearRecentDocuments());
			return navigate('/' + location.search, { replace: true });
		}
		if (isError) return showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
	}, [deleteDocumentResult]); // eslint-disable-line

	return (
		<>
			<Dropdown.Divider />
			<Dropdown.Item as={Button} onClick={onDeleteClick} disabled={disabled}>
				<TextIcon Icon={BsTrash}>Видалити дослідження</TextIcon>
			</Dropdown.Item>
		</>
	);
};

export default DeleteDocumentButton;
