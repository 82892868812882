import { skipToken } from '@reduxjs/toolkit/query';
import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { BsStopwatch } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { showSystemError } from '../../alerts';
import { DEFAULTS } from '../../common';
import FetchError from '../../components/FetchError';
import HeaderWithImage from '../../components/HeaderWithImage';
import PageTitle from '../../components/PageTitle';
import SearchPagination from '../../components/Pagination';
import SearchWaiter from '../../components/SearchWaiter';
import ImgFileManager from '../../img/undraw_file_manager_re_ms29.svg';
import VoidImage from '../../img/undraw_void_-3-ggu.svg';
import { RootState } from '../../store';
import { getTranslatedTerm } from '../../termTranslator';
import DocumentList from '../documents/DocumentList';
import RecentDocuments from '../documents/recent/RecentDocuments';
import { ISearchRequestParams, useSearchDocumentsQuery } from '../services/documents';
import { clearDocuments, setDocuments, setPageNo } from './searchSlice';

interface ISearchResultsProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const SearchResults = ({ clearDocuments, setDocuments, setPageNo, searchState }: ISearchResultsProps) => {
	const { pageNo, pageCount, searchResponse, searchingSalt, operativeSearchParams } = searchState;
	const [searchParams] = useSearchParams();
	const [searchRequestParams, setSearchRequestParams] = useState<ISearchRequestParams>();
	const { data: response, error, isFetching } = useSearchDocumentsQuery(searchRequestParams || skipToken);

	/**
	 * При натисканні на кнопку пошуку useEffect в SearchForm змінить operativeSearchParams
	 * та searchingSalt відповідно до нових searchParams. Тому ми виконуємо пошук при зміні
	 * searchingSalt, або pageNo. При цьому operativeSearchParams будуть містити параметри,
	 * за якими має відбутись пошук.
	 */
	useEffect(() => {
		if (operativeSearchParams.term.trim() === '') {
			clearDocuments();
			setSearchRequestParams(undefined);
		} else {
			try {
				setSearchRequestParams({
					params: {
						...operativeSearchParams,
						term: getTranslatedTerm(operativeSearchParams),
					},
					pageNo,
					salt: searchingSalt,
				});
			} catch (error) {
				showSystemError(error as Error);
			}
		}
		// ! Скролінг тут треба проводити лише якщо користувач ініціював новий пошук.
		// ! Простіше всього виконати це при ініціації пошуку в пошуковій формі, а не тут.
		// scrollWindowToTop('auto');
	}, [pageNo, searchingSalt]); // eslint-disable-line

	useEffect(() => {
		if (!response) return;

		setDocuments({
			searchResponse: response,
			pageNo,
			pageCount: pageNo === 0 ? Math.ceil(response.response.numFound / DEFAULTS.documentsPerPage) : undefined,
		});
	}, [response]); // eslint-disable-line

	const onPageClick = (pageNo: number) => {
		setPageNo(pageNo);
	};

	const searchedTerm = searchParams.get('term');

	return (
		<>
			<PageTitle title={searchedTerm ? `«${searchedTerm}»` : ''} />
			{error && <FetchError error={error} className="w-50 mt-3" />}
			{!searchResponse || pageCount === undefined || pageCount === 0 ? (
				!searchResponse ? (
					<HeaderWithImage
						imgSrc={ImgFileManager}
						alt="Зображення файлів"
						title="Останні дослідження"
						imgClassName="order-1"
						gap={0}
						className="position-relative"
					>
						<RecentDocuments />
					</HeaderWithImage>
				) : (
					<HeaderWithImage
						imgSrc={VoidImage}
						alt="Зображення відсутності результатів"
						headerClassName="text-secondary"
						title="За Вашим пошуковим запитом нічого не знайдено"
						className="col"
					/>
				)
			) : (
				<>
					<div>
						Знайдено документів: {searchResponse.response.numFound.toLocaleString(DEFAULTS.locale)}
						<span className="text-muted ms-1">
							({searchResponse.responseHeader.QTime.toLocaleString(DEFAULTS.locale)} мс)
						</span>
						{searchResponse.responseHeader.partialResults && (
							<Alert variant="info" className="mt-1">
								<BsStopwatch className="mt-n1" /> <span className="fw-bolder">Часткові результати.</span> Пошук було
								примусово припинено у&nbsp;зв’язку з&nbsp;тим, що&nbsp;виконання його у&nbsp;повному обсязі потребувало
								надто багато часу. Якщо&nbsp;результати Вас не&nbsp;задовольняють, спробуйте виконати цей&nbsp;же пошук
								ще&nbsp;раз.
							</Alert>
						)}
					</div>
					<DocumentList
						documents={searchResponse.response.docs}
						firstNo={pageNo * DEFAULTS.documentsPerPage + 1}
						className="my-3"
						highlighting={searchResponse.highlighting}
						lastPage={pageNo === pageCount - 1}
						maxScore={searchResponse.response.maxScore}
					/>
					<SearchPagination pageCount={pageCount} activePageNo={pageNo} onPageClick={onPageClick} />
				</>
			)}
			{isFetching && <SearchWaiter />}
		</>
	);
};

const mapState = (state: RootState) => ({
	searchState: state.search,
});

const mapDispatch = { clearDocuments, setPageNo, setDocuments };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SearchResults);
