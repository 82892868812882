import React from 'react';
import { Form } from 'react-bootstrap';
import { BsDatabase } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import QuestionButton from '../../components/QuestionButton';
import VerifiedMark from '../../components/VerifiedMark';
import WithRealms, { IWithRealms } from '../../components/hoc/withRealms';
import { RootState } from '../../store';
import { changeRealm } from '../search/searchSlice';

interface IRealmsProps extends PropsFromRedux, IWithRealms, React.AllHTMLAttributes<HTMLDivElement> {}
const Realms = ({ changeRealm, realm, className, realmsAllIds, realmsById }: IRealmsProps) => {
	const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;
		changeRealm(value);
	};

	return (
		<div className={className}>
			<label className="form-label d-flex justify-content-between">
				<span className="fw-bolder">
					<BsDatabase className="me-1 mt-n1" />
					Сегмент даних
				</span>
				<QuestionButton title="Інформаційні матеріали в системі поділено на сегменти для покращення деталізації пошуку." />
			</label>
			{/* <ToggleButtonGroup type="radio" value={realm} name="search_realm" vertical className="w-100">
				<ToggleButton id="search_realm_all" value={'*'} variant="outline-secondary" onChange={onRadioChange}>
					Всі сегменти
				</ToggleButton>
				{realmsAllIds.map((name) => (
					<ToggleButton
						key={name}
						id={`search_realm_${name}`}
						value={name}
						variant="outline-secondary"
						onChange={onRadioChange}
					>
						{realmsById[name]?.title} <VerifiedMark draft={realmsById[name]?.draft} withColors={name !== realm} />
					</ToggleButton>
				))}
			</ToggleButtonGroup> */}
			<Form.Group controlId="search_realm">
				<Form.Check
					type="radio"
					label="Всі сегменти"
					name="search_realm"
					id="search_realm_all"
					checked={realm === '*'}
					value="*"
					onChange={onRadioChange}
				/>
				{realmsAllIds.map((name) => (
					<div className="d-flex justify-content-between align-items-center" key={name}>
						<Form.Check
							type="radio"
							label={
								<div className="d-flex align-items-center gap-1">
									<span>{realmsById[name]?.title}</span>
									<VerifiedMark draft={realmsById[name]?.draft} className="mt-n1" />
								</div>
							}
							name="search_realm"
							id={`search_realm_${name}`}
							checked={realm === name}
							value={name}
							onChange={onRadioChange}
						/>
						<QuestionButton title={realmsById[name]?.description} className="mt-n1" />
					</div>
				))}
			</Form.Group>
		</div>
	);
};

const mapState = (state: RootState) => ({
	realm: state.search.operativeSearchParams.realm,
});

const mapDispatch = {
	changeRealm,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WithRealms(Realms));
