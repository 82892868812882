import React from 'react';
import styled from 'styled-components';
import IssueCreator from '../features/issues/IssueCreator';
import { TopScroller } from './TopScroller';

interface IFixedActionPanelProps extends React.AllHTMLAttributes<HTMLDivElement> {
	hasAccess?: boolean;
}
const FixedActionPanel = ({ hasAccess }: IFixedActionPanelProps) => {
	return (
		<ActionPanel className="position-fixed vstack justify-content-center gap-1 z-1">
			{hasAccess && <IssueCreator />}
			<TopScroller />
		</ActionPanel>
	);
};

export default FixedActionPanel;

const ActionPanel = styled.div`
	right: 0.67em;
	bottom: 0;
	height: 80px;
`;
