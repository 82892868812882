import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TNumberOrNull, TStringOrNull } from '../../common';

export interface IFaceSearchSlice {
	photoSrcTs: TNumberOrNull;
	selectedFaceIndex: TNumberOrNull;
	selectedFaceSrcTs: TNumberOrNull;
}
const initialState: IFaceSearchSlice = {
	photoSrcTs: null,
	selectedFaceIndex: null,
	selectedFaceSrcTs: null,
};
export const photoSearchSlice = createSlice({
	name: 'photoSearch',
	initialState,
	reducers: {
		changePhotoSrcTs: (state, action: PayloadAction<TNumberOrNull | undefined>) => {
			if (action.payload === state.photoSrcTs) return;
			state.photoSrcTs = action.payload ?? new Date().valueOf();
			state.selectedFaceIndex = null;
			state.selectedFaceSrcTs = null;
		},
		changeSelectedFaceIndex: (state, action: PayloadAction<TNumberOrNull>) => {
			if (state.selectedFaceIndex === action.payload) return;
			state.selectedFaceIndex = action.payload;
			state.selectedFaceSrcTs = null;
		},
		changeSelectedFaceSrcTs: (state, action: PayloadAction<TNumberOrNull>) => {
			state.selectedFaceSrcTs = action.payload;
		},
	},
});

export const { changeSelectedFaceIndex, changePhotoSrcTs, changeSelectedFaceSrcTs } = photoSearchSlice.actions;

export default photoSearchSlice.reducer;

/**
 * Прийнято рішення зберігати фото не в state, а в якості глобальних змінних,
 * тому що їх обсяг достатньо великий і це приводить до критичних проблем
 * з серіалізацією об'єктів при використанні Redux DevTools (page crash).
 * У зв'язку з цим в state зберігається не сам об'єкт, а час (timestamp) його останньої зміни,
 * що дає змогу "ловити" момент зміни зображень в інших компонентах.
 * Відповідно, після зміни об'єктів шляхов присвоєння значення наведеним нижче
 * глобальним об'єктом необхідно викликати функцію зміни timestamp відповідного об'єкта
 * в state.
 */
let globalPhotoSrc: TStringOrNull = null;
export const getGlobalPhotoSrc = () => globalPhotoSrc;
export const setGlobalPhotoSrc = (newPhotoSrc: TStringOrNull) => (globalPhotoSrc = newPhotoSrc);
let globalSelectedFaceSrc: TStringOrNull = null;
export const getGlobalSelectedFaceSrc = () => globalSelectedFaceSrc;
export const setGlobalSelectedFaceSrc = (newFace: TStringOrNull) => (globalSelectedFaceSrc = newFace);
