import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { formatDocumentDate } from '../../common';
import DocumentIcon from './DocumentIcon';

interface IDocumentExternalDataProps extends React.AllHTMLAttributes<HTMLDivElement> {
	date: string;
	top?: string;
	ext: string;
	fileIconClassName?: string;
}
const DocumentExternalData = ({ date, className, top = '0', ext, fileIconClassName }: IDocumentExternalDataProps) => {
	return (
		<DocumentDate
			className={classNames(
				'position-absolute h6 d-none d-xxl-flex flex-column align-items-center justify-content-between',
				className
			)}
			top={top}
		>
			<span title="Приблизна дата створення документа" className="text-secondary">
				{formatDocumentDate(date)}
			</span>
			<DocumentIcon ext={ext} className={fileIconClassName} />
		</DocumentDate>
	);
};

export default DocumentExternalData;

interface IDocumentDateProps {
	top: string;
}
const DocumentDate = styled.div<IDocumentDateProps>`
	right: 100%;
	top: ${(props) => props.top};
`;
