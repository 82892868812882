import { useEffect } from 'react';
import styled from 'styled-components';
import Dwarf from '../img/dwarf.webp';
import { DwarfImage } from '../styled';
import LoginButton from './LoginButton';

const SplashScreen = () => {
	useEffect(() => {
		const body = document.querySelector('body');
		if (!body) return;

		body.classList.add('not-auth');

		return () => body.classList.remove('not-auth');
	}, []);

	return (
		<SplashContainer className="d-flex flex-row gap-2 align-items-center m-auto">
			<DwarfImage src={Dwarf} />
			<LoginButton large />
		</SplashContainer>
	);
};

export default SplashScreen;

const SplashContainer = styled.div`
	padding-bottom: 64px;
`;
