import { useEffect, useState } from 'react';
import { showAlert } from '../../alerts';
import Dwarf from '../../img/dwarf.webp';
import { DwarfImage } from '../../styled';
import { useAppVersionQuery } from '../services/moriaApi';

// const POLLING_INTERVAL = 10 * 1000;
const POLLING_INTERVAL = 5 * 60 * 1000;

const AppVersionChecker = () => {
	const [appVersion, setAppVersion] = useState<string>();
	const { data } = useAppVersionQuery(undefined, { pollingInterval: POLLING_INTERVAL });

	const updatePage = () => window.location.reload();

	useEffect(() => {
		if (!data) return;
		if (appVersion === data.appVersion) return;
		if (appVersion === undefined) return setAppVersion(data.appVersion);
		showAlert(
			<div className="d-flex gap-3 align-items-center">
				<DwarfImage src={Dwarf} height="80px" />
				<div>
					Програмне забезпечення було оновлене. Для&nbsp;завершення оновлення дана веб-сторінка має&nbsp;бути
					перезавантажена.
				</div>
			</div>,
			'Оновлення',
			updatePage
		);
		setTimeout(updatePage, 30 * 1000);
	}, [data, appVersion]);

	return null;
};

export default AppVersionChecker;
