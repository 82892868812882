import React from 'react';
import styled from 'styled-components';
import { DEFAULTS, formatDocumentDate } from '../../common';
import DownloadDocumentButton from '../../components/DownloadButton';
import QueryLink from '../../components/QueryLink';
import ScorePanel from '../../components/ScorePanel';
import VerifiedMark from '../../components/VerifiedMark';
import { IDictionary } from '../../interfaces/common';
import { IRealmDic } from '../../interfaces/dict';
import { ISolrDocument } from '../../interfaces/document';
import DocumentExternalData from './DocumentExternalData';

interface IDocumentItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	document: ISolrDocument;
	no: number;
	hl?: string[];
	maxScore: number;
	realms: IDictionary<string, IRealmDic>;
}
const DocumentItem = ({ document, no, hl, maxScore, realms }: IDocumentItemProps) => {
	const { id, title, create_date, ext, score, realm, hidden } = document;

	return (
		<div className="bg-default border-0 position-relative mb-1">
			<div className="d-flex gap-2 align-items-center">
				<h3 className="flex-shrink-0 m-0">{no}.</h3>
				{!hidden ? (
					<QueryLink
						to={`/${DEFAULTS.routes.doc}/${id}`}
						className="h3 text-truncate link-primary m-0"
						state={DEFAULTS.fromListState}
						// data-id={id}
					>
						{title}
					</QueryLink>
				) : (
					<h3 className="m-0 text-secondary">Документ в процесі створення</h3>
				)}
				<VerifiedMark draft={realms[realm]?.draft} title={realms[realm]?.title} className="flex-shrink-0 fs-4 mt-n1" />
				<div className="ms-auto flex-shrink-0 d-flex flex-column align-items-end">
					{/* <h6 title="Приблизна дата створення документа" className="text-secondary">
						{formatDocumentDate(create_date)}
					</h6> */}
					<ScorePanel current={score} max={maxScore} />
					{!hidden && (
						<DownloadDocumentButton
							document={document}
							className="link-secondary p-0 d-none d-xxl-block"
							iconClassName="d-inline-block d-xxl-none"
						/>
					)}
				</div>
			</div>
			<div className="d-flex d-xxl-none justify-content-between align-items-center">
				<h6 title="Приблизна дата створення документа" className="text-secondary m-0">
					{formatDocumentDate(create_date)}
				</h6>
				{!hidden && (
					<DownloadDocumentButton
						document={document}
						className="link-secondary p-0"
						// iconClassName="d-inline-block d-xxl-none"
					/>
				)}
			</div>
			{/* <h6 title="Приблизна дата створення документа" className="text-secondary">
				{formatDocumentDate(create_date)}
			</h6> */}
			<DocumentExternalData date={create_date} className="pe-3" ext={ext} top="0.2rem" />
			{hl && hl.length !== 0 ? (
				<CardText className="mt-3 text-break">
					{hl.filter(Boolean).map((line, index) => (
						<p key={index} dangerouslySetInnerHTML={{ __html: line }} />
					))}
				</CardText>
			) : !hidden ? (
				<p>
					Для даного документу дуже складно підсвітити введені Вами слова пошукового запиту, але вони в ньому точно є!
				</p>
			) : (
				<p className="text-secondary">
					Результати даного дослідження наразі готуються до публікації. Невдовзі матеріали будуть доступні для
					перегляду.
				</p>
			)}
		</div>
	);
};

export default DocumentItem;

const CardText = styled.div`
	p {
		margin-bottom: 0.5rem;
		/* &:last-child {
			margin-bottom: 0;
		} */
	}
`;
