import { useAuth0 } from '@auth0/auth0-react';
import { skipToken } from '@reduxjs/toolkit/query';
import React from 'react';
import { useActiveUserDataQuery } from '../../features/services/users';
import { IActiveUserData } from '../../interfaces/dict';
import { selectAppStore, useAppSelector } from '../../store';

export interface IWithActiveUserData {
	activeUserData?: IActiveUserData;
}

const WithActiveUserData =
	<T extends IWithActiveUserData>(WrappedComponent: React.ComponentType<T>) =>
	(props: Omit<T, keyof IWithActiveUserData>) => {
		// eslint-disable-next-line
		const { accessToken } = useAppSelector(selectAppStore);
		// eslint-disable-next-line
		const { user } = useAuth0();

		// eslint-disable-next-line
		const { data } = useActiveUserDataQuery(user?.email || skipToken, {
			skip: !accessToken,
		});

		return <WrappedComponent {...(props as T)} activeUserData={user?.email && accessToken ? data : undefined} />;
	};

export default WithActiveUserData;
