import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DEFAULTS } from '../../common';
import { QUERY_TAGS } from '../../interfaces/common';
import { IDictionaries } from '../../interfaces/dict';
import { RootState } from '../../store';

export type TIssueType = 'b' | 'e' | 'h' | 'q';
export interface IIssue {
	title: string;
	body?: string;
	location: string;
	type: TIssueType;
}

export const moriaApi = createApi({
	reducerPath: 'moriaApi',
	baseQuery: fetchBaseQuery({
		baseUrl: './api/',
		prepareHeaders: (headers, { getState }) => {
			const accessToken = (getState() as RootState).app.accessToken;
			if (accessToken) {
				headers.set('Authorization', `Bearer ${accessToken}`);
			}
			return headers;
		},
	}),
	tagTypes: [QUERY_TAGS.user],
	refetchOnMountOrArgChange: 300,
	endpoints: (builder) => ({
		dictionaries: builder.query<IDictionaries, void>({
			query: () => '../public/api/dicts',
		}),
		newIssue: builder.mutation<any, IIssue>({
			query: (body) => ({
				url: 'edit/issue',
				method: DEFAULTS.httpMethod.post,
				body,
			}),
		}),
		appVersion: builder.query<{ appVersion: string }, void>({
			query: () => '../public/api/app_version',
		}),
	}),
});

export const { useDictionariesQuery, useNewIssueMutation, useAppVersionQuery } = moriaApi;
