import classNames from 'classnames';
import React, { useEffect } from 'react';
import { ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import { showError, showPromiseConfirm } from '../../../alerts';
import Asterisk from '../../../components/Asterisk';
import { useDocumentChildData } from '../../../hooks';
import { IArrayDataOptions, IDocumentTocView } from '../../../interfaces/document';
import { getControlData, objectsAreEquals } from '../../../utils';
import EditButtonsGroup from '../../controls/EditButtonsGroup';

const DEFAULT_TOC: IDocumentTocView = {
	id: 0,
	title: '',
	start_page: 0,
};
const TITLE_COL_SIZE = 9;

interface IDocumentTocProps {
	initialData?: IDocumentTocView[];
	onChange: (data: IArrayDataOptions<IDocumentTocView>) => void;
	setInEditing: (newInEditing: boolean) => void;
}
const DocumentToc = ({ initialData, onChange, setInEditing }: IDocumentTocProps) => {
	const {
		newItemIndex,
		editingIndex,
		setEditingIndex,
		refSelectedInput,
		data: toc,
		setData: setToc,
		modified,
		onItemReset,
	} = useDocumentChildData(initialData, 'id');

	useEffect(() => {
		setInEditing(newItemIndex !== editingIndex);
	}, [newItemIndex, editingIndex, setInEditing]);

	useEffect(() => {
		onChange({ modified, selected: toc });
	}, [modified, toc]); // eslint-disable-line

	const onControlClick = async (event: React.MouseEvent<HTMLDivElement>) => {
		const action = getControlData(event.target, '.btn-action', 'action');
		const strIndex = getControlData(event.target, '.toc-edit_form', 'index') as string;
		if (!action || !strIndex) return;
		const index = parseInt(strIndex, 10);

		switch (action) {
			case 'edit':
				setEditingIndex(index);
				break;
			case 'delete':
				const oldTitle = toc[index].title;
				if (!(await showPromiseConfirm(`Ви впевнені, що бажаєте видалити розділ "${oldTitle}?"`))) return;
				setToc(toc.filter((item, itemIndex) => index !== itemIndex));
				break;
		}
	};

	const onItemSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		const form = event.target as HTMLFormElement;
		const formData = new FormData(form);

		const newTitle = formData.get('TITLE')?.toString().trim() ?? '';
		if (newTitle === '') return showError('Назва розділу не може бути порожньою');
		if (toc.some(({ title: TITLE }, index) => TITLE === newTitle && index !== editingIndex))
			return showError('Розділ з такою назвою вже існує');

		const oldValue = toc[editingIndex];
		const newValue: IDocumentTocView = {
			id: oldValue?.id ?? 0,
			title: newTitle,
			start_page: parseInt(formData.get('START_PAGE')?.toString() ?? '', 10),
		};
		if (oldValue && objectsAreEquals(oldValue, newValue)) return setEditingIndex(newItemIndex);
		// console.log(newValue);

		const newToc = [...toc];
		if (editingIndex !== newItemIndex) {
			newToc[editingIndex] = newValue;
			setEditingIndex(newItemIndex);
		} else {
			newToc.push(newValue);
		}
		newToc.sort((item1, item2) =>
			item1.start_page !== item2.start_page ? item1.start_page - item2.start_page : item1.title < item2.title ? -1 : 1
		);
		setToc(newToc);
	};

	return (
		<>
			{toc.length !== 0 && (
				<Row className="g-2">
					<Col xs={TITLE_COL_SIZE}>
						Назва розділу <Asterisk />
					</Col>
					<Col>
						№ першої сторінки <Asterisk />
					</Col>
				</Row>
			)}
			<div className="vstack gap-1" onClick={onControlClick}>
				{[...toc, { ...DEFAULT_TOC }].map((item, index) => (
					<React.Fragment key={`${item.title}_${index}`}>
						{index === newItemIndex && (
							<Form.Label className={classNames(toc.length !== 0 && 'mt-3')}>Новий розділ документа</Form.Label>
						)}
						<Form data-index={index} className="row g-2 toc-edit_form" onSubmit={onItemSubmit} onReset={onItemReset}>
							<Col xs={TITLE_COL_SIZE}>
								<Form.Control
									type="text"
									maxLength={100}
									required
									placeholder="Введіть назву розділу"
									defaultValue={item.title}
									autoComplete="off"
									disabled={editingIndex !== index}
									ref={editingIndex === index ? refSelectedInput : undefined}
									name="TITLE"
								/>
							</Col>
							<Col>
								<Form.Control
									type="number"
									min={1}
									required
									// placeholder="Введіть назву розділу"
									defaultValue={item.start_page}
									autoComplete="off"
									disabled={editingIndex !== index}
									name="START_PAGE"
								/>
							</Col>
							<Col xs="auto">
								<ButtonGroup>
									<EditButtonsGroup
										showEditingButtons={editingIndex === index}
										showEditButtons={index !== newItemIndex && index !== editingIndex}
										editButtonsDisabled={editingIndex !== newItemIndex}
									/>
								</ButtonGroup>
							</Col>
						</Form>
					</React.Fragment>
				))}
			</div>
		</>
	);
};

export default DocumentToc;
