import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { BsRecord, BsRecordFill } from 'react-icons/bs';
import { ESearchScope } from '../../common';

interface ISearchScopeProps extends React.AllHTMLAttributes<HTMLDivElement> {
	scope: ESearchScope;
	onScopeChange: (newScope: ESearchScope) => void;
}
const SearchScope = ({ scope, onScopeChange }: ISearchScopeProps) => {
	const [title, setTitle] = useState<string>('');

	useEffect(() => {
		const current = SEARCH_SCOPES.find((item) => item.scope === scope);
		setTitle(current ? current.title : SEARCH_SCOPES[0].title);
	}, [scope]);

	const onScopeClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
		const { scope: newScope } = (event.target as HTMLAnchorElement).dataset;
		if (scope !== newScope) onScopeChange(newScope as ESearchScope);
	};

	return (
		<DropdownButton variant="warning" title={title} id="searchScoreDD">
			{SEARCH_SCOPES.map((item) => (
				<Dropdown.Item key={item.scope} data-scope={item.scope} href="#" onClick={onScopeClick}>
					{item.scope === scope ? (
						<BsRecordFill className="text-primary" />
					) : (
						<BsRecord className="text-secondary" />
					)}{' '}
					{item.title}
				</Dropdown.Item>
			))}
		</DropdownButton>
	);
};

export default SearchScope;

interface ISearchScope {
	title: string;
	scope: ESearchScope;
}
const SEARCH_SCOPES: ISearchScope[] = [
	{ title: 'Будь-що', scope: ESearchScope.essCommon },
	{ title: 'Телефонний номер', scope: ESearchScope.essPhone },
	{ title: 'Номер паспорту', scope: ESearchScope.essPassport },
	{ title: 'Автомобільний номер', scope: ESearchScope.essAutoNumber },
];
