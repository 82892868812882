import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Asterisk from '../../components/Asterisk';
import { useArray } from '../../hooks';
import { IEntity, TEntityId } from '../../interfaces/common';
import { ITextArrayDataOptions } from '../../interfaces/document';

interface ICheckboxesGroupProps {
	options?: { [key: string]: string };
	entities?: IEntity<string, { title: string }>;
	initialOptions?: TEntityId<string>[];
	idTemplate: string;
	inline?: boolean;
	title: string;
	className?: string;
	onChange: (data: ITextArrayDataOptions) => void;
	required?: boolean;
}
const CheckboxesGroup = ({
	options = {},
	entities,
	initialOptions,
	onChange,
	className,
	title,
	idTemplate,
	inline,
	required = false,
}: ICheckboxesGroupProps) => {
	const { data, setData, toggleItem, modified } = useArray(initialOptions);

	useEffect(() => {
		setData(initialOptions);
	}, [initialOptions]); // eslint-disable-line

	useEffect(() => {
		onChange({ modified, selected: data });
	}, [modified, data]); // eslint-disable-line

	const onOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { id } = event.target.dataset;
		if (!id) return;
		toggleItem(id);
	};

	return (
		<fieldset className={className}>
			<legend>
				{title} {required && <Asterisk />}
			</legend>
			{(entities ? entities.ids : Object.keys(options)).map((id) => (
				<Form.Check
					key={id}
					id={idTemplate + '_' + id}
					type="checkbox"
					label={(entities ? entities.entities[id]?.title : options[id]) || '—'}
					checked={data.includes(id)}
					onChange={onOptionChange}
					data-id={id}
					inline={inline}
				/>
			))}
		</fieldset>
	);
};

export default CheckboxesGroup;
