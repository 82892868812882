import React from 'react';
import { useDictionariesQuery } from '../../features/services/moriaApi';
import { TStaticOptions } from '../../interfaces/dict';

export interface IWithStaticOptions {
	staticOptions: TStaticOptions;
}

const WithStaticOptions =
	<T extends IWithStaticOptions>(WrappedComponent: React.ComponentType<T>) =>
	(props: Omit<T, keyof IWithStaticOptions>) => {
		// eslint-disable-next-line
		const { staticOptions } = useDictionariesQuery(undefined, {
			refetchOnMountOrArgChange: false,
			selectFromResult: ({ data }) => ({ staticOptions: data?.staticOptions }),
		});

		return <WrappedComponent {...(props as T)} staticOptions={staticOptions || {}} />;
	};

export default WithStaticOptions;
