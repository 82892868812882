import React, { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { IToast } from '../../common';

interface IToastItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	toast: IToast;
	onRemoveToast: (id: number) => void;
}
const ToastItem = ({ toast, onRemoveToast }: IToastItemProps) => {
	const [show, setShow] = useState(false);

	const { id, title, text, variant, delay = 5000 } = toast;

	const onClose = () => {
		setShow(false);
		setTimeout(onRemoveToast, 300, id);
	};

	useEffect(() => {
		setTimeout(setShow, 0, true);
	}, []);

	return (
		<Toast bg={variant} className="shadow-sm" onClose={onClose} delay={delay} autohide show={show}>
			{title && (
				<Toast.Header>
					<span className="me-auto">{title}</span>
				</Toast.Header>
			)}
			<Toast.Body className={variant && ['light', 'info'].indexOf(variant) === -1 ? 'text-white' : undefined}>
				{text}
			</Toast.Body>
		</Toast>
	);
};

export default ToastItem;
