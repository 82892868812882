import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { BsEye, BsPlusCircle, BsSortAlphaDown, BsSortDown, BsSortNumericDownAlt } from 'react-icons/bs';
import { connect, ConnectedProps } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { DEFAULTS } from '../../../common';
import { FixedDotSpinner } from '../../../components/DotSpinner';
import FetchError from '../../../components/FetchError';
import PageTitle from '../../../components/PageTitle';
import SearchPagination from '../../../components/Pagination';
import TextIcon from '../../../components/textIcon';
import { useDebounce } from '../../../hooks';
import { ISourceBase } from '../../../interfaces/sources';
import { moveControlToScreenCenter } from '../../../scrolls';
import { RootState } from '../../../store';
import { getControlData } from '../../../utils';
import { useSourceListQuery } from '../../services/sources';
import { changeSourceOrder, changeSourcePageNo, changeSourceTerm } from '../manageSlice';
import SourceDialog from './SourceDialog';
import SourceItem from './SourceItem';

interface ISourcesManageLayoutProps extends PropsFromRedux {}
const SourcesManageLayout = ({
	term,
	order,
	pageNo,
	changeSourcePageNo,
	changeSourceOrder,
	changeSourceTerm,
}: ISourcesManageLayoutProps) => {
	const [selectedId, setSelectedId] = useState<number | null>(null);
	const { term: visibleTerm, onTermChange, debouncedValue: debouncedTerm } = useDebounce(term);
	const { data, error, isFetching } = useSourceListQuery(
		{ term, order, pageNo },
		{
			refetchOnMountOrArgChange: true,
		}
	);

	useEffect(() => {
		changeSourceTerm(debouncedTerm);
	}, [debouncedTerm, changeSourceTerm]);

	const onEditButtonClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
		const button = (event.target as HTMLElement).closest('.btn-edit') as HTMLButtonElement;
		if (!button) return;
		const { sourceId } = button.dataset;
		if (sourceId === undefined) return;
		event.stopPropagation();
		setSelectedId(parseInt(sourceId));
	}, []);

	const onCloseDialog = useCallback((sourceId?: number) => {
		setSelectedId(null);
		if (!sourceId) return;
		setTimeout(() => {
			moveControlToScreenCenter(document.getElementById(`source_${sourceId}`), true);
		}, 100);
	}, []);

	const onSortClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		const order = getControlData(event.target, '.btn', 'order') as keyof ISourceBase;
		if (!order) return;
		changeSourceOrder(order);
	};

	const filtered = debouncedTerm.includes('.');

	return (
		<>
			<PageTitle title="Джерела" />
			<Row>
				<Col className="hstack gap-1 align-items-center">
					<h3 className="m-0">
						Кількість знайдених джерел —<span className="ms-1 fw-bold text-info">{data?.total}</span>
					</h3>
					<Button className="ms-auto btn-edit" data-source-id={0} onClick={onEditButtonClick}>
						<TextIcon Icon={BsPlusCircle} size="lg">
							Додати
						</TextIcon>
					</Button>
				</Col>
			</Row>
			<Form.Group as={Row} className="mt-3">
				<Form.Label column sm={3} lg={2}>
					URL джерела для фільтру
				</Form.Label>
				<Col sm={9} lg={10}>
					<Form.Control
						type="text"
						placeholder="фільтрація проводиться по назві та URL джерела"
						value={visibleTerm}
						onChange={onTermChange}
					/>
				</Col>
			</Form.Group>
			<Row className="mt-1">
				{error && (
					<Col xs={12}>
						<FetchError error={error} />
					</Col>
				)}
				<Col className="position-relative">
					<Table striped onClick={onEditButtonClick} responsive>
						<thead>
							<tr>
								<th>
									ID
									{!filtered && (
										<Button
											variant="link"
											data-order="id"
											className={classNames('ms-2 p-0', order === 'id' ? 'link-primary' : 'link-secondary')}
											onClick={onSortClick}
										>
											<BsSortNumericDownAlt className="icon-lg mt-n1" />
										</Button>
									)}
								</th>
								<th title="Чи може бути знайдене користувачем та приєднане до документу">
									<BsEye className="mt-n1" />
								</th>
								<th>
									Назва
									{!filtered && (
										<Button
											variant="link"
											data-order="title"
											className={classNames('ms-2 p-0', order === 'title' ? 'link-primary' : 'link-secondary')}
											onClick={onSortClick}
										>
											<BsSortAlphaDown className="icon-lg mt-n1" />
										</Button>
									)}
								</th>
								<th>Домен{filtered && <BsSortDown className="icon-lg ms-2 text-primary" />}</th>
								<th>Країна</th>
								<th>Якість</th>
								<th>Оновлено</th>
								<th>Дії</th>
							</tr>
						</thead>
						{data && (
							<tbody>
								{data.sources.map((source) => (
									<SourceItem key={source.id} source={source} />
								))}
								<tr className="show-only-child">
									<td colSpan={7}>&empty; Відсутні записи, що задовольняють фільтру</td>
								</tr>
							</tbody>
						)}
					</Table>
					{isFetching && <FixedDotSpinner>Завантаження списку джерел</FixedDotSpinner>}
				</Col>
			</Row>
			{data && (
				<Row>
					<Col>
						<SearchPagination
							pageCount={Math.ceil(data.total / DEFAULTS.sourcesPerPage)}
							activePageNo={data.pageNo}
							onPageClick={changeSourcePageNo}
						/>
					</Col>
				</Row>
			)}
			{selectedId !== null && (
				<SourceDialog source={data?.sources.find(({ id }) => id === selectedId)} onClose={onCloseDialog} />
			)}
			<Outlet />
		</>
	);
};

const mapState = (state: RootState) => ({
	term: state.manage.sourceTerm,
	order: state.manage.sourceOrder,
	pageNo: state.manage.sourcePageNo,
});

const mapDispatch = { changeSourcePageNo, changeSourceOrder, changeSourceTerm };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SourcesManageLayout);
