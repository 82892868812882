import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { THighlighting } from '../../common';
import HeaderWithImage from '../../components/HeaderWithImage';
import WithRealms, { IWithRealms } from '../../components/hoc/withRealms';
import BloomingImage from '../../img/undraw_blooming_re_2kc4.svg';
import { ISolrDocument } from '../../interfaces/document';
import DocumentItem from './DocumentItem';

interface IDocumentListProps extends IWithRealms, React.AllHTMLAttributes<HTMLDivElement> {
	documents: ISolrDocument[];
	firstNo: number;
	highlighting?: THighlighting;
	lastPage: boolean;
	maxScore: number;
}
const DocumentList = ({
	documents,
	firstNo,
	className,
	highlighting,
	lastPage,
	maxScore,
	realmsById,
}: IDocumentListProps) => {
	return (
		<Row>
			<Col className={classNames(className, 'd-flex flex-column gap-4')}>
				{documents.map((doc, index) => (
					<DocumentItem
						key={doc.id}
						document={doc}
						no={firstNo + index}
						// hl={highlighting[doc.id].body}
						hl={highlighting && highlighting[doc.id] ? highlighting[doc.id].body : undefined}
						maxScore={maxScore}
						realms={realmsById}
					/>
				))}
				{lastPage && <HeaderWithImage imgSrc={BloomingImage} alt="Остання сторінка" className="my-2" small />}
			</Col>
		</Row>
	);
};

export default WithRealms(DocumentList);
