import React, { useEffect, useMemo, useState } from 'react';
import Select, { ActionMeta, OptionProps, SingleValue, SingleValueProps, components } from 'react-select';
import { DEFAULTS } from '../../common';
import Asterisk from '../../components/Asterisk';
import NatoCountryFlag from '../../components/NatoCountryFlag';
import WithCountries, { IWithCountries } from '../../components/hoc/withCountries';
import { IDictionary, ISelectOption } from '../../interfaces/common';
import { ICountryDic } from '../../interfaces/dict';

const DEFAULT_COUNTRIES = ['UKR', 'RUS', 'BLR'];

interface ICountrySelectorProps extends IWithCountries {
	onCountryChange?: (trigram: string) => void;
	trigram: string;
	showLabel?: boolean;
	label?: string | JSX.Element;
	className?: string;
	containerClassName?: string;
	required?: boolean;
}
const CountrySelector = ({
	countriesAllIds,
	countriesById,
	trigram,
	onCountryChange,
	showLabel = true,
	className,
	containerClassName,
	label = 'Країна',
	required = false,
}: ICountrySelectorProps) => {
	const [countries, setCountries] = useState<ISelectOption[]>(() =>
		// countriesAllIds.map((trigram) => ({ label: countriesById[trigram]?.title || '', value: trigram }))
		filterCountries(countriesAllIds, countriesById, [...DEFAULT_COUNTRIES, trigram], '')
	);

	const countryValue = useMemo(
		() => ({ label: countriesById[trigram]?.title || '', value: trigram }),
		[trigram, countriesById]
	);

	useEffect(() => {
		// setCountries(countriesAllIds.map((trigram) => ({ label: countriesById[trigram]?.title || '', value: trigram })));
		setCountries(filterCountries(countriesAllIds, countriesById, [...DEFAULT_COUNTRIES, trigram], ''));
	}, [countriesAllIds, countriesById, trigram]);

	const onSingleChange = (value: SingleValue<ISelectOption>, actionMeta: ActionMeta<ISelectOption>) => {
		if (onCountryChange && value) onCountryChange(value.value);
	};

	// const onMultiChange = (value: MultiValue<ISelectOption>, actionMeta: ActionMeta<ISelectOption>) => {
	// 	console.log(value);
	// };

	const onInputChange = (term: string) => {
		if (term.length === 1) return;
		setCountries(filterCountries(countriesAllIds, countriesById, [...DEFAULT_COUNTRIES, trigram], term));
	};

	return (
		<div title="Країна" className={containerClassName}>
			{showLabel && (
				<label className="form-label">
					{label}
					{required && <Asterisk />}
				</label>
			)}
			<Select
				// className="basic-single"
				classNamePrefix="themed-select"
				// classNames={{
				// 	control:
				// }}
				// inputId="search_country"
				// isMulti
				isSearchable
				options={countries}
				value={countryValue}
				onChange={onSingleChange}
				onInputChange={onInputChange}
				placeholder="країна..."
				name="country"
				components={{ SingleValue: SingleElement, Option: CountryOption }}
				// styles={countryStyles}
				noOptionsMessage={() => DEFAULTS.noMatch}
				// menuIsOpen
				className={className}
			/>
		</div>
	);
};

export default WithCountries(CountrySelector);

// const countryStyles: Partial<StylesConfig<ISelectOption, false, GroupBase<ISelectOption>>> = {
// 	control: (baseStyles) => ({
// 		...baseStyles,
// 		backgroundColor: 'var(--bs-body-bg)',
// 	}),
// 	singleValue: (baseStyles) => ({
// 		...baseStyles,
// 		color: 'var(--bs-body-color)',
// 	}),
// menuList: (baseStyles) => ({
// 	...baseStyles,
// 	zIndex: 2,
// 	// backgroundColor: 'var(--bs-body-bg)',
// }),
// };

const SingleElement = ({ children, data, ...props }: SingleValueProps<ISelectOption>) => (
	<components.SingleValue data={data} {...props} className="d-flex align-items-center">
		<CountrySingleValue trigram={data.value}>{children}</CountrySingleValue>
	</components.SingleValue>
);

const CountryOption = ({ children, data, ...props }: OptionProps<ISelectOption, false>) => (
	<components.Option data={data} {...props} className="d-flex align-items-center">
		<CountrySingleValue trigram={data.value}>{children}</CountrySingleValue>
	</components.Option>
);

interface CountrySingleValueProps extends React.BaseHTMLAttributes<HTMLDivElement> {
	trigram: string;
}
const CountrySingleValue = ({ trigram, children }: CountrySingleValueProps) => (
	<>
		<NatoCountryFlag trigram={trigram} className="me-2" />
		{children}
	</>
);

const filterCountries = (
	countriesAllIds: string[],
	countriesById: IDictionary<string, ICountryDic>,
	initialCountries: string[],
	term: string
) => {
	const lowerTerm = term.toLowerCase();
	const resultIds = new Set(
		lowerTerm
			? countriesAllIds.filter((trigram) => countriesById[trigram]?.title.toLowerCase().includes(lowerTerm))
			: initialCountries
	);
	return [...resultIds].map((trigram) => ({ label: countriesById[trigram]?.title || '', value: trigram }));
};
