import React from 'react';
import { BsArrowLeft, BsArrowRight, BsTags } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import { ESearchScope } from '../../common';
import QuestionButton from '../../components/QuestionButton';
import { RootState } from '../../store';
import { changeSeverity } from '../search/searchSlice';

interface ISeverityProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const Severity = ({ changeSeverity, operativeSearchParams: { severity, scope }, className }: ISeverityProps) => {
	const onSeverityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		event.stopPropagation();
		changeSeverity(event.target.value);
	};

	const disabled = scope !== ESearchScope.essCommon;
	return (
		<div className={className}>
			<label htmlFor="search_severity" className="form-label mb-0 d-flex gap-1 justify-content-between">
				<span className="fw-bolder">
					<BsTags className="me-1" />
					Строгість
				</span>
				<QuestionButton title="Пошук з&nbsp;низькою строгістю дає&nbsp;більше результатів, але&nbsp;вони&nbsp;є менш релевантними. Тому використовуйте&nbsp;його у&nbsp;випадках, коли пошук з&nbsp;високою строгістю не&nbsp;дає результатів." />
			</label>
			<input
				id="search_severity"
				type="range"
				name="severity"
				className="form-range mb-0"
				min="0"
				max="100"
				step="25"
				autoComplete="off"
				value={disabled ? 100 : severity}
				onChange={onSeverityChange}
				disabled={disabled}
			/>
			<div className="d-flex justify-content-between small-text text-muted mt-n3">
				<span>
					<BsArrowLeft className="me-1" />
					Нижча
				</span>
				<span className={disabled ? 'text-danger' : undefined}>
					Поточна: {disabled ? 100 : severity} %
					{disabled && (
						<QuestionButton
							title="Відповідно до&nbsp;обраного Вами об'єкта пошуку застосовано максимальну строгість."
							className="ms-1 mt-n1"
						/>
					)}
				</span>
				<span>
					Вища
					<BsArrowRight className="ms-1" />
				</span>
			</div>
		</div>
	);
};

const mapState = (state: RootState) => ({
	operativeSearchParams: state.search.operativeSearchParams,
});

const mapDispatch = { changeSeverity };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Severity);
