import { IUpdateResponse } from '../../common';
import { IEntityEditMethod, QUERY_TAGS } from '../../interfaces/common';
import { IActiveUserData } from '../../interfaces/dict';
import { IUser, IUsers, TMutatingUser } from '../../interfaces/users';
import { moriaApi } from './moriaApi';

const usersApi = moriaApi.injectEndpoints({
	endpoints: (builder) => ({
		activeUserData: builder.query<IActiveUserData, string>({
			query: (email) => `activeUser/${encodeURIComponent(email)}`,
		}),
		userList: builder.query<IUsers, void>({
			query: () => 'users',
			providesTags: [{ type: QUERY_TAGS.user, id: 'list' }],
		}),
		user: builder.mutation<IUpdateResponse, TMutatingUser & IEntityEditMethod>({
			query: ({ method, ...rest }) => ({
				url: 'edit/user',
				method,
				body: rest,
			}),
			invalidatesTags: [{ type: QUERY_TAGS.user, id: 'list' }],
		}),
	}),
});

export const { useActiveUserDataQuery, useUserListQuery, useUserMutation } = usersApi;

export const getUserLabel = (user?: IUser, full = true) =>
	user ? (full ? `${user?.email} ⛏️ ${user?.code ?? 'null'}` : user.code ?? user.email) : 'null';
