import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../store';
import { removeToast } from '../app/appSlice';
import ToastItem from './ToastItem';

interface IToastListProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const ToastList = ({ removeToast, toasts }: IToastListProps) => {
	const onRemoveToast = (id: number) => {
		removeToast(id);
	};

	return (
		<ToastListContainer className="position-fixed d-flex flex-column gap-2 pb-2">
			{toasts.map((toast) => (
				<ToastItem key={toast.id} toast={toast} onRemoveToast={onRemoveToast} />
			))}
		</ToastListContainer>
	);
};

const mapState = (state: RootState) => ({
	toasts: state.app.toasts,
});

const mapDispatch = {
	removeToast,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ToastList);

const ToastListContainer = styled.div`
	right: 1rem;
	bottom: 80px;
	max-height: 50vh;
	overflow: auto;
`;
