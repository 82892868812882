import { useAuth0 } from '@auth0/auth0-react';
import React, { Suspense } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import ToastList from '../features/toasts/ToastList';
import { selectAppStore, useAppSelector } from '../store';
import FixedActionPanel from './FixedActionPanel';
import NavFooter from './NavFooter';
import NavHeader from './NavHeader';
import PageTitle from './PageTitle';
import Preloader from './Preloader';

const LazyEmptyWorkPlace = React.lazy(() => import('./EmptyWorkPlace'));

// interface ILayoutProps extends React.AllHTMLAttributes<HTMLDivElement> {}
// const Layout = ({}: ILayoutProps) => {
const Layout = () => {
	const { isAuthenticated, isLoading } = useAuth0();
	const { hasAccess, isAdmin, colorTheme } = useAppSelector(selectAppStore);

	return (
		<>
			<PageTitle />
			<NavHeader hasAccess={hasAccess} isAdmin={isAdmin} isAuthenticated={isAuthenticated} isLoading={isLoading} />
			{hasAccess ? (
				<Outlet />
			) : (
				<Suspense fallback={<Preloader />}>
					<LazyEmptyWorkPlace />
				</Suspense>
			)}
			<NavFooter hasAccess={hasAccess} colorTheme={colorTheme} />
			<ToastList />
			<ScrollRestoration />
			<FixedActionPanel hasAccess={hasAccess} />
		</>
	);
};

export default Layout;
