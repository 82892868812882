import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { BsBoxArrowUpRight, BsPlusCircle, BsSave, BsXLg } from 'react-icons/bs';
import { showError, showPromiseConfirm } from '../../../alerts';
import { EXIT_CONFIRMATION } from '../../../common';
import Asterisk from '../../../components/Asterisk';
import WithStaticOptions, { IWithStaticOptions } from '../../../components/hoc/withStaticOptions';
import TextIcon from '../../../components/textIcon';
import { useDocumentBaseSource, useFormFields } from '../../../hooks';
import { TPublicationReliabilityOptions, TSourceReliabilityOptions } from '../../../interfaces/dict';
import { IDocumentSourceView, TDocumentSource } from '../../../interfaces/document';
import { moveControlToScreenPosition } from '../../../scrolls';
import DateWithNull from '../../controls/DateWithNull';
import SourceDialog from '../../manage/sources/SourceDialog';

interface ILinkEditProps extends IWithStaticOptions, React.AllHTMLAttributes<HTMLDivElement> {
	link: IDocumentSourceView;
	baseSource?: TDocumentSource;
	onClose: (newLink?: IDocumentSourceView) => void;
	onSourceChange: (source: TDocumentSource) => void;
}
const LinkEdit = ({
	link,
	baseSource,
	onClose,
	onSourceChange,
	staticOptions: { SOURCE_RELIABILITY, PUBLICATION_RELIABILITY },
}: ILinkEditProps) => {
	const { formFields, createChangeHandler, modified, setFormFields } = useFormFields(link);
	const [domain, source, sourceFetchedSuccess, sourceFetching, updateBaseSource] = useDocumentBaseSource(
		formFields.pub_url,
		baseSource
	);
	const [baseSourceInserting, setBaseSourceInserting] = useState(false);

	useEffect(() => {
		if ((source?.id ?? null) === formFields.source_id) return;
		setFormFields({
			...formFields,
			source_id: source?.id ?? null,
			/**
			 * Якщо якість базового джерела 'F', то якість джерела в лінкі ставим буде таким же.
			 * Інакше, якщо якість джерела в лінкі 'F', то ставим його таким же, як в джерелі.
			 * Інакше лишаємо якість джерела в лінкі без змін.
			 */
			source_reliability: source
				? source.reliability === 'F'
					? 'F'
					: formFields.source_reliability === 'F'
					? source.reliability
					: formFields.source_reliability
				: formFields.source_reliability,
		});
	}, [source, formFields, setFormFields]);

	useEffect(() => {
		if (source?.id === baseSource?.id) return;
		if (source) onSourceChange(source);
	}, [source, baseSource?.id, onSourceChange]);

	useEffect(() => {
		moveControlToScreenPosition(document.getElementById('fmDocumentSource'), 'end');
	}, []);

	const onFormReset = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		if (modified && !(await showPromiseConfirm(EXIT_CONFIRMATION))) return;
		onClose();
	};

	const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		if (!formFields.source_id) return showError('Ви маєте внести інформацію про нове джерело!');
		// * Наразі користувач може зберігати посилання з неоціненною достовірністю інформації.
		// * Коли користувач спробує змінити статус документа, він буде вимушений оцінити її.
		// if (formFields.pub_reliability === PUB_RELIABILITY_DEFAULT)
		// 	return showError('Ви маєте оцінити достовірність інформації!');
		onClose(formFields);
	};

	const onAddSource = () => setBaseSourceInserting(true);

	const onSourceAddingClose = () => {
		setBaseSourceInserting(false);
		updateBaseSource();
	};

	const sourceNotRated = source?.reliability === 'F';
	return (
		<>
			<Form onSubmit={onFormSubmit} onReset={onFormReset} className="py-2" id="fmDocumentSource">
				<Row>
					<Form.Group as={Col} xs={12} controlId="dsURL">
						<Form.Label className="hstack justify-content-between align-items-center">
							<span>
								URL <Asterisk />
							</span>
							{formFields.pub_url && (
								<a href={formFields.pub_url} target="_blank" rel="noreferrer">
									<BsBoxArrowUpRight className="mt-n1" />
								</a>
							)}
						</Form.Label>
						<Form.Control
							type="url"
							required
							placeholder="Введіть URL"
							value={formFields.pub_url}
							onChange={createChangeHandler('pub_url')}
							autoComplete="off"
							autoFocus
						/>
					</Form.Group>
				</Row>
				<Row className="mt-0 g-3">
					<Form.Group as={Col} xs={12} md={6} controlId="dsTitle">
						<Form.Label>Назва статті</Form.Label>
						<Form.Control
							type="text"
							placeholder="Введіть назву"
							value={formFields.pub_title || ''}
							onChange={createChangeHandler('pub_title')}
							autoComplete="off"
						/>
					</Form.Group>
					<Form.Group as={Col} xs controlId="dsAuthor">
						<Form.Label>Автор</Form.Label>
						<Form.Control
							type="text"
							placeholder="Введіть автора"
							value={formFields.pub_author || ''}
							onChange={createChangeHandler('pub_author')}
							autoComplete="off"
						/>
					</Form.Group>
					<Form.Group as={Col} xs="auto" controlId="dsDate">
						<Form.Label>Дата</Form.Label>
						<DateWithNull value={formFields.pub_date || ''} onChange={createChangeHandler('pub_date')} />
					</Form.Group>
				</Row>
				<Row className="mt-0 g-3">
					<Form.Group as={Col} xs={12} lg={6} controlId="dsSourceTitle">
						<Form.Label>
							Джерело <Asterisk />
						</Form.Label>
						<InputGroup>
							<Form.Control
								type="text"
								value={source?.title ?? '⚠️ Ви мусите додати джерело.'}
								readOnly
								// plaintext
								// className="fw-bold"
								className={classNames('bg-body-secondary', !source && 'text-danger')}
							/>
							{sourceFetchedSuccess && !sourceFetching && domain && !source && (
								<Button variant="danger" title="Додати джерело" onClick={onAddSource}>
									<BsPlusCircle />
								</Button>
							)}
						</InputGroup>
					</Form.Group>
					<Form.Group as={Col} controlId="dsSR">
						<Form.Label>
							Якість джерела <Asterisk />
						</Form.Label>
						<Form.Select
							value={formFields.source_reliability}
							onChange={createChangeHandler('source_reliability')}
							// disabled={sourceNotRated}
							disabled
						>
							{SOURCE_RELIABILITY &&
								Object.keys(SOURCE_RELIABILITY).map((key) => (
									<option key={key} value={key}>
										{key} — {SOURCE_RELIABILITY[key as TSourceReliabilityOptions]}
									</option>
								))}
						</Form.Select>
					</Form.Group>
					<Form.Group as={Col} controlId="dsPR">
						<Form.Label>
							Достовірність інформації <Asterisk />
						</Form.Label>
						<Form.Select value={formFields.pub_reliability} onChange={createChangeHandler('pub_reliability')}>
							{PUBLICATION_RELIABILITY &&
								Object.keys(PUBLICATION_RELIABILITY).map((key) => (
									<option key={key} value={key}>
										{key} — {PUBLICATION_RELIABILITY[key as TPublicationReliabilityOptions]}
									</option>
								))}
						</Form.Select>
					</Form.Group>
				</Row>
				{sourceNotRated && (
					<Row>
						<Col className="text-secondary mt-1">
							Примітка: поле "Якість джерела" оновиться автоматично після того, як буде виконана оцінка його якості.
						</Col>
					</Row>
				)}
				<Row className="mt-3">
					<Form.Group controlId="dsComment">
						<Form.Label>Коментар</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							placeholder="За необхідності"
							value={formFields.comment || ''}
							onChange={createChangeHandler('comment')}
							autoComplete="off"
							className="no-resize"
							maxLength={4000}
						/>
					</Form.Group>
				</Row>
				<div className="hstack gap-1 justify-content-center mt-2">
					{/* <Button disabled={!modified || updateSourceResult.isLoading} type="submit" form="fmSource"> */}
					<Button variant="primary" disabled={!modified} type="submit">
						<TextIcon Icon={BsSave} className={false ? 'animation-bounce' : undefined}>
							Зберегти
						</TextIcon>
					</Button>
					<Button variant="secondary" type="reset">
						<TextIcon Icon={BsXLg}>Закрити</TextIcon>
					</Button>
				</div>
			</Form>
			{baseSourceInserting && domain && <SourceDialog onClose={onSourceAddingClose} newDomain={domain} />}
		</>
	);
};

export default WithStaticOptions(LinkEdit);
