import classNames from 'classnames';
import React from 'react';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import { ILogItem } from '../../common';

interface ILogItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	item: ILogItem;
}
const LogItem = ({ item: { success, hitDate, message, fileName } }: ILogItemProps) => {
	return (
		<div className={classNames('d-flex', !success && 'text-warning')}>
			<span className="me-1">
				{success ? <BsCheckCircle className="text-success" /> : <BsXCircle className="text-danger" />}
			</span>
			<span className="me-1">{hitDate.toLocaleTimeString()}:</span>
			<span>
				<strong>{fileName}</strong> {message}
			</span>
		</div>
	);
};

export default LogItem;
