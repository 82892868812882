// import { promiseTimeOut } from './utils';

import { promiseTimeout } from './common';

/**
 * `scrollWindowToTop` is a function that scrolls the window to the top of the page
 * @param {'auto' | 'smooth'} [behavior=auto] - 'auto' | 'smooth' = 'auto'
 */
export const scrollWindowToTop = (behavior: 'auto' | 'smooth' = 'smooth') =>
	window.scrollTo({
		top: 0,
		behavior,
	});

export const scrollWindowToBottom = (behavior: 'auto' | 'smooth' = 'smooth') =>
	window.scrollTo({
		top: document.body.scrollHeight,
		behavior,
	});

// export const deferredScroll = (behavior: 'auto' | 'smooth' = 'smooth', timeout = 100) =>
// 	setTimeout(() => {
// 		scrollWindowToTop(behavior);
// 	}, timeout);

export const scrollContainerToBottom = (container: Element | null, behavior: 'auto' | 'smooth' = 'auto') =>
	container
		? container.scrollTo({
				top: 10000,
				behavior,
		  })
		: undefined;

// export const scrollWindowToBottom = (behavior: 'auto' | 'smooth' = 'auto') =>
// 	window.scrollTo({
// 		top: 10000,
// 		behavior,
// 	});

export const moveControlToScreenPosition = (
	control?: Element | null,
	block: ScrollLogicalPosition = 'center',
	highlight: boolean = false
) => {
	if (!control) return;
	control.scrollIntoView({ behavior: 'smooth', block });
	if (!highlight) return;
	highlightControl(control);
};

export const moveControlToScreenCenter = (control?: Element | null, highlight: boolean = false) =>
	moveControlToScreenPosition(control, 'center', highlight);

export const highlightControl = async (control?: Element | null, timeout?: number) => {
	if (!control) return;
	if (timeout) await promiseTimeout(timeout);
	control.classList.add('animation-highlighted');
	await promiseTimeout(500);
	control.classList.remove('animation-highlighted');
};

/**
 * Производит скроллинг элемента в центр видимости первого скроллируемого компонента
 * Необходимость этой функции обусловлена тем, что стандартный scrollIntoView работает
 * не корректно для скроллируемых компонентов с ограниченной высотой (при ее вызове
 * производится скроллинг всего окна, что не верно).
 * @param control Элемент, который необходимо показать
 * @param scrollDelta Сдвиг скроллинга
 * @param timeout Таймаут
 */
export const scrollControlIntoContainer = async (
	control?: HTMLElement | null,
	scrollDelta = 0,
	timeout?: number,
	block: ScrollLogicalPosition = 'center'
) => {
	const getNearestScrollableContainer = (control: HTMLElement) => {
		let c: HTMLElement | null = control.parentElement;
		while (c) {
			if (c.clientHeight !== c.scrollHeight) return c;
			c = c.parentElement;
		}
		return null;
	};

	if (!control) return;
	if (timeout !== undefined) await promiseTimeout(timeout);
	const container = getNearestScrollableContainer(control);
	if (!container || container.tagName === 'HTML') return;

	let delta = 0;
	switch (block) {
		case 'start':
			delta = scrollDelta;
			break;

		default:
			delta = -(window.innerHeight - control.clientHeight) / 2 + scrollDelta;
			break;
	}

	container.scrollTo({
		top: control.offsetTop + delta,
		behavior: 'smooth',
	});
};

export const scrollControlIntoContainerCenter = async (
	control?: HTMLElement | null,
	scrollDelta = 0,
	timeout?: number
) => {
	scrollControlIntoContainer(control, scrollDelta, timeout, 'center');
};
