import {
	ESearchScope,
	getAutoNumberFromString,
	getDigits,
	getPassportNumberFromString,
	ISearchParams,
	TStringWithUndefined,
} from './common';

export const getTranslatedTerm = ({ term, scope }: ISearchParams): string => {
	let translatedTerm: string;
	switch (scope) {
		case ESearchScope.essPhone:
			translatedTerm = translatePhoneTerm(term);
			break;
		case ESearchScope.essAutoNumber:
			translatedTerm = translateAutoTerm(term);
			break;
		case ESearchScope.essPassport:
			translatedTerm = translatePassportTerm(term);
			break;
		default:
			translatedTerm = translateCommonTerm(term);
			break;
	}
	return translatedTerm;
};

const translateCommonTerm = (term: string = '') =>
	term
		.trim()
		.replace(/:/g, '\\:')
		.replace(/("[^"]+)\s+(and|і|и|та|or|или|або)\s+([^"]+")/gi, '$1 __$2__ $3')
		.replace(/\s+(?:and|і|и|та)\s+/gi, ' && ')
		.replace(/\s+(?:or|или|або)\s+/gi, ' || ')
		.replace(/__(and|і|и|та|or|или|або)__/gi, '$1');

export const getPhonePattern = (num: string): string[] => {
	const FIRST_DIGITS_COUNT: { [key: number]: number } = { 8: 1, 9: 2, 10: 3, 11: 1, 12: 1 };
	if (num.length === 7)
		return [/^(.{7})$/, /^(.{3})(.{4})$/, /^(.{3})(.{2})(.{2})$/].map((re) =>
			(num.match(re) || []).slice(1).join(' ')
		);

	const re = new RegExp(`^(.{${FIRST_DIGITS_COUNT[num.length]}})(.+)$`);
	const [firstDigits, rest] = (num.match(re) || []).slice(1);
	const patternsPrev = getPhonePattern(rest);
	const result: string[] = patternsPrev
		.map((p) => `${firstDigits}${p}`)
		.concat(patternsPrev.map((p) => `${firstDigits} ${p}`));

	return result;
};

const translatePhoneTerm = (term: string) => {
	const num = getDigits(term);
	if (num.length < 7) throw new Error('Надто мало цифр для пошуку за № телефону.');
	if (12 < num.length) throw new Error('Надто багато цифр в № телефону.');

	const phonePatterns = getPhonePattern(num);
	const solrPattern = phonePatterns
		.map((pattern) => (pattern.includes(' ') ? `"${pattern}"` : `*${pattern}`))
		.join(' || ');
	// console.log(solrPattern);

	return solrPattern;
};

export const getAutoNumberPatterns = (
	firstLetters: TStringWithUndefined,
	num: string,
	lastLetters: TStringWithUndefined
) => {
	if (firstLetters && lastLetters)
		return [
			`${firstLetters}${num}${lastLetters}`,
			`"${firstLetters}${num} ${lastLetters}"`,
			`"${firstLetters} ${num}${lastLetters}"`,
			`"${firstLetters} ${num} ${lastLetters}"`,
		];
	if (firstLetters) return [`${firstLetters}${num}*`, `"${firstLetters} ${num}"`];
	if (lastLetters) return [`*${num}${lastLetters}`, `"${num} ${lastLetters}"`];

	return [`*${num}*`];
};

const translateAutoTerm = (term: string) => {
	const [firstLetters, num, lastLetters] = getAutoNumberFromString(term);

	if (!num)
		throw new Error(
			'Введена строка пошуку не схожа но № автомобіля. Введіть строку виду «АН1234ХР», або «12345ХР»'
		);

	const patterns = getAutoNumberPatterns(firstLetters, num, lastLetters);
	return patterns?.join(' || ');
};

export const getPasswordPatterns = (series: string, num: string) => [`${series}${num}`, `"${series} ${num}"~5`];

const translatePassportTerm = (term: string) => {
	const [series, num] = getPassportNumberFromString(term);
	if (!num) throw new Error('Введена строка пошуку не схожа но № паспорту. Введіть строку виду «АН 123456»');
	const pattern: string[] = getPasswordPatterns(series, num);
	return pattern.join(' || ');
};
