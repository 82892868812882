import classNames from 'classnames';
import React from 'react';
import { BsDatabaseExclamation, BsDatabaseFillCheck } from 'react-icons/bs';

interface IVerifiedMarkProps extends React.AllHTMLAttributes<HTMLDivElement> {
	draft?: boolean;
	withColors?: boolean;
	showTitle?: boolean;
}
const VerifiedMark = ({ draft, title, className, withColors = true, showTitle = false }: IVerifiedMarkProps) => {
	if (draft === undefined) return null;

	return (
		<>
			{showTitle && title}
			{draft ? (
				<BsDatabaseExclamation className={classNames(withColors && 'text-secondary', className)} title={title} />
			) : (
				<BsDatabaseFillCheck className={classNames(withColors && 'text-success', className)} title={title} />
			)}
		</>
	);
};

export default VerifiedMark;
