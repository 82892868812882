import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import React, { Suspense, useEffect, useRef } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import { DEFAULTS } from '../../common';
import ErrorHandler from '../../components/ErrorHandler';
import Layout from '../../components/Layout';
import Preloader from '../../components/Preloader';
import SplashScreen from '../../components/SplashScreen';
import WorkPlace from '../../components/WorkPlace';
import HtmlDocument from '../documents/HtmlDocument';
import DocumentsListForSource from '../manage/sources/DocumentsListForSource';
import SourcesManageLayout from '../manage/sources/SourcesManageLayout';
import UsersManageLayout from '../manage/users/UsersManageLayout';
import AccessTokenUpdater from './AccessTokenUpdater';
import AppVersionChecker from './AppVersionChecker';
import { changePermissions } from './appSlice';

const LazyPageNotFound = React.lazy(() => import('../../components/PageNotFound'));
const LazyManager = React.lazy(() => import('../manage/Manager'));
const LazyDocumentDataDialog = React.lazy(() => import('../documents/data/DocumentDataDialog'));
const LazyFacerRecognizeLayout = React.lazy(() => import('../facer/FacerRecognizeLayout'));
const LazyFacerVerifyLayout = React.lazy(() => import('../facer/FacerVerifyLayout'));

const router = createHashRouter([
	{
		path: '/',
		element: <Layout />,
		errorElement: <ErrorHandler />,
		children: [
			{
				path: `${DEFAULTS.routes.manage}`,
				element: (
					<Suspense fallback={<Preloader fixed />}>
						<LazyManager />
					</Suspense>
				),
				children: [
					{
						path: DEFAULTS.routes.users,
						element: <UsersManageLayout />,
					},
					{
						path: DEFAULTS.routes.sources,
						element: <SourcesManageLayout />,
						children: [
							{
								path: `:id/${DEFAULTS.routes.docs}`,
								element: <DocumentsListForSource />,
							},
						],
					},
				],
			},
			{
				// path: '/',
				index: true,
				element: <WorkPlace />,
			},
			{
				path: `${DEFAULTS.routes.doc}/:id`,
				element: <HtmlDocument />,
				children: [
					{
						path: DEFAULTS.routes.edit,
						element: (
							<Suspense fallback={<Preloader fixed />}>
								<LazyDocumentDataDialog />
							</Suspense>
						),
					},
				],
			},
			{
				path: DEFAULTS.routes.facer,
				children: [
					{
						path: DEFAULTS.routes.facerRecognize,
						element: (
							<Suspense fallback={<Preloader fixed />}>
								<LazyFacerRecognizeLayout />
							</Suspense>
						),
					},
					{
						path: DEFAULTS.routes.facerVerify,
						element: (
							<Suspense fallback={<Preloader fixed />}>
								<LazyFacerVerifyLayout />
							</Suspense>
						),
					},
				],
			},
			{
				path: '*',
				element: (
					<Suspense fallback={<Preloader />}>
						<LazyPageNotFound />
					</Suspense>
				),
			},
		],
	},
]);

interface IAppProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const App = ({ changePermissions }: IAppProps) => {
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
	const oldHash = useRef(window.location.hash);

	useEffect(() => {
		const updatePermissions = async (isAuthenticated: boolean) => {
			const accessToken = isAuthenticated ? await getAccessTokenSilently() : undefined;
			changePermissions(accessToken ? (jwtDecode(accessToken) as { scope: string }).scope.split(' ') : undefined);
		};

		updatePermissions(isAuthenticated);
	}, [isAuthenticated, getAccessTokenSilently, changePermissions]);

	useEffect(() => {
		const newHash = window.location.hash;

		// Пустий хеш виникає з якоїсь причини після входу в обліковий запис:
		// пошук здійснюється, а хеш очищується. В цьому разі встановлюємо збережений хеш.
		if (!newHash) {
			if (isAuthenticated && oldHash.current) window.location.hash = oldHash.current;
		} else {
			if (oldHash.current !== newHash) oldHash.current = newHash;
		}
	}, [isAuthenticated]);

	if (!(isAuthenticated || isLoading)) return <SplashScreen />;

	return (
		<>
			<RouterProvider router={router} />
			<AccessTokenUpdater />
			<AppVersionChecker />
		</>
	);
};

// const mapState = (state: RootState) => ({});

const mapDispatch = { changePermissions };

const connector = connect(undefined, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(App);
