import { useRouteError } from 'react-router-dom';
import styled from 'styled-components';

interface IErrorInfo {
	status?: number;
	statusText?: string;
	error?: {
		message?: string;
		stack?: string;
	};
}

const ErrorHandler = () => {
	let error = useRouteError() as IErrorInfo;
	console.error(error);
	return (
		<ErrorPanel className="alert alert-danger m-auto">
			<BoldDiv>{'\u{1f914}'} Щось пішло не так</BoldDiv>
			<div>Помилка: {(error as Error).message || error.status + ' ' + error.statusText}</div>
			<div>Адреса: {window.location.href}</div>
			<ErrorInfo>{(error as Error).stack || error.error?.stack}</ErrorInfo>
			<BoldDiv>
				&#x26a0;&#xfe0f; Будь ласка, обов’язково повідомте про дану помилку розробника, описавши порядок Ваших дій, які
				призвели до її виникнення!
			</BoldDiv>
		</ErrorPanel>
	);
};

export default ErrorHandler;

const ErrorPanel = styled.div`
	/* transform: translateY(-32px); */
	text-shadow: 0 1px 1px #fff;
	max-width: 62%;
`;
const ErrorInfo = styled.div`
	white-space: pre-wrap;
`;
const BoldDiv = styled.div`
	font-weight: 700;
`;
