import React, { useEffect, useState } from 'react';
import Select, { ActionMeta, MultiValue, MultiValueGenericProps, OptionProps, components } from 'react-select';
import { DEFAULTS } from '../../common';
import Asterisk from '../../components/Asterisk';
import NatoCountryFlag from '../../components/NatoCountryFlag';
import WithCountries, { IWithCountries } from '../../components/hoc/withCountries';
import { IDictionary, ISelectOption } from '../../interfaces/common';
import { ICountryDic } from '../../interfaces/dict';

const DEFAULT_COUNTRIES = ['UKR', 'RUS', 'BLR'];

interface IMultiCountrySelectorProps extends IWithCountries {
	onCountriesChange?: (trigram: string[]) => void;
	trigrams?: string[];
	showLabel?: boolean;
	label?: string | JSX.Element;
	className?: string;
	containerClassName?: string;
	required?: boolean;
}
const MultiCountrySelector = ({
	countriesAllIds,
	countriesById,
	trigrams,
	onCountriesChange,
	showLabel = true,
	className,
	containerClassName,
	label = 'Країна',
	required = false,
}: IMultiCountrySelectorProps) => {
	const [countries, setCountries] = useState<ISelectOption[]>(() =>
		// countriesAllIds.map((trigram) => ({ label: countriesById[trigram]?.title || '', value: trigram }))
		filterCountries(countriesAllIds, countriesById, [...DEFAULT_COUNTRIES, ...(trigrams || [])], '')
	);

	const countryValues = trigrams
		? trigrams.map((trigram) => ({ label: countriesById[trigram]?.title || '', value: trigram }))
		: [];

	useEffect(() => {
		if (!trigrams) return;
		setCountries(filterCountries(countriesAllIds, countriesById, [...DEFAULT_COUNTRIES, ...trigrams], ''));
	}, [countriesAllIds, countriesById, trigrams]);

	// const onSingleChange = (value: SingleValue<ISelectOption>, actionMeta: ActionMeta<ISelectOption>) => {
	// 	if (onCountryChange && value) onCountryChange(value.value);
	// };

	const onMultiChange = (values: MultiValue<ISelectOption>, actionMeta: ActionMeta<ISelectOption>) => {
		if (onCountriesChange && values) onCountriesChange(values.map(({ value }) => value));
	};

	const onInputChange = (term: string) => {
		if (term.length === 1) return;
		setCountries(filterCountries(countriesAllIds, countriesById, [...DEFAULT_COUNTRIES, ...(trigrams || [])], term));
	};

	return (
		<div title="Країна" className={containerClassName}>
			{showLabel && (
				<label className="form-label">
					{label}
					{required && <Asterisk />}
				</label>
			)}
			<Select
				classNamePrefix="themed-select"
				isMulti
				isSearchable
				options={countries}
				value={countryValues}
				onChange={onMultiChange}
				onInputChange={onInputChange}
				placeholder="країна..."
				name="country"
				components={{ MultiValueLabel, Option: CountryOption }}
				noOptionsMessage={() => DEFAULTS.noMatch}
				// closeMenuOnSelect={false}
				// menuIsOpen
				className={className}
				required={required}
			/>
		</div>
	);
};

export default WithCountries(MultiCountrySelector);

const MultiValueLabel = ({ children, data, ...props }: MultiValueGenericProps<ISelectOption>) => {
	return (
		<components.MultiValueLabel data={data} {...props}>
			<CountrySingleValue trigram={data.value}>{children}</CountrySingleValue>
		</components.MultiValueLabel>
	);
};

const CountryOption = ({ children, data, ...props }: OptionProps<ISelectOption, true>) => (
	<components.Option data={data} {...props} className="d-flex align-items-center">
		<CountrySingleValue trigram={data.value}>{children}</CountrySingleValue>
	</components.Option>
);

interface CountrySingleValueProps extends React.BaseHTMLAttributes<HTMLDivElement> {
	trigram: string;
}
const CountrySingleValue = ({ trigram, children }: CountrySingleValueProps) => (
	<>
		<NatoCountryFlag trigram={trigram} className="me-2" />
		{children}
	</>
);

const filterCountries = (
	countriesAllIds: string[],
	countriesById: IDictionary<string, ICountryDic>,
	initialCountries: string[],
	term: string
) => {
	const lowerTerm = term.toLowerCase();
	const resultIds = new Set(
		lowerTerm
			? countriesAllIds.filter((trigram) => countriesById[trigram]?.title.toLowerCase().includes(lowerTerm))
			: initialCountries
	);
	return [...resultIds].map((trigram) => ({ label: countriesById[trigram]?.title || '', value: trigram }));
};
