import { skipToken } from '@reduxjs/toolkit/query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSourceFromDomainQuery } from './features/services/sources';
import { TObjectType } from './interfaces/common';
import { TDocumentSource } from './interfaces/document';
import {
	arraysOfObjectsHaveSameContent,
	differentContentInArray,
	getDomainFromUrl,
	objectsAreEquals,
	targetIsNotEquals,
} from './utils';

export const useScreenWidth = (width: number) => {
	const [isSmaller, setIsSmaller] = useState(window.innerWidth < width);

	useEffect(() => {
		function handleResize() {
			setIsSmaller(window.innerWidth < width);
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [width]);

	return [isSmaller];
};

export function useFormFields<T extends TObjectType>(initialFields: T) {
	const [formFields, setFormFields] = useState<T>({ ...initialFields });
	const [modified, setModified] = useState<boolean>(false);

	useEffect(() => {
		if (!objectsAreEquals(initialFields, formFields)) setFormFields({ ...initialFields });
	}, [initialFields]); // eslint-disable-line

	useEffect(() => {
		setModified(targetIsNotEquals(initialFields, formFields));
	}, [initialFields, formFields]);

	/**
	 * За бізнес-логікою додатку поле типу boolean реалізовано за допомогою
	 * типу number, що може містити значення 1 або 0
	 */
	const createChangeHandler = useCallback(
		(key: keyof T, fieldType: 'string' | 'number' | 'boolean' = 'string') =>
			(
				event?: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
				newValue?: string | number | null
			) => {
				const value = (event ? event.target?.value : newValue) ?? null;
				setFormFields((prev: T) => ({
					...prev,
					[key]: event
						? fieldType === 'string'
							? value
							: fieldType === 'number'
							? parseInt(value as string, 10) ?? null
							: (event.target as HTMLInputElement).checked
							? 1
							: 0
						: value,
				}));
			},
		[]
	);

	const resetFormFields = useCallback(() => {
		setFormFields({ ...initialFields });
	}, [initialFields]);

	return { formFields, createChangeHandler, setFormFields, resetFormFields, modified };
}

export const useArray = <T>(initialData?: T[]) => {
	const [data, setData] = useState<T[]>(() => initialData || []);
	const [modified, setModified] = useState(false);

	useEffect(() => {
		if (!initialData) return;
		if (differentContentInArray(data, initialData)) setData(initialData);
	}, [initialData]); // eslint-disable-line

	useEffect(() => {
		setModified(differentContentInArray(initialData || [], data));
	}, [data, initialData]);

	const setDataIfDifferent = (newData?: T[]) => {
		if (!newData) return;
		if (differentContentInArray(data, newData)) setData([...new Set(newData)]);
	};

	const toggleItem = (id: T) => {
		setData(data.includes(id) ? data.filter((itemId) => itemId !== id) : [...data, id]);
	};
	// console.log(data);

	return { data, setData: setDataIfDifferent, toggleItem, modified };
};

export const useDebounce = <T>(initialValue: T, delay: number = 500) => {
	const [term, setTerm] = useState(initialValue);
	const [debouncedValue, setDebouncedValue] = useState(initialValue);

	useEffect(() => {
		setTerm(initialValue);
	}, [initialValue]);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(term);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [term, delay]);

	const onTermChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newTerm = event.target.value as unknown as T;
			setTerm(newTerm);
		},
		[setTerm]
	);

	return { term, setTerm, onTermChange, debouncedValue };
};

// export interface IWithPageNo {
// 	pageNo: number;
// }
// export const usePageNo = <T>(
// 	initialValue: T,
// 	initialPageNo = 0
// ): [T & IWithPageNo, (newData: Partial<T>) => void, (newPageNo: number) => void] => {
// 	const [value, setValue] = useState<T & IWithPageNo>(() => ({ ...initialValue, pageNo: initialPageNo }));

// 	// useEffect(() => {
// 	// 	const { pageNo: initialPageNo, ...initialRest } = initialValue;
// 	// 	const { pageNo: currentPageNo, ...currentRest } = value;
// 	// 	if (!targetIsNotEquals(initialRest, currentRest)) return;
// 	// 	setValue({ ...initialValue, pageNo: 0 });
// 	// }, [initialValue]);

// 	const setPageNo = (newPageNo: number) => {
// 		if (newPageNo === value.pageNo) return;
// 		setValue((value) => ({ ...value, pageNo: newPageNo }));
// 	};

// 	const setData = useCallback((newData: Partial<T>) => {
// 		// const { pageNo: newPageNo, ...newRest } = newData;
// 		// const { pageNo: currentPageNo, ...currentRest } = value;
// 		// if (!targetIsNotEquals(newRest, currentRest)) return;
// 		setValue((value) => ({ ...value, ...newData, pageNo: 0 }));
// 	}, []);

// 	return [value, setData, setPageNo];
// };

/**
 * Використовується для роботи з даними таблиці, підлеглої до таблиці документів
 * @param initialData Первинні дані
 * @param idFieldName Назва поля, що містить ідентифікатор запису
 * @returns Атрибути та методи зміни стану
 */
export const useDocumentChildData = <T extends TObjectType, Q extends keyof T>(
	initialData: T[] | undefined,
	idFieldName: Q,
	omitFields: Q[] = []
) => {
	interface IState {
		data: T[];
		newItemIndex: number;
		modified: boolean;
	}

	const [state, setState] = useState<IState>(() => ({
		data: initialData ?? [],
		newItemIndex: initialData?.length ?? 0,
		modified: false,
	}));
	const [editingIndex, setEditingIndex] = useState(state.newItemIndex);
	const refSelectedInput = useRef<HTMLInputElement>(null);

	const setData = useCallback(
		(data: T[]) => {
			if (arraysOfObjectsHaveSameContent(state.data, data, idFieldName, omitFields)) return;
			setState({
				data,
				newItemIndex: data.length,
				modified: !arraysOfObjectsHaveSameContent(initialData ?? [], data, idFieldName, omitFields),
			});
			setEditingIndex(data.length);
		},
		[state.data, idFieldName, omitFields, initialData]
	);

	const focusEditing = useCallback(
		() =>
			setTimeout(() => {
				refSelectedInput.current?.focus();
			}, 100),
		[refSelectedInput]
	);

	useEffect(() => {
		setState({
			data: initialData ?? [],
			newItemIndex: initialData?.length ?? 0,
			modified: false,
		});
	}, [initialData]);

	useEffect(() => {
		focusEditing();
	}, [editingIndex]); // eslint-disable-line

	const onItemReset = useCallback(() => {
		setEditingIndex(state.newItemIndex);
		focusEditing();
	}, [setEditingIndex, focusEditing, state.newItemIndex]);

	return {
		...state,
		editingIndex,
		setEditingIndex,
		refSelectedInput,
		setData,
		onItemReset,
	};
};

export const useDocumentBaseSource = (
	URL: string | null,
	initialSource?: TDocumentSource
): [string | null, TDocumentSource | undefined, boolean, boolean, () => void] => {
	const { term, setTerm, debouncedValue: domain } = useDebounce(getDomainFromUrl(URL), 1000);
	const [source, setSource] = useState(initialSource);
	const { data, refetch, isSuccess, isFetching, isUninitialized } = useSourceFromDomainQuery(domain ?? skipToken, {
		refetchOnMountOrArgChange: true,
		skip: term !== domain || (source && domain === source.base_domain),
		/**
		 * Даний рядок не дає виконувати пошук, коли наявний url виду "pravda.com.ua"
		 * змінюється на url виду "blogs.pravda.com.ua", що не вірним для нас.
		 */
		// skip: term !== domain || (source && (domain === source.base_domain || domain?.endsWith('.' + source.base_domain))),
	});

	useEffect(() => {
		setTerm(getDomainFromUrl(URL));
	}, [URL, setTerm]);

	// При зміні домену очищуємо інформацію про джерело. Вона буде знову встановлена
	// після отримання відповіді з серверу
	useEffect(() => {
		if (source && (term === source.base_domain || term?.endsWith('.' + source.base_domain))) return;
		setSource(undefined);
	}, [term, source]);

	useEffect(() => {
		if (!data) return;
		setSource(data);
	}, [data, setSource]);

	useEffect(() => {
		if (isFetching || isSuccess || isUninitialized) return;
		const timer = setInterval(refetch, 5000);
		return () => clearInterval(timer);
	}, [isSuccess, isFetching, isUninitialized, refetch]);

	return [domain, source, isSuccess, isFetching, refetch];
};

export const useShowSkeleton = (isFetching: boolean, delay: number, initialShow = false) => {
	const { debouncedValue, setTerm } = useDebounce(initialShow, delay);

	useEffect(() => {
		setTerm(isFetching);
	}, [isFetching, setTerm]);

	return isFetching && debouncedValue;
};
