import styled from 'styled-components';

interface ITopStickyDivProps {
	top?: string;
	zIndex?: number;
	bg?: string;
}
export const TopStickyDiv = styled.div<ITopStickyDivProps>`
	position: sticky;
	top: ${(props) => props.top || '56px'};
	z-index: ${(props) => props.zIndex || 1};
	background-color: ${(props) => props.bg || 'var(--bs-body-bg)'};
`;

interface IBottomStickyDivProps {
	bottom?: string;
	bg?: string;
}
export const BottomStickyDiv = styled.div<IBottomStickyDivProps>`
	position: sticky;
	bottom: ${(props) => props.bottom || '0'};
	background-color: ${(props) => props.bg || 'var(--bs-body-bg)'};
`;

interface IDwarfImageProps {
	height?: string;
}
export const DwarfImage = styled.img<IDwarfImageProps>`
	height: ${({ height }) => height || '38vh'};
	width: auto;
	opacity: 0.75;
	filter: drop-shadow(0 0 5px white);
`;

interface IGridByRowsProps {
	baseSize: string;
}
export const GridAutoFill = styled.div<IGridByRowsProps>`
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: ${(props) => `repeat(auto-fill, minmax(${props.baseSize}, 1fr))`};
`;

const NAV_FACE_SIZE = 41;
export const NavFace = styled.span<{ userIcon?: boolean }>`
	position: relative;
	margin-left: ${NAV_FACE_SIZE + 5}px;
	img {
		position: absolute;
		top: -11px;
		right: calc(100% + 0.5rem);
		/* border: ${({ userIcon }) => (userIcon ? 'unset' : '1px solid var(--bs-light)')}; */
		box-shadow: 0 0 4px white;
		width: ${NAV_FACE_SIZE}px;
		height: ${NAV_FACE_SIZE}px;
		object-fit: cover;
		border-radius: ${({ userIcon }) => (userIcon ? '50%' : '0.25rem')};
	}
	img.nav-face_big {
		animation: keyframe-random-face 1s 1 ease;
	}
`;
