import React, { useEffect } from 'react';
import { ButtonGroup, Col, Row } from 'react-bootstrap';
import { BsBoxArrowUpRight, BsPencilSquare } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import { Outlet } from 'react-router-dom';
import { formatDocumentDate } from '../../../common';
import FetchError from '../../../components/FetchError';
import GrayerSkeleton from '../../../components/GreyerSkeleton';
import NatoCountryFlag from '../../../components/NatoCountryFlag';
import QueryLink from '../../../components/QueryLink';
import TextByParagraphs from '../../../components/TextByParagraphs';
import WithDictionaries, { IWithDictionaries } from '../../../components/hoc/withDictionaries';
import TextIcon from '../../../components/textIcon';
import { useShowSkeleton } from '../../../hooks';
import { IDocumentData, TDocumentContextType } from '../../../interfaces/document';
import { getRandomArray } from '../../../utils';
import { useDocumentDataQuery } from '../../services/documents';
import { documentDataAreValid } from './DocumentDataDialog';
import DocumentStatus from './DocumentStatus';

interface IDocumentDataProps extends IWithDictionaries, React.AllHTMLAttributes<HTMLDivElement> {
	documentHash: string;
	onDataFetched: (data: IDocumentData | null) => void;
	textExists: boolean;
	draft?: boolean;
}
const DocumentData = ({
	documentHash,
	staticOptions,
	realms,
	onDataFetched,
	textExists,
	draft,
}: IDocumentDataProps) => {
	const { data, isFetching, error } = useDocumentDataQuery(documentHash);
	const showSkeleton = useShowSkeleton(draft === false && isFetching, 100);

	useEffect(() => {
		onDataFetched(data ?? null);
	}, [data, onDataFetched]);

	if (error) return <FetchError error={error} className="w-50 mt-3" />;
	if (showSkeleton)
		return (
			<div className="mb-3">
				<h4>Опис дослідження</h4>
				{getRandomArray(5, 10).map((_, index) => (
					<DataRow key={`d_${index}`}>
						<Skeleton count={Math.random() * 2} containerClassName="flex-grow-1" />
					</DataRow>
				))}
			</div>
		);
	if (!data) return null;

	const {
		main,
		countries,
		disclaimers,
		containing,
		titles,
		toc,
		sources: { links, byId },
		editable,
		closeable,
		attached,
	} = data;

	const onDocumentDataAreValid = async (): Promise<boolean> => {
		return await documentDataAreValid({
			containingLength: containing.length,
			countriesLength: countries.length,
			titlesLength: titles.length,
			tocLength: toc.length,
			linksLength: links.length,
			dialogType: 'E',
			resumeMessage: 'Для закриття документу всі помилки мають бути виправлені!',
			documentLinks: links,
		});
	};

	return (
		<>
			<Row className="mb-3">
				<Col>
					<div className="hstack justify-content-between">
						<h4>Опис дослідження</h4>
						<ButtonGroup>
							{editable && (
								<QueryLink to="edit" replace className="btn btn-primary">
									<TextIcon Icon={BsPencilSquare}>Редагувати</TextIcon>
								</QueryLink>
							)}
							<DocumentStatus
								current={main.status}
								DOCUMENT_STATUS={staticOptions.DOCUMENT_STATUS}
								editable={editable}
								closeable={closeable}
								documentId={main.id}
								documentDataAreValid={onDocumentDataAreValid}
								deletionDisabled={attached.length !== 0}
								statusChangeDisabled={attached.length === 0}
							/>
						</ButtonGroup>
					</div>
					<div className="font-monospace">
						<DataRow title="Сегмент даних">{realms.entities[main.realm]?.title}</DataRow>
						<DataRow title="Безпека">
							{staticOptions.RESTRICTION_OPTIONS
								? staticOptions.RESTRICTION_OPTIONS[main.restriction]
								: main.restriction}
						</DataRow>
						<DataRow title="Завдання">
							{staticOptions.TASK_OPTIONS ? staticOptions.TASK_OPTIONS[main.task] : main.task}
						</DataRow>
						<DataRow title="Пріоритет">
							{staticOptions.PRIORITY_OPTIONS ? staticOptions.PRIORITY_OPTIONS[main.priority] : main.priority}
						</DataRow>
						<DataRow title="Тип завдання">
							{staticOptions.TASK_TYPE_OPTIONS ? staticOptions.TASK_TYPE_OPTIONS[main.task_type] : main.task_type}
						</DataRow>
						<DataRow title="Версія">
							{staticOptions.VERSION_OPTIONS ? staticOptions.VERSION_OPTIONS[main.version] : main.version}
						</DataRow>
						<DataRow title="Серія">
							{staticOptions.SERIES_OPTIONS ? staticOptions.SERIES_OPTIONS[main.series] : main.series}
						</DataRow>
						<DataRow title="Дата створення">{formatDocumentDate(main.creation_date)}</DataRow>
						<DataRow title="Дати збирання інформації">
							{formatDocumentDate(main.collection_start_date)}–{formatDocumentDate(main.collection_stop_date)}
						</DataRow>
						{main.event_start_date && (
							<DataRow title="Дата події/контенту">
								{formatDocumentDate(main.event_start_date)}
								{main.event_stop_date && <>–{formatDocumentDate(main.event_stop_date)}</>}
							</DataRow>
						)}
						{main.validity_date && (
							<DataRow title="Термін актуальності інформації">{formatDocumentDate(main.validity_date)}</DataRow>
						)}
						<DataRow title="Країни, пов’язані з дослідженням">
							<div>
								{countries.map((country) => (
									<NatoCountryFlag key={country} trigram={country} withTitle className="me-2" inline />
								))}
							</div>
						</DataRow>
						{main.lat && main.lon && (
							<DataRow title="Географічне розташування">
								{main.lat} / {main.lon}
								<a
									href={`https://www.google.com/maps/place/${main.lat}+${main.lon}/@${main.lat},${main.lon},11z`}
									target="_blank"
									rel="noreferrer"
									className="d-print-none ms-2"
								>
									<BsBoxArrowUpRight className="mt-n1" />
								</a>
							</DataRow>
						)}
						<DataRow title="Тема">
							<ul className="list-unstyled mb-0">
								{main.title}.{' '}
								{titles.map((title) => (
									<li key={title} className="d-inline me-1">
										{title}.
									</li>
								))}
							</ul>
						</DataRow>
						<DataRow title="Автор">{main.user_code}</DataRow>
						{disclaimers.length !== 0 && (
							<DataRow title="Застереження">
								<ul className="list-unstyled mb-0">
									{disclaimers.map((disclaimer, index) => (
										<li key={disclaimer} className="d-inline">
											{index !== 0 && ', '}
											{staticOptions.DISCLAIMER_OPTIONS ? staticOptions.DISCLAIMER_OPTIONS[disclaimer] : disclaimer}
										</li>
									))}
								</ul>
							</DataRow>
						)}
						<DataRow title="Резюме">
							<TextByParagraphs text={main.summary} />
						</DataRow>
						<DataRow title="Зміст">
							<ul className="list-unstyled mb-0 w-100">
								{toc.map((section, index) => (
									<li key={index}>
										{section.title} (стор. {section.start_page})
									</li>
								))}
							</ul>
						</DataRow>
						<DataRow title="Дослідження містить">
							<ul className="list-unstyled mb-0">
								{containing.map((c, index) => (
									<li key={c} className="d-inline">
										{index !== 0 && ', '}
										{staticOptions.CONTAINING_OPTIONS ? staticOptions.CONTAINING_OPTIONS[c] : c}
									</li>
								))}
							</ul>
						</DataRow>
						{main.comment && (
							<DataRow title="Коментар">
								<TextByParagraphs text={main.comment} />
							</DataRow>
						)}
						<DataRow title="Джерела">
							<ul className="mb-0 ps-3">
								{links.map(
									({
										id: ID,
										source_id: SOURCE_ID,
										pub_title: PUB_TITLE,
										pub_url: PUB_URL,
										pub_author: PUB_AUTHOR,
										pub_date: PUB_DATE,
										source_reliability: SOURCE_RELIABILITY,
										pub_reliability: PUB_RELIABILITY,
										comment: COMMENT,
									}) => (
										<li key={ID}>
											{/* {SOURCE_ID && (
												<span className="fw-bolder me-2">
													{byId[SOURCE_ID]?.TITLE} —
													<NatoCountryFlag trigram={byId[SOURCE_ID]?.COUNTRY} withTitle inline className="ms-2" />
												</span>
											)} */}
											{SOURCE_ID && (
												<span className="fw-bolder me-2">
													{byId[SOURCE_ID]?.title} ({byId[SOURCE_ID]?.country}).
													<wbr />
												</span>
											)}
											<a target="_blank" rel="noreferrer" href={PUB_URL} className="d-print-none text-break">
												{PUB_TITLE || PUB_URL}
											</a>
											{PUB_TITLE && <span className="d-none d-print-inline ms-2">{PUB_TITLE}</span>}
											<span className="d-none d-print-inline text-break ms-2">{PUB_URL}</span>
											{PUB_AUTHOR && <span>, {PUB_AUTHOR}</span>}
											{PUB_DATE && <span>, {formatDocumentDate(PUB_DATE)}</span>}, рейтинг
											<strong className="ms-2">
												{SOURCE_RELIABILITY}
												{PUB_RELIABILITY}
											</strong>
											{SOURCE_ID && SOURCE_RELIABILITY !== byId[SOURCE_ID]?.reliability && (
												<span className="ms-1 text-secondary">
													(поточна якість джерела — {byId[SOURCE_ID]?.reliability})
												</span>
											)}
											.{COMMENT && <TextByParagraphs text={COMMENT} />}
										</li>
									)
								)}
							</ul>
						</DataRow>
					</div>
					{/* <hr /> */}
				</Col>
			</Row>
			{data && <Outlet context={{ data, realm: main.realm, documentHash, textExists } as TDocumentContextType} />}
		</>
	);
};

export default WithDictionaries(DocumentData);

interface IDataRowProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const DataRow = ({ title, children }: IDataRowProps) => {
	return (
		<div className="hstack gap-1 align-items-start mt-1 border-bottom">
			<span className="fw-bolder w-25 flex-shrink-0">
				{title || <GrayerSkeleton count={Math.random() * 0.5 + 0.5} />}
				{title && ':'}
			</span>
			{children}
		</div>
	);
};
