import classNames from 'classnames';
import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import { BsPencilSquare } from 'react-icons/bs';
import { IDictionary } from '../../../interfaces/common';
import { IRealmDic } from '../../../interfaces/dict';
import { IUser } from '../../../interfaces/users';
import { getUserLabel } from '../../services/users';

interface IUserItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	user?: IUser;
	realms: IDictionary<string, IRealmDic>;
	manager?: IUser;
}
const UserItem = ({ user, realms, manager }: IUserItemProps) => {
	// Ця строка більше потрібна для відповідності типам
	if (!user) return null;

	return (
		<tr>
			<td>
				<Badge bg="secondary">{user.id}</Badge>
			</td>
			<td id={`user_${user.id}`}>{user.email}</td>
			<td className={classNames('font-monospace', !user.code && 'text-body-tertiary')}>{user.code || 'null'}</td>
			<td className="font-monospace">
				{manager ? getUserLabel(manager, false) : <span className="text-body-tertiary">null</span>}
			</td>
			<td>{user.docsCount}</td>
			<td>
				{user.editableRealms.length !== 0 ? (
					user.editableRealms.map((realm) => (
						<Badge key={realm} bg={realms[realm]?.draft ? 'secondary' : 'success'} className="me-1">
							{realms[realm]?.title}
						</Badge>
					))
				) : (
					<span className="text-body-tertiary">&empty;</span>
				)}
			</td>
			<td>
				<Button variant="link" size="sm" data-user-id={user.id} className="btn-edit p-0" title="Редагувати">
					<BsPencilSquare />
					<span className="ms-1 d-none d-lg-inline">Редагувати</span>
				</Button>
			</td>
		</tr>
	);
};

export default UserItem;
