import { THighlighting } from '../common';
import { IDictionary, TEntityId, TMutatingRow } from './common';
import {
	TContainingOptions,
	TDisclaimerOptions,
	TDocumentStatusOptions,
	TPriorityOptions,
	TPublicationReliabilityOptions,
	TRestrictionOptions,
	TSeriesOptions,
	TSourceReliabilityOptions,
	TTaskOptions,
	TTaskTypeOptions,
	TVersionOptions,
} from './dict';
import { ISourceBase } from './sources';

/**
 * Тут назва поля "selected" тому, що первинно даний інтерфейс використовувався
 * для роботи з наборами checkboxes. Наразі він використовується для роботи
 * з масивами будь-яких об'єктів, але назва лишилась стара.
 */
export interface IArrayDataOptions<T> {
	modified: boolean;
	selected: T[];
}
export type ITextArrayDataOptions = IArrayDataOptions<TEntityId<string>>;

export interface ISolrDocument {
	id: string;
	title: string;
	size: number;
	create_date: string;
	score: number;
	html: string;
	ext: string;
	realm: string;
	hidden?: boolean; // Документ приховано, тому що його ще не виконано
}

export interface ISolrResponse {
	responseHeader: {
		partialResults?: boolean;
		QTime: number;
	};
	response: {
		numFound: number;
		maxScore: number;
		docs: ISolrDocument[];
	};
	// facet_counts: IFacetCounts;
	highlighting?: THighlighting;
}

export interface IDocumentBase {
	id: number;
	realm: string;
	creator_id: number;
	restriction: TRestrictionOptions;
	task: TTaskOptions;
	priority: TPriorityOptions;
	task_type: TTaskTypeOptions;
	version: TVersionOptions;
	series: TSeriesOptions;
	creation_date: string;
	collection_start_date: string;
	collection_stop_date: string;
	event_start_date: string | null;
	event_stop_date: string | null;
	validity_date: string | null;
	lat: number | null;
	lon: number | null;
	summary: string;
	comment: string | null;
	status: TDocumentStatusOptions;
	title: string;
}
export interface IDocumentView extends IDocumentBase {
	user_code: string;
}
export type TMutatingDocumentBase = TMutatingRow<IDocumentBase, 'id'>;

export interface IDocumentAttached {
	id: number;
	// document_id: number;
	document_hash: string;
	document_no: number;
}

// export interface IDocumentTitleBase {
// 	ID: number;
// 	DOCUMENT_ID: number;
// 	TITLE: string;
// }
// export type IDocumentTitleView = Omit<IDocumentTitleBase, 'DOCUMENT_ID'>;

export interface IDocumentTocBase {
	id: number;
	document_id: number;
	title: string;
	start_page: number;
}
export type IDocumentTocView = Omit<IDocumentTocBase, 'document_id'>;

export interface IDocumentSourceBase {
	id: number;
	document_id: number;
	source_id: number | null;
	source_reliability: TSourceReliabilityOptions;
	pub_title: string | null;
	pub_author: string | null;
	pub_date: string | null;
	pub_url: string;
	pub_reliability: TPublicationReliabilityOptions;
	comment: string | null;
}
export type TDocumentSource = Omit<ISourceBase, 'update_date' | 'findable' | 'description'>;
export interface IDocumentSourceView extends Omit<IDocumentSourceBase, 'document_id'> {}
export interface IDocumentSourceData {
	links: IDocumentSourceView[];
	byId: IDictionary<number, TDocumentSource>;
}

export interface IDocumentData {
	main: IDocumentView;
	countries: string[];
	disclaimers: TDisclaimerOptions[];
	containing: TContainingOptions[];
	// titles: IDocumentTitleView[];
	titles: string[];
	toc: IDocumentTocView[];
	sources: IDocumentSourceData;
	attached: IDocumentAttached[]; // хеши приєднаних до опису документів Solr
	editable: boolean; // чи може користувач редагувати документ
	closeable: boolean; // чи може користувач закрити документ
}

export interface IMutatingDocumentData {
	main: TMutatingDocumentBase;
	disclaimers: TDisclaimerOptions[];
	containing: TContainingOptions[];
	countries: string[];
	titles: string[];
	toc: IDocumentTocView[];
	links: IDocumentSourceView[];
}

export type TDocumentContextType = { data: IDocumentData; realm: string; documentHash: string; textExists: boolean };

export const DOCUMENT_STATUS_COLORS: { [key in TDocumentStatusOptions]: string } = {
	L: 'danger',
	W: 'secondary',
	P: 'warning',
	D: 'success',
};
