import { TObjectType } from './interfaces/common';

/**
 * Определяет, изменились ли значения полей объекта target по сравнению с объектом source.
 * Ключи перебираются по объекту source
 * Если источника нет, то считаем, что объекты различаются
 * @param source Исходный объект
 * @param target Сравниваемый объект
 * @param strict Если равно true, то производит строгое сравнение, иначе - нет
 */
export const targetIsNotEquals = (source: TObjectType | undefined, target: TObjectType) =>
	source === undefined || Object.keys(source).some((key) => source[key] !== target[key]);

/**
 * Повертає об'єкт розбіжностей між двома об'єктами для передачі на сервер.
 * Примітка: в БД логічний тип представлено значеннями 1 та 0.
 * @param source Исходный объект
 * @param target Объект после редактирования
 * @param REQUIRED_FIELDS Названия полей, которые обязательно быть включены в пакет на отправку
 */
export const getDataForSave = <T extends TObjectType, Q extends keyof T>(
	source: T | undefined,
	target: T,
	REQUIRED_FIELDS: (keyof T)[] = [],
	nullIfEmpty = true
): Partial<T> & Pick<T, Q> => {
	const result = { ...target };
	if (source !== undefined) {
		Object.keys(source).forEach((key) => {
			if (!REQUIRED_FIELDS.includes(key) && source[key] === result[key]) delete result[key];
		});
	}
	if (nullIfEmpty)
		for (const key in result) {
			if (result[key] === '') (result[key] as any) = null;
		}
	return result;
};

/**
 * Повертає інформацію про те, чи містять масиві різні (саме так) елементи.
 * Зверніть увагу на те, що порядок елементів в масивах не має значення.
 * @param array1 Масив 1
 * @param array2 Масив 2
 * @returns Якщо вміст масивів різний, то повертається True
 */
export const differentContentInArray = <T>(array1: T[], array2: T[]) => {
	if (array1.length !== array2.length) return true;
	return array1.some((item) => !array2.includes(item));
};

export const getControlData = <T extends HTMLElement>(
	target: EventTarget,
	controlSelector: string,
	dataName?: string
) => {
	const control = (target as HTMLElement).closest(controlSelector) as T;
	if (!control) return;
	return dataName ? control.dataset[dataName] : control.dataset;
};

/**
 * Виконує неглибоке порівняння двох об'єктів
 * @param etalon Вихідний об'єкт
 * @param target Кінцевий об'єкт
 * @returns Якщо об'єкти однакові, то повертається True, інакше False
 */
export const objectsAreEquals = <T extends TObjectType, Q extends keyof T>(
	etalon: T,
	target?: Partial<T>,
	omit: Q[] = []
) =>
	target ? Object.entries(etalon).every(([key, value]) => omit.includes(key as Q) || target[key] === value) : false;

/**
 * Виконує порівняння двох масивів, що містять однотипні об'єкти в будь-якій послідовності
 * @param array1 Перший масив об'єктів
 * @param array2 Другий масив об'єктів
 * @param idFieldName Назва поля, щоо містить ідентифікатор запису
 * @returns Якщо масиви містять однакові об'єкти (в будь-якому порядку), повертається True
 */
export const arraysOfObjectsHaveSameContent = <T extends TObjectType, Q extends keyof T>(
	array1: T[],
	array2: T[],
	idFieldName: keyof T,
	omit: Q[] = []
) => {
	if (array1.length !== array2.length) return false;
	return array1.every((etalon) => {
		const id = etalon[idFieldName];
		const target = array2.find((item) => item[idFieldName] === id);
		return objectsAreEquals(etalon, target, omit);
	});
};

export const getDomainFromUrl = (URL?: string | null) => {
	if (!URL) return null;
	const data = /^(?:https?:\/\/)?([^/\s]+)/i.exec(URL.trim());
	if (!data) return null;
	return data[1].toLowerCase();
};

/**
 * Визначає, чим є даний ідентифікатор:
 * - якщо він складається лише з чисел, то це serial id from table "document";
 * - в іншому разі (ідентифікатор містить щось, крім цифр) це Solr id.
 * @param id Ідентифікатор повідомлення (serial id from table "document" or Solr id)
 * @returns Чи є переданий ідентифікатор serial id from table "documents"
 */
export const idIsSerial = (id: string = '') => /^\d+$/.test(id);

/**
 * Визначає правильність періоду дат
 * @param startDate дата початку періоду
 * @param endDate дата закінчення періоду
 * @param validIfBothNotDefined визначає, чи є період вірним, якщо дати періоду на задані
 * @returns true, якщо дата початку періоду менша, або дорівнює даті закінчення періоду.
 */
export const datePeriodIsValid = (startDate: string | null, endDate: string | null, validIfBothNotDefined = true) => {
	if (!startDate && !endDate) return validIfBothNotDefined;
	if (!startDate || !endDate) return false;
	return new Date(startDate).valueOf() <= new Date(endDate).valueOf();
};

/**
 * The function `onBeforeUnloadStandard` is used to handle the `beforeunload` event in TypeScript,
 * preventing the default behavior of the event.
 * @param {BeforeUnloadEvent} event - The `event` parameter in the `onBeforeUnloadStandard` function is
 * of type `BeforeUnloadEvent`. This event is fired before the window unloads, allowing you to prompt
 * the user with a confirmation dialog to prevent accidental loss of data or navigation away from the
 * page.
 * @returns The function `onBeforeUnloadStandard` is returning `false`.
 */
export const onBeforeUnloadStandard = (event: BeforeUnloadEvent) => {
	// Це чомусь не працює (мабуть через асинхронність), але стандартна поведінка мене влаштовує
	// if (await showPromiseConfirm('Ви впевнені, що бажаєте закрити сторінку?')) return true;
	// Требует стандарт
	event.preventDefault();
	// Требует стандарт
	event.returnValue = '';
	return false;
};

export const loadFileToImageControl = (fileControl: HTMLInputElement | null) =>
	new Promise<string>((response, reject) => {
		if (!fileControl) return reject('Відсутній елемент зображення');
		const reader = new FileReader();
		reader.onload = () => response(reader.result as string);

		const files = fileControl.files;
		if (!files || files.length === 0) return reject('Файл зображення не обрано');
		const [file] = files;

		reader.readAsDataURL(file);

		fileControl.value = ''; // Очистка файла
	});

export const loadUrlToImageControl = (url: string) => {
	return new Promise<string>((resolve, reject) => {
		// Створення нового об'єкта Image
		const img = new Image();
		img.crossOrigin = 'anonymous'; // Додаємо crossOrigin, щоб уникнути проблем з політикою CORS

		// Обробка події завантаження зображення
		img.onload = () => {
			// Створення canvas для роботи із зображенням
			const canvas = document.createElement('canvas');
			canvas.width = img.width;
			canvas.height = img.height;

			// Малювання зображення на canvas
			const ctx = canvas.getContext('2d');
			if (!ctx) return reject(`Не вдалося створити об'єкт canvas`);

			ctx.drawImage(img, 0, 0);

			// Отримання base64 даних з canvas
			const dataURL = canvas.toDataURL('image/png'); // Можна змінити 'image/png' на інший формат, якщо потрібно
			resolve(dataURL);
		};

		// Обробка помилок завантаження зображення
		img.onerror = (err) => {
			reject(`Не вдалося завантажити зображення за адресою: ${url}`);
		};

		// Встановлення URL для завантаження
		img.src = url;
	});
};

export const getSimilarityPercent = (threshold: number, distance: number) =>
	(((60 - 100) / threshold) * distance + 100).toFixed(1) + ' %';

export const getRandomArray = (minCount: number, maxCount: number) =>
	new Array(Math.round(Math.random() * (maxCount - minCount)) + minCount).fill(undefined);
