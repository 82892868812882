import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { BsCrosshair2 } from 'react-icons/bs';
import styled from 'styled-components';
import { DEFAULTS } from '../../common';
import { useDebounce } from '../../hooks';
import { ISolrDocument } from '../../interfaces/document';
import { moveControlToScreenCenter, scrollControlIntoContainerCenter } from '../../scrolls';
import { getControlData } from '../../utils';

const imgWidth = 80;
const STICKY_TOP_POSITION = 121;

interface IDocumentImageTHumbnailsProps extends React.AllHTMLAttributes<HTMLDivElement> {
	solrDocument: ISolrDocument;
	isSticky: boolean;
}
const DocumentImageTHumbnails = ({ solrDocument, isSticky }: IDocumentImageTHumbnailsProps) => {
	const [imageCount, setImageCount] = useState(0);
	const { setTerm: setImageNo, debouncedValue: debouncedImageNo } = useDebounce(-1, 500);

	useEffect(() => {
		const io = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (!entry.isIntersecting) return;
					const imageNo = (entry.target as HTMLImageElement).dataset.imageNo;
					setImageNo(imageNo ? parseInt(imageNo) : -1);
				});
			},
			{
				// root: document.querySelector('.html-document-container'),
				threshold: 1,
			}
		);
		const images = document.querySelectorAll('.img-in-doc') as NodeListOf<HTMLImageElement>;
		setImageCount(images.length);

		for (const image of images) {
			if (!image.src.startsWith('data:')) continue;
			io.observe(image);
		}
	}, [solrDocument, setImageNo]);

	useEffect(() => {
		if (!debouncedImageNo) return;
		const image = document.querySelector(`.img-out-doc[data-image-no="${debouncedImageNo}"]`) as HTMLImageElement;
		scrollControlIntoContainerCenter(image, isSticky ? STICKY_TOP_POSITION : DEFAULTS.FIXED_TOP_POSITION);
	}, [debouncedImageNo, isSticky]);

	const onImageClick = useCallback(async (event: React.MouseEvent<HTMLDivElement>) => {
		const imageNo = getControlData(event.target, '.img-out-doc', 'imageNo');
		if (imageNo === undefined) return;
		moveControlToScreenCenter(document.querySelector(`.img-in-doc[data-image-no="${imageNo}"]`));
	}, []);

	if (imageCount === 0) return null;

	return (
		<ImagesOuterContainer
			className={classNames(isSticky ? 'position-sticky col' : 'position-fixed p-0')}
			onClick={onImageClick}
		>
			<ImagesInnerContainer className="d-flex flex-column gap-1 overflow-y-auto bg-secondary-subtle mh-100">
				{new Array(imageCount).fill(undefined).map((notUsed, image_no) => {
					const src = (document.querySelector(`.img-in-doc[data-image-no="${image_no}"]`) as HTMLImageElement | null)
						?.src;
					return src?.startsWith('data:') ? (
						<ThumbnailContainer
							key={image_no}
							className="link-button text-center img-out-doc lh-1 position-relative border border-secondary"
							data-image-no={image_no}
						>
							<Thumbnail
								src={(document.querySelector(`.img-in-doc[data-image-no="${image_no}"]`) as HTMLImageElement).src}
								loading="lazy"
							/>
							{image_no === debouncedImageNo && <BsCrosshair2 className="position-absolute top-0 end-0 text-primary" />}
						</ThumbnailContainer>
					) : null;
				})}
			</ImagesInnerContainer>
		</ImagesOuterContainer>
	);
};

export default DocumentImageTHumbnails;

const ImagesOuterContainer = styled.div`
	top: var(--img-thumbnails-top, ${DEFAULTS.FIXED_TOP_POSITION}px);
	bottom: 80px;
	right: 0;
	width: ${imgWidth}px;
	&.position-sticky {
		flex-basis: ${imgWidth}px;
		--img-thumbnails-top: ${STICKY_TOP_POSITION}px;
		height: calc(100vh - ${STICKY_TOP_POSITION}px);
		/* margin: 1px 0; */
	}
`;

const ImagesInnerContainer = styled.div`
	scrollbar-width: thin;
	scroll-snap-type: y proximity;
`;
const ThumbnailContainer = styled.button`
	/* flex: 1 1 ${imgWidth}px; */
	scroll-snap-align: start;
`;

const Thumbnail = styled.img`
	width: 100%;
	object-fit: contain;
`;
