import React, { useCallback, useEffect, useRef } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { BsArrowLeftCircle, BsArrowRightCircle, BsCommand, BsTrash, BsUpload } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { showError, showPromiseConfirm } from '../../../alerts';
import { DEFAULTS } from '../../../common';
import TextIcon from '../../../components/textIcon';
import { IDocumentAttached } from '../../../interfaces/document';
import { useAppDispatch } from '../../../store';
import { getControlData } from '../../../utils';
import { clearRecentDocuments } from '../../search/searchSlice';
import {
	IDocumentPageChangeParams,
	useDeleteDocumentMutation,
	useMoveDocumentsMutation,
} from '../../services/documents';
import UploaderFileSelector from '../../uploads/UploaderFileSelector';

interface IAttachedDocumentsActionsProps extends React.AllHTMLAttributes<HTMLDivElement> {
	attached: IDocumentAttached[];
	documentId: number;
	selectedIndex: number;
	realm: string;
	selectedHash?: string;
	extension: string;
}
const AttachedDocumentsActions = ({
	documentId,
	attached,
	selectedIndex,
	realm,
	selectedHash,
	extension,
}: IAttachedDocumentsActionsProps) => {
	const dispatch = useAppDispatch();
	const refFileSelector = useRef<HTMLInputElement>(null);
	const [movePages] = useMoveDocumentsMutation();
	const navigate = useNavigate();
	const [deleteDocument, deleteDocumentResult] = useDeleteDocumentMutation();

	const invalidateRecentDocuments = useCallback(() => dispatch(clearRecentDocuments()), [dispatch]);

	const onDeleteClick = useCallback(async () => {
		if (!selectedHash) return;
		if (!(await showPromiseConfirm('Ви дійсно бажаєте видалити поточну частину?'))) return;
		await deleteDocument({ hash: selectedHash, id: documentId, ext: extension });
		invalidateRecentDocuments();
	}, [selectedHash, deleteDocument, documentId, extension, invalidateRecentDocuments]);

	useEffect(() => {
		const { isUninitialized, isError, error, isSuccess } = deleteDocumentResult;
		if (isUninitialized) return;
		if (isError) return showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
		if (!isSuccess) return;
		const remainingHashes: string[] = attached
			.map(({ document_hash }) => document_hash)
			.filter((h) => h !== selectedHash);
		navigate(`../${remainingHashes[0] ?? documentId}`, {
			replace: true,
			relative: 'path',
		});
	}, [deleteDocumentResult]); // eslint-disable-line

	const onMoveClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
		const direction = getControlData(event.target, '.di-page-move', 'direction');
		if (!direction) return;
		const forward = direction === 'forward';
		const current = attached[selectedIndex];
		const other = attached[forward ? selectedIndex + 1 : selectedIndex - 1];
		if (!other || !current) return;
		const data: IDocumentPageChangeParams = {
			id: documentId,
			pages: [
				{ id: current.id, document_no: other.document_no },
				{ id: other.id, document_no: current.document_no },
			],
		};
		await movePages(data);
		invalidateRecentDocuments();
	};

	const onAddPartClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		refFileSelector.current?.click();
	};

	const onUploadDone = (documentHash?: string) => {
		if (documentHash) navigate(`../${documentHash}`, { replace: true, relative: 'path' });
	};

	return (
		<DropdownButton
			variant="link p-0 link-secondary text-decoration-none"
			// size="sm"
			title={
				<TextIcon Icon={BsCommand} inline>
					<span className="d-none d-md-inline">Дії</span>
				</TextIcon>
			}
			id="ddNewDocument"
		>
			<Dropdown.Item href="#" onClick={onAddPartClick}>
				<TextIcon Icon={BsUpload}>Додати новий файл</TextIcon>
			</Dropdown.Item>
			<UploaderFileSelector
				onUploadDone={onUploadDone}
				realm={realm}
				isDraft={false}
				autoClose={true}
				documentId={documentId}
				documentNo={attached.length + 1}
				ref={refFileSelector}
				title="Додавання нової частини документа"
			/>
			<Dropdown.Item href="#" disabled={!extension} onClick={onDeleteClick}>
				<TextIcon Icon={BsTrash}>Видалити поточну частину</TextIcon>
			</Dropdown.Item>
			{1 < attached.length && (
				<>
					<Dropdown.Divider />
					<Dropdown.Item
						href="#"
						data-direction="backward"
						className="di-page-move"
						onClick={onMoveClick}
						disabled={selectedIndex === 0}
					>
						<TextIcon Icon={BsArrowLeftCircle}>Посунути частину ліворуч</TextIcon>
					</Dropdown.Item>
					<Dropdown.Item
						href="#"
						data-direction="forward"
						className="di-page-move"
						onClick={onMoveClick}
						disabled={selectedIndex === attached.length - 1}
					>
						<TextIcon Icon={BsArrowRightCircle}>Посунути частину праворуч</TextIcon>
					</Dropdown.Item>
				</>
			)}
		</DropdownButton>
	);
};

export default AttachedDocumentsActions;
