import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { DEFAULTS, formatDocumentDate } from '../../../common';
import { TDocumentStatusOptions } from '../../../interfaces/dict';
import { DOCUMENT_STATUS_COLORS } from '../../../interfaces/document';
import { IRecentDocument } from '../../services/documents';

interface IRecentDocumentListProps {
	documents: IRecentDocument[];
	isNotDraftEditor: boolean;
	DOCUMENT_STATUS?: { [key in TDocumentStatusOptions]: string };
	indexBase: number;
	isFetching: boolean;
}
const RecentDocumentList = ({
	documents,
	isNotDraftEditor,
	DOCUMENT_STATUS,
	indexBase,
	isFetching,
}: IRecentDocumentListProps) => {
	return (
		<>
			{documents.map(({ id, title, first_document_hash, insertion_date, user_code, status, editable }, index) => (
				<tr key={id} className={classNames('position-relative', indexBase !== 0 && index === 0 && 'tr-top-separator')}>
					<td>{indexBase + index + 1}.</td>
					<td>
						<Link
							to={`/${DEFAULTS.routes.doc}/${first_document_hash || id}`}
							className={classNames(
								isNotDraftEditor ? (editable ? 'link-primary' : 'link-secondary') : 'link-primary',
								'stretched-link'
							)}
							state={DEFAULTS.fromListState}
						>
							{title ?? 'Документ без назви'}
							{/* {editable === 1 && <BsPencilSquare className="ms-1" />} */}
						</Link>
					</td>
					<td className="d-none d-sm-table-cell">{formatDocumentDate(insertion_date)}</td>
					{/* {isNotDratEditor && <td>{user_code}</td>} */}
					<td className="d-none d-md-table-cell">{user_code}</td>
					<td className={`text-${DOCUMENT_STATUS_COLORS[status]} text-nowrap`}>
						{DOCUMENT_STATUS ? DOCUMENT_STATUS[status] : status}
					</td>
				</tr>
			))}
		</>
	);
};

export default RecentDocumentList;
