import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import appReducer from './features/app/appSlice';
import manageReducer from './features/manage/manageSlice';
import faceSearchReducer from './features/search/faceSearchSlice';
import searchReducer from './features/search/searchSlice';
import { moriaApi } from './features/services/moriaApi';

const store = configureStore({
	reducer: {
		app: appReducer,
		search: searchReducer,
		faceSearch: faceSearchReducer,
		manage: manageReducer,
		[moriaApi.reducerPath]: moriaApi.reducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(moriaApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
// export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppDispatch: () => AppDispatch = useDispatch;

export const selectAppStore = (state: RootState) => state.app;
export const selectSearchStore = (state: RootState) => state.search;
export const selectPhotoSearchStore = (state: RootState) => state.faceSearch;
export const selectManageStore = (state: RootState) => state.manage;

export default store;
