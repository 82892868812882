import { skipToken } from '@reduxjs/toolkit/query';
import { useCallback } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatDocumentDate } from '../../../common';
import DotSpinner from '../../../components/DotSpinner';
import FetchError from '../../../components/FetchError';
import { ISourceBase } from '../../../interfaces/sources';
import { useRecentDocumentsBySourceQuery } from '../../services/sources';

// interface IDocumentsListForSourceProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const DocumentsListForSource = () => {
	const { id = '' } = useParams<'id'>();
	const sourceId = parseInt(id);
	const { state } = useLocation();
	const source = (state as { source?: ISourceBase })?.source;
	const navigate = useNavigate();
	const { data, error, isFetching } = useRecentDocumentsBySourceQuery(sourceId || skipToken);

	const onHide = useCallback(() => {
		if (source) navigate(-1);
		else navigate('..');
	}, [navigate, source]);

	if (isNaN(sourceId)) return null;
	return (
		<Modal show size="xl" fullscreen="lg-down" onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title className="text-truncate">Список останніх документів, в яких використане джерело</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isFetching && <DotSpinner>Завантаження списку документів</DotSpinner>}
				{data && (
					<Table striped responsive className="mb-0">
						{source && (
							<caption className="pb-0">
								{source.title}
								<a
									href={`https://${source.base_domain}`}
									target="_blank"
									rel="noreferrer"
									className="ms-2 link-secondary"
								>
									({source.base_domain})
								</a>
							</caption>
						)}
						<thead>
							<tr>
								<th>#</th>
								<th>Назва</th>
								<th>Додано</th>
								<th>Посилань</th>
								<th>Виконавець</th>
							</tr>
						</thead>
						<tbody>
							{data.map(({ title, document_id, links_count, insertion_date, user_code }, index) => (
								<tr key={document_id}>
									<td>{index + 1}.</td>
									<td>
										<Link to={`/doc/${document_id}`}>{title}</Link>
									</td>
									<td>{formatDocumentDate(insertion_date)}</td>
									<td>{links_count}</td>
									<td className="font-monospace">{user_code}</td>
								</tr>
							))}
						</tbody>
					</Table>
				)}
				{error && <FetchError error={error} />}
			</Modal.Body>
		</Modal>
	);
};

export default DocumentsListForSource;
