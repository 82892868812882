import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';

interface ISourceSelectorProps extends React.AllHTMLAttributes<HTMLDivElement> {
	selected: boolean;
}
const SourceSelector = ({ selected }: ISourceSelectorProps) => {
	return (
		<div className="hstack gap-1">
			<input
				type="checkbox"
				className="form-check-input btn-action mt-0 ms-n1"
				data-action="select"
				checked={selected}
				readOnly
			/>
			<Dropdown>
				<Dropdown.Toggle variant="link" id="dropdown-basic" size="sm" className="p-0 link-secondary" />
				<Dropdown.Menu>
					<Dropdown.Item as={Button} className="btn-action" data-action="select-similar">
						Вибрати всі посилання на це джерело
					</Dropdown.Item>
					<Dropdown.Item as={Button} className="btn-action" data-action="unselect-similar">
						Скасувати вибір всіх посилань на це джерело
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

export default SourceSelector;
