import classNames from 'classnames';
import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BsBoxArrowUpRight, BsPencilSquare } from 'react-icons/bs';
import { formatDocumentDate } from '../../../common';
import TextByParagraphs from '../../../components/TextByParagraphs';
import { PUB_RELIABILITY_DEFAULT } from '../../../interfaces/dict';
import { IDocumentSourceView } from '../../../interfaces/document';
import { getDomainFromUrl } from '../../../utils';
import SourceSelector from './SourceSelector';

interface ILinkViewProps extends React.AllHTMLAttributes<HTMLTableRowElement> {
	link: IDocumentSourceView;
	sourceTitle?: string;
	index: number;
	editButtonsDisabled: boolean;
	selected: boolean;
}
const LinkView = ({
	link: { id, pub_title, pub_url, pub_author, pub_date, source_reliability, pub_reliability, source_id, comment },
	sourceTitle,
	index,
	editButtonsDisabled,
	selected,
}: ILinkViewProps) => (
	<tr className={classNames('source-row', editButtonsDisabled && 'opacity-25')} data-index={index}>
		<td>{index + 1}.</td>
		<td>
			<SourceSelector selected={selected} />
		</td>
		<td className="fw-500">
			{(source_id && sourceTitle) || (
				<span className="text-danger">
					<span className="fw-normal">Нове джерело:</span> {getDomainFromUrl(pub_url)}
				</span>
			)}
		</td>
		<td>
			<a href={pub_url} target="_blank" rel="noreferrer" className="text-break">
				{pub_title || pub_url}
				<BsBoxArrowUpRight className="ms-1 mt-n1" />
			</a>
			{pub_author && <span>, {pub_author}</span>}
			{pub_date && <span>, {formatDocumentDate(pub_date)}</span>}
			{comment && <TextByParagraphs text={comment} className="text-small" />}
		</td>
		<td
			className={pub_reliability === PUB_RELIABILITY_DEFAULT ? 'text-danger' : undefined}
		>{`${source_reliability}${pub_reliability}`}</td>
		<td>
			<ButtonGroup>
				<Button
					variant="link"
					title="Редагувати"
					className="btn-action p-0 link-secondary mt-n1"
					data-action="edit"
					type="button"
					disabled={editButtonsDisabled}
				>
					<BsPencilSquare />
				</Button>
			</ButtonGroup>
		</td>
	</tr>
);

export default LinkView;
