import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { showError, showPromiseConfirm } from '../../alerts';
import { useArray } from '../../hooks';
import { TEntityId } from '../../interfaces/common';
import { ITextArrayDataOptions } from '../../interfaces/document';
import { getControlData } from '../../utils';
import EditButtonsGroup from './EditButtonsGroup';

interface ITextInputsGroupProps {
	initialData?: TEntityId<string>[];
	// idTemplate: string;
	// title: string;
	// className?: string;
	itemClassName?: string;
	onChange: (data: ITextArrayDataOptions) => void;
	// required?: boolean;
	setInEditing: (newInEditing: boolean) => void;
}
const TextInputsGroup = ({
	initialData,
	onChange,
	// className,
	itemClassName,
	// title,
	// idTemplate,
	// required = false,
	setInEditing,
}: ITextInputsGroupProps) => {
	const { data, toggleItem, setData, modified } = useArray(initialData);
	const [newItemIndex, setNewItemIndex] = useState(initialData?.length ?? 0);
	const [editingIndex, setEditingIndex] = useState(newItemIndex);
	const refSelectedInput = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setNewItemIndex(data.length);
		setEditingIndex(data.length);
	}, [data.length]);

	useEffect(() => {
		setInEditing(newItemIndex !== editingIndex);
	}, [newItemIndex, editingIndex, setInEditing]);

	useEffect(() => {
		setData(initialData);
	}, [initialData]); // eslint-disable-line

	useEffect(() => {
		onChange({ modified, selected: data });
	}, [modified, data]); // eslint-disable-line

	useEffect(() => {
		focusEditing();
	}, [editingIndex]);

	const focusEditing = () =>
		setTimeout(() => {
			refSelectedInput.current?.focus();
		}, 100);

	const onControlClick = async (event: React.MouseEvent<HTMLDivElement>) => {
		const action = getControlData(event.target, '.btn-action', 'action');
		const strIndex = getControlData(event.target, '.text-input_group', 'index') as string;
		if (!action || !strIndex) return;
		const index = parseInt(strIndex, 10);
		switch (action) {
			case 'edit':
				setEditingIndex(index);
				break;
			case 'delete':
				const oldTitle = data[index];
				if (!(await showPromiseConfirm(`Ви впевнені, що бажаєте видалити тему "${oldTitle}?"`))) return;
				toggleItem(oldTitle);
				break;
		}
	};

	const onItemSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		const form = event.target as HTMLFormElement;
		const formData = new FormData(form);
		const newTitle = formData.get('title')?.toString().trim() ?? '';
		if (newTitle === '') return showError('Тема не може бути порожньою');
		if (data.includes(newTitle)) return showError('Така тема вже існує');
		if (editingIndex !== newItemIndex) {
			if (data[editingIndex] !== newTitle)
				setData(data.map((title, index) => (index !== editingIndex ? title : newTitle)));
			setEditingIndex(newItemIndex);
		} else {
			setData([...data, newTitle]);
		}
	};

	const onItemReset = () => {
		setEditingIndex(newItemIndex);
		focusEditing();
	};

	return (
		<>
			<div className="vstack gap-1" onClick={onControlClick}>
				{[...data, ''].map((title, index) => (
					<React.Fragment key={`${title}_${index}`}>
						{index === newItemIndex && (
							<Form.Label className={classNames(data.length !== 0 && 'mt-3')}>Нова тема</Form.Label>
						)}
						<Form
							data-index={index}
							className="input-group text-input_group"
							onSubmit={onItemSubmit}
							onReset={onItemReset}
						>
							<Form.Control
								// id={idTemplate + '_' + index}
								type="text"
								defaultValue={title}
								className={itemClassName}
								disabled={editingIndex !== index}
								ref={editingIndex === index ? refSelectedInput : undefined}
								name="title"
								placeholder={
									title !== '' ? `Старе значення: "${title}"` : 'Введіть нову тему та натисніть Enter для її збереження'
								}
							/>
							<EditButtonsGroup
								showEditingButtons={editingIndex === index}
								showEditButtons={index !== newItemIndex && index !== editingIndex}
								editButtonsDisabled={editingIndex !== newItemIndex}
							/>
						</Form>
					</React.Fragment>
				))}
			</div>
			<div className="text-secondary mt-2">
				В&nbsp;якості тем можуть використовуватись: ПІБ,&nbsp;або назва об’єкта зацікавленості (всіх об’єктів, вказаних
				у&nbsp;запиті); проблематика запиту; ключові слова, які&nbsp;допомагають у&nbsp;пошуку документа; тощо.
			</div>
		</>
	);
};

export default TextInputsGroup;
