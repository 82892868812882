import React from 'react';
import styled from 'styled-components';
import IconDoc from '../../img/fileIcons/128/free-icon-doc.png';
import IconDocx from '../../img/fileIcons/128/free-icon-docx.png';
import IconPdf from '../../img/fileIcons/128/free-icon-pdf.png';

const FILE_ICONS: { [key: string]: string } = {
	'.pdf': IconPdf,
	'.doc': IconDoc,
};

interface IDocumentIconProps extends React.AllHTMLAttributes<HTMLDivElement> {
	ext: string;
	size?: number;
}
const DocumentIcon = ({ ext, className, size = 96 }: IDocumentIconProps) => {
	return (
		<FileIcon src={FILE_ICONS[ext] || IconDocx} alt={ext} className={className} size={size} title={`${ext}-файл`} />
	);
};

export default DocumentIcon;

interface IFileIconProps {
	size: number;
}
const FileIcon = styled.img<IFileIconProps>`
	width: ${(props) => props.size + 'px'};
	height: ${(props) => props.size + 'px'};
	opacity: var(--auxiliary-image-opacity, 1);
`;
