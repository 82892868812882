import classNames from 'classnames';
import React from 'react';
import { BsQuestionCircle } from 'react-icons/bs';
import { showAlert } from '../alerts';

interface IQuestionButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	placement?: string;
}
const QuestionButton = ({ title, className, placement = 'left', ...rest }: IQuestionButtonProps) => {
	// return (
	// 	<OverlayTrigger
	// 		overlay={
	// 			<Popover>
	// 				<Popover.Body>{title}</Popover.Body>
	// 			</Popover>
	// 		}
	// 		trigger="hover"
	// 		placement={placement as any}
	// 	>
	// 		<button
	// 			className={classNames('btn btn-link btn-sm p-0 text-secondary', className)}
	// 			data-content={title}
	// 			tabIndex={-1}
	// 			{...rest}
	// 		>
	// 			<BsQuestionCircle />
	// 		</button>
	// 	</OverlayTrigger>
	// );
	const onQuestionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		const { content } = (event.currentTarget as HTMLButtonElement).dataset;
		showAlert(content || 'Довідка по даному елементу наразі не доступна.', 'Довідка');
	};

	return (
		<button
			className={classNames('btn btn-link btn-sm p-0 text-secondary', className)}
			data-content={title}
			tabIndex={-1}
			onClick={onQuestionClick}
			{...rest}
		>
			<BsQuestionCircle />
		</button>
	);
};

export default QuestionButton;
