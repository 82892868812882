import React from 'react';
import { useDictionariesQuery } from '../../features/services/moriaApi';
import { IDictionary, TEntityId } from '../../interfaces/common';
import { IRealmDic } from '../../interfaces/dict';

export interface IWithRealms {
	realmsAllIds: TEntityId<string>[];
	realmsById: IDictionary<string, IRealmDic>;
}

const WithRealms =
	<T extends IWithRealms>(WrappedComponent: React.ComponentType<T>) =>
	(props: Omit<T, keyof IWithRealms>) => {
		// eslint-disable-next-line
		const { realms } = useDictionariesQuery(undefined, {
			refetchOnMountOrArgChange: false,
			selectFromResult: ({ data }) => ({ realms: data?.realms }),
		});

		return <WrappedComponent {...(props as T)} realmsAllIds={realms?.ids || []} realmsById={realms?.entities || {}} />;
	};

export default WithRealms;
