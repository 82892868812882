import classNames from 'classnames';
import React from 'react';

interface IAsteriskProps extends React.HTMLAttributes<HTMLDivElement> {
	position?: 'start' | 'end';
	colorClassName?: string;
}
const Asterisk = ({ position = 'end', colorClassName = 'text-danger', className }: IAsteriskProps) => (
	<span className={classNames(position === 'end' ? 'ms-1' : 'me-1', colorClassName, className)}>*</span>
);

interface IAsteriskTextProps extends React.AllHTMLAttributes<HTMLDivElement> {
	text?: string;
	muted?: boolean;
}
export const AsteriskText = ({
	muted = true,
	className,
	text = 'Поля, обов’язкові для заповнення',
}: IAsteriskTextProps) => (
	<div className={className}>
		<Asterisk position="start" />
		<small className={classNames(muted && 'text-secondary')}>{text}</small>
	</div>
);

export default Asterisk;
