import { BsCalendarEvent, BsFiles } from 'react-icons/bs';
import { DEFAULTS } from '../common';
import { useShortStatisticQuery } from '../features/services/documents';

const ShortStatistic = () => {
	const { data: shortStatistic } = useShortStatisticQuery(undefined, {
		pollingInterval: 15 * 60 * 1000,
	});

	if (!shortStatistic) return null;

	return (
		<div>
			{shortStatistic.lastDate && (
				<div>
					<BsCalendarEvent className="me-1 mt-n1" />
					Дата оновлення:
					<span className="semi-bold ms-1">
						{new Date(shortStatistic.lastDate).toLocaleDateString(DEFAULTS.locale, {
							month: '2-digit',
							day: '2-digit',
							year: 'numeric',
						})}
					</span>
				</div>
			)}
			<div>
				<BsFiles className="me-1 mt-n1" />
				Кількість документів:<span className="semi-bold ms-1">{shortStatistic.count}</span>
			</div>
		</div>
	);
};

export default ShortStatistic;
