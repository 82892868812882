// import HeaderWithImage from '../../components/HeaderWithImage';
// import PeopleSearch from '../../img/undraw_people_search_re_5rre.svg';
import ParamsContainer from './ParamsContainer';
import Realms from './Realms';
import Severity from './Severity';

// interface IParamsProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const Params = () => {
	return (
		<>
			<div className="position-sticky top-main-gap">
				<ParamsContainer caption="Додаткові параметри">
					<Realms />
					<Severity className="mt-2" />
				</ParamsContainer>
				<h6 className="mt-3 text-center">
					Для пошуку документів
					<br />
					скористайтесь формою вгорі
				</h6>
			</div>

			{/* <HeaderWithImage
				imgSrc={PeopleSearch}
				alt="Зображення пошуку"
				headerClassName="text-primary"
				title="Скористайтесь формою та виконайте пошук"
				headerLevel={5}
				className="mt-3"
			/> */}
		</>
	);
};

export default Params;
